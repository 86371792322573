// @types
import { FAQ_CITY_PEOPLE } from 'src/_mock/_faq-CP';

import { BlockTypes } from 'src/types/generator';
import { CodeTypes, FieldTypes, SettingsField } from 'src/types/page-generator';

import banners from './banners';
import themeSettings from './themeSettings';
import verticalBanners from './vertical-banners';
import { predefinedFonts } from '../theme/typography';

export const CONTENT_FIELDS_PRESETS: Record<BlockTypes, SettingsField[]> = {
  [BlockTypes.header]: [
    {
      name: 'h1',
      type: FieldTypes.input,
      defaultValue: '',
      label: 'H1 Title',
      rowSize: 2,
    },
    {
      name: 'metakeywords',
      type: FieldTypes.input,
      defaultValue: '',
      label: 'Meta Keywords',
      rowSize: 2,
    },
    {
      name: 'metatitle',
      type: FieldTypes.input,
      defaultValue: '',
      label: 'Meta Title',
      rowSize: 4,
    },
    {
      name: 'metadescription',
      type: FieldTypes.text,
      defaultValue: '',
      label: 'Meta Description',
      rowSize: 4,
    },
    {
      name: 'links',
      type: FieldTypes.list,
      defaultValue: [
        {
          label: 'Parent Link 1',
          path: '#',
          children: [
            { label: 'Child Link 1 - 1', path: '#', icon: '' },
            { label: 'Child Link 1 - 2', path: '#', icon: '' },
            { label: 'Child Link 1 - 3', path: '#', icon: '' },
          ],
        },
        {
          label: 'Parent Link 2',
          path: '#',
          children: [
            { label: 'Child Link 2 - 1', path: '#', icon: '' },
            { label: 'Child Link 2 - 2', path: '#', icon: '' },
            { label: 'Child Link 2 - 3', path: '#', icon: '' },
          ],
        },
      ],
      label: 'Menu Links',
      rowSize: 4,
    },
    {
      name: 'burgerLinks',
      showOn: 'settings.showBurgerMenu',
      type: FieldTypes.list,
      defaultValue: [
        {
          label: 'Parent Link 1',
          path: '#',
          children: [
            { label: 'Child Link 1 - 1', path: '#', icon: '' },
            { label: 'Child Link 1 - 2', path: '#', icon: '' },
            { label: 'Child Link 1 - 3', path: '#', icon: '' },
          ],
        },
        {
          label: 'Parent Link 2',
          path: '#',
          children: [
            { label: 'Child Link 2 - 1', path: '#', icon: '' },
            { label: 'Child Link 2 - 2', path: '#', icon: '' },
            { label: 'Child Link 2 - 3', path: '#', icon: '' },
          ],
        },
      ],
      label: 'Burger Menu Links',
      rowSize: 4,
    },
    {
      name: 'menuButtons',
      type: FieldTypes.buttons,
      defaultValue: [
        {
          label: 'Explore now',
          color: 'primary',
          size: 'medium',
          variant: 'contained',
          link: '#',
        },
      ],
      rowSize: 4,
      label: 'Menu Buttons',
    },
  ],
  [BlockTypes.headercitypeople]: [
    {
      name: 'h1',
      type: FieldTypes.input,
      defaultValue: '',
      label: 'H1 Title',
      rowSize: 2,
    },
    {
      name: 'metakeywords',
      type: FieldTypes.input,
      defaultValue: '',
      label: 'Meta Keywords',
      rowSize: 2,
    },
    {
      name: 'metatitle',
      type: FieldTypes.input,
      defaultValue: '',
      label: 'Meta Title',
      rowSize: 4,
    },
    {
      name: 'metadescription',
      type: FieldTypes.text,
      defaultValue: '',
      label: 'Meta Description',
      rowSize: 4,
    },
    {
      name: 'subheaderText',
      type: FieldTypes.input,
      defaultValue: '',
      label: 'Subheader Text',
      rowSize: 2,
    },
    {
      name: 'burgerLinks',
      type: FieldTypes.list,
      defaultValue: [
        {
          icon: '',
          path: '/',
          label: 'link 1',
          children: [],
        },
        {
          icon: '',
          path: '/',
          label: 'link 2',
          children: [],
        },
        {
          icon: '',
          path: '/',
          label: 'link 3',
          children: [],
        },
      ],
      label: 'Burger Menu Links',
      rowSize: 4,
    },
  ],
  [BlockTypes.StandartHorizontalBannerV1]: [...banners],
  [BlockTypes.happygift_activitybanner]: [],
  [BlockTypes.stepbanner]: [],
  [BlockTypes.combinedstepbanner]: [],
  [BlockTypes.cardsbanner]: [],

  [BlockTypes.sixhoverblocks]: [
    {
      name: 'mainTitle',
      type: FieldTypes.input,
      defaultValue: 'מערכת ניהול למנהלות רווחה ',
      rowSize: 4,
      label: 'Main Title',
    },
    {
      name: 'title',
      type: FieldTypes.input,
      defaultValue: 'בואו נעשה עבודה קלה יותר ונעשה יותר!',
      rowSize: 4,
      label: 'Title',
    },
    {
      name: 'contentText',
      type: FieldTypes.richtext,
      defaultValue:
        'עם המערכת הדיגיטלית לשליחת מתנות של Happy Gift, עסקים בכל גודלומכל סוג, יכולים לשלוח את המתנה המושלמת - בכמה צעדים פשוטים. עם המערכת הדיגיטלית לשליחת מתנות של Happy Gift, עסקים בכל גודלומכל סוג, יכולים לשלוח את המתנה המושלמת - בכמה צעדים פשוטים.',
      rowSize: 4,
      label: 'Content text',
    },
    {
      name: 'actionButtons',
      type: FieldTypes.buttons,
      defaultValue: [
        {
          label: 'התחברות למערכת HR',
          color: 'primary',
          size: 'medium',
          variant: 'contained',
          link: '#',
        },
        {
          label: 'איך זה עובד?',
          color: 'primary',
          size: 'medium',
          variant: 'contained',
          link: '#',
        },
      ],
      rowSize: 4,
      label: 'Action Buttons',
    },
    // {},
  ],
  [BlockTypes.banner]: [],
  [BlockTypes.cards]: [],
  [BlockTypes.filter]: [],
  [BlockTypes.swiper]: [],
  [BlockTypes.textblock]: [],
  [BlockTypes.footer]: [
    {
      name: 'description',
      label: 'Description',
      type: FieldTypes.richtext,
      defaultValue:
        'באתר Happy gift תוכלו למצוא מבחר מתנות לפי תחומי עניין שונים המאפשרים לכם לבחור את המתנה המדויקת עבורכם.',
      rowSize: 4,
    },
    {
      name: 'logo',
      label: 'Logo',
      type: FieldTypes.upload,
      defaultValue: '/assets/images/happy-gift/logo.png',
      rowSize: 4,
      uploadType: 'image',
    },
    {
      name: 'copyright',
      label: 'Copyright text',
      type: FieldTypes.input,
      defaultValue: '© 2023 happygift.co.il כל הזכויות שמורות.',
      rowSize: 4,
    },
    {
      name: 'links',
      type: FieldTypes.list,
      defaultValue: [
        {
          label: 'צור קשר',
          path: '#',
          children: [
            { label: '03-5106070', path: '#', icon: '' },
            { label: "ימים א'-ה' 8:30-17:00", path: '#', icon: '' },
          ],
        },
        {
          label: 'שימושי',
          path: '#',
          children: [
            { label: 'הנחיות לשוברים וכרטיסי מתנה', path: '#', icon: '' },
            { label: 'הארכת תוקף', path: '#', icon: '' },
            { label: 'צור קשר', path: '#', icon: '' },
          ],
        },
        {
          label: 'תחומי פעילות',
          path: '#',
          children: [
            { label: 'PAI', path: '#', icon: '' },
            { label: 'shalemPO', path: '#', icon: '' },
            { label: 'Todot', path: '#', icon: '' },
            { label: 'Bitravel', path: '#', icon: '' },
          ],
        },
        {
          label: 'דף הבית',
          path: '#',
          children: [
            { label: 'אודותנו', path: '#', icon: '' },
            { label: 'מתנות לעובדים', path: '#', icon: '' },
            { label: 'HappyGift שוברי', path: '#', icon: '' },
            { label: 'בדיקת יתרה HappyGift', path: '#', icon: '' },
            { label: 'הצטרפות לבתי עסק', path: '#', icon: '' },
          ],
        },
        {
          label: 'קטגוריות',
          path: '#',
          children: [
            { label: 'בתי מלון', path: '#', icon: '' },
            { label: 'צימרים ובקתות', path: '#', icon: '' },
            { label: 'סדנאות', path: '#', icon: '' },
            { label: 'טרקלין חשמל', path: '#', icon: '' },
            { label: 'Fast Food', path: '#', icon: '' },
            { label: 'מסעדות ובתי קפה', path: '#', icon: '' },
            { label: 'ספא טיפוח ויופי', path: '#', icon: '' },
            { label: 'ראה כל', path: '#', icon: '' },
          ],
        },
      ],
      label: 'Menu Links',
      rowSize: 4,
    },
    {
      name: 'socialLinks',
      label: 'Social links',
      type: FieldTypes.list,
      defaultValue: [
        { label: 'LinkedIn', path: '#', icon: 'mingcute:linkedin-fill' },
        { label: 'Instagram', path: '#', icon: 'mdi:instagram' },
        { label: 'Facebook', path: '#', icon: 'ri:facebook-fill' },
      ],
      rowSize: 2,
      noChildren: true,
    },
    {
      name: 'footerLinks',
      label: 'Additional links',
      type: FieldTypes.list,
      defaultValue: [
        { label: 'תנאים והגבלות', path: '#' },
        { label: 'מדיניות פרטיות', path: '#' },
      ],
      rowSize: 2,
      noChildren: true,
    },
  ],
  [BlockTypes.carousel]: [],
  [BlockTypes.bigdiscountlist]: [],
  [BlockTypes.cardlist]: [],
  [BlockTypes.categories]: [],
  [BlockTypes.categorylist]: [],
  [BlockTypes.productwithfilter]: [],
  [BlockTypes.moreitems]: [],
  [BlockTypes.newarrivalslist]: [],
  [BlockTypes.productcarousel]: [],
  [BlockTypes.ratecomponent]: [],
  [BlockTypes.servicelist]: [],
  [BlockTypes.hotdealscarousel]: [],
  [BlockTypes.multibannerscarousel]: [],
  [BlockTypes.multirowbanner]: [],
  [BlockTypes.serviceitems]: [],
  [BlockTypes.subscribenewsletter]: [],
  [BlockTypes.trendingitems]: [],
  [BlockTypes.bigbannerscarousel]: [],
  [BlockTypes.categorybanner]: [],
  [BlockTypes.instagramphotogallery]: [],
  [BlockTypes.salesbanner]: [],
  [BlockTypes.topcategories]: [],
  [BlockTypes.searchbarwithbackground]: [],
  [BlockTypes.popularproductswithmultilevelmenu]: [],
  [BlockTypes.multilinecategories]: [],
  [BlockTypes.testimonials]: [],
  [BlockTypes.productscarouselhovereffect]: [],
  [BlockTypes.bestsellingcategories]: [],
  [BlockTypes.blogcard]: [],
  [BlockTypes.bloglistarea]: [],
  [BlockTypes.brandlistcarouselarea]: [],
  [BlockTypes.fullwidthbanner]: [],
  [BlockTypes.offerbanner]: [],
  [BlockTypes.productlistcolumn]: [],
  [BlockTypes.bestsellingproducts]: [],
  [BlockTypes.whitecardbanner]: [...banners],
  [BlockTypes.happyhrbanner]: [],
  [BlockTypes.b2cbanner]: [...banners],
  [BlockTypes.b2cwhitecardbanner]: [],
  [BlockTypes.b2chappygiftglobal]: [],
  [BlockTypes.b2chappygiftworld]: [],
  [BlockTypes.b2chappygiftbluecardbanner]: [],
  [BlockTypes.threecolumnsinfo]: [],
  [BlockTypes.wallet]: [
    {
      name: 'title',
      label: 'Title',
      type: FieldTypes.input,
      defaultValue: 'שלום, ישראל!',
      rowSize: 4,
    },
    {
      name: 'logo',
      label: 'Logo',
      type: FieldTypes.upload,
      defaultValue: '',
      rowSize: 4,
      uploadType: 'image',
    },
  ],
  [BlockTypes.cart]: [],
  [BlockTypes.b2bshop]: [],
  [BlockTypes.wallet2]: [],
  [BlockTypes.clubcards]: [],
  [BlockTypes.videobanner]: [],
  [BlockTypes.cardholderbanner]: [],
  [BlockTypes.sixtableelements]: [],
  [BlockTypes.fourcolumnelements]: [],
  [BlockTypes.threecolumnscardinfowithicons]: [],
  [BlockTypes.themeSettings]: themeSettings,
  [BlockTypes.fontSettings]: [
    {
      name: 'enableCustomFont',
      rowSize: 4,
      defaultValue: false,
      label: 'Custom font',
      type: FieldTypes.switch,
    },
    {
      name: 'googleFontFamily',
      rowSize: 4,
      defaultValue: 'comfortaa',
      label: 'Google Fonts Font Family',
      type: FieldTypes.select,
      options: predefinedFonts,
      hideOn: 'enableCustomFont',
    },
    {
      name: 'customFontFamily',
      rowSize: 4,
      defaultValue: 'sans',
      label: 'Custom Font Family',
      type: FieldTypes.input,
      showOn: 'enableCustomFont',
    },
    {
      name: 'fontCSS',
      rowSize: 4,
      defaultValue: '',
      label: 'Font import (CSS)',
      type: FieldTypes.code,
      extra: { codeType: CodeTypes.css, height: '250px', placeholder: '/* Import fonts CSS */' },
      showOn: 'enableCustomFont',
    },
  ],
  [BlockTypes.titleandsubtitle]: [
    {
      name: 'titleText',
      type: FieldTypes.input,
      defaultValue: 'המהפכה שצוברת תאוצה בעולם נמצאת כעת בהישג ידך',
      label: 'Title text',
      rowSize: 4,
    },
    {
      name: 'subtitleText',
      type: FieldTypes.text,
      defaultValue:
        'ניתן לנהל את הכסף שלך בקלות, גם אם אין לך חשבון בנק או כרטיס אשראי. הפתרון כולל PAI, כרטיס חיוב נטען בינלאומי ויזה/מאסטר קארד. יחד, הם יכולים לשלוט בהוצאות, ולקבל ולבצע תשלומים',
      label: 'Subtitle text',
      rowSize: 4,
    },
  ],
  [BlockTypes.threecolumncardinfowithicons]: [
    {
      name: 'firstTitleText',
      type: FieldTypes.input,
      defaultValue: 'פשטות',
      label: 'First Title text',
      rowSize: 4,
    },
    {
      name: 'firstSubtitleText',
      type: FieldTypes.text,
      defaultValue: 'הארנק הדיגיטלי בנקודות הטעינה הפרוסות ברחבי הארץ ובכרטיס אשראי',
      label: 'First Subtitle text',
      rowSize: 4,
    },
    {
      name: 'secondTitleText',
      type: FieldTypes.input,
      defaultValue: 'העברה',
      label: 'Second Title text',
      rowSize: 4,
    },
    {
      name: 'secondSubtitleText',
      type: FieldTypes.text,
      defaultValue: 'כסף מהארנק הדיגיטלי ועד לכרטיס הנטען בלחיצת כפתור, דרך האתר או באפליקציית Pai',
      label: 'Second Subtitle text',
      rowSize: 4,
    },
    {
      name: 'thirdTitleText',
      type: FieldTypes.input,
      defaultValue: 'נוחות',
      label: 'Third Title text',
      rowSize: 4,
    },
    {
      name: 'thirdSubtitleText',
      type: FieldTypes.text,
      defaultValue: 'בצע רכישות ועסקאות בקלות, שליטה מרבית בהוצאות',
      label: 'Third Subtitle text',
      rowSize: 4,
    },
    {
      name: 'buttonText',
      type: FieldTypes.text,
      defaultValue: 'Happy HR',
      label: 'Button text',
      rowSize: 4,
    },
  ],
  [BlockTypes.customtestimonials]: [],
  [BlockTypes.searchbycategory]: [],
  [BlockTypes.forcolumnelements]: [
    {
      name: 'firstTitleText',
      type: FieldTypes.input,
      defaultValue: '3 מיליון',
      label: 'First Title text',
      rowSize: 4,
    },
    {
      name: 'firstSubtitleText',
      type: FieldTypes.text,
      defaultValue: 'נשלחו כרטיסים',
      label: 'First Subtitle text',
      rowSize: 4,
    },
    {
      name: 'secondTitleText',
      type: FieldTypes.input,
      defaultValue: '10 שנים',
      label: 'Second Title text',
      rowSize: 4,
    },
    {
      name: 'secondSubtitleText',
      type: FieldTypes.text,
      defaultValue: 'של עבודה עבורכם',
      label: 'Second Subtitle text',
      rowSize: 4,
    },
    {
      name: 'thirdTitleText',
      type: FieldTypes.input,
      defaultValue: '1,5 מיליון ',
      label: 'Third Title text',
      rowSize: 4,
    },
    {
      name: 'thirdSubtitleText',
      type: FieldTypes.text,
      defaultValue: 'לקוחות',
      label: 'Third Subtitle text',
      rowSize: 4,
    },
    {
      name: 'fourthTitleText',
      type: FieldTypes.input,
      defaultValue: '+400',
      label: 'Fourth Title text',
      rowSize: 4,
    },
    {
      name: 'fourthSubtitleText',
      type: FieldTypes.text,
      defaultValue: 'מותגים ברשימה',
      label: 'Fourth Subtitle text',
      rowSize: 4,
    },
  ],
  [BlockTypes.titleandzipline]: [
    {
      name: 'titleText',
      type: FieldTypes.input,
      defaultValue: 'אנו נבחרים על ידי העסקים הגדולים בישראל',
      label: 'Title text',
      rowSize: 4,
    },
  ],
  [BlockTypes.brandscarousel]: [],
  [BlockTypes.threecolumnsinfowithIcons]: [],
  [BlockTypes.css]: [
    {
      name: 'custom-css',
      rowSize: 4,
      defaultValue: '',
      label: 'Custom CSS',
      type: FieldTypes.code,
      extra: {
        codeType: CodeTypes.css,
        height: '300px',
        placeholder: '/* Type your custom CSS styles here */',
      },
    },
  ],

  [BlockTypes.standardverticalbanner]: [...verticalBanners],
  [BlockTypes.shopbanner]: [],
  [BlockTypes.shopbanner2]: [],
  [BlockTypes.shopbanner3]: [],
  [BlockTypes.shopbanner4]: [],
  [BlockTypes.shopbanner5]: [],
  [BlockTypes.shopbanner6]: [],
  [BlockTypes.buttonsbannerhappyhr]: [],
  [BlockTypes.allhappyhrbanner]: [],
  [BlockTypes.hottestoffers]: [],

  [BlockTypes.popularcategories]: [],
  [BlockTypes.products]: [],
  [BlockTypes.myCoupons]: [],
  [BlockTypes.myActiveCoupon]: [],
  [BlockTypes.reverseBlock]: [],
  [BlockTypes.productDescription]: [],
  [BlockTypes.about]: [],
  [BlockTypes.productpagination]: [],
  [BlockTypes.productdetailsview]: [],
  [BlockTypes.coupondetailsview]: [],
  [BlockTypes.banner1]: [],
  [BlockTypes.banner2]: [],
  [BlockTypes.banner3]: [],
  [BlockTypes.banner4]: [],
  [BlockTypes.banner1Carousel]: [],
  [BlockTypes.bannerproducts]: [],
  [BlockTypes.bannerproducts2]: [],
  [BlockTypes.bannerproducts3]: [],
  [BlockTypes.catalogboombuy]: [],
  [BlockTypes.catalogboombuyzara]: [],
  [BlockTypes.catalogboombuyzara2]: [],
  [BlockTypes.categoriesboomBuy]: [],
  [BlockTypes.mainmainbanner]: [],
  [BlockTypes.mainzarabanner]: [],
  [BlockTypes.headbanner]: [],
  [BlockTypes.headzarabanner]: [],
  [BlockTypes.headzboombuybanner]: [],
  [BlockTypes.productboombuydatailsview]: [],
  [BlockTypes.pastimebanner]: [],
  [BlockTypes.attractionscoupons]: [],
  [BlockTypes.voucherbanner]: [],
  [BlockTypes.bigbannerhappygift]: [],
  [BlockTypes.search]: [],
  [BlockTypes.specialcategories]: [],
  [BlockTypes.allshopbanners]: [],
  [BlockTypes.account]: [
    {
      name: '',
      label: 'Menu tabs',
      type: FieldTypes.group,
      rowSize: 2,
      fields: [
        {
          name: 'generalTabItem',
          label: 'General',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'billingTabItem',
          label: 'Billing',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'notificationsTabItem',
          label: 'Notifications',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'socialTabItem',
          label: 'Social links',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'securityTabItem',
          label: 'Security',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
      ],
    },
  ],
  [BlockTypes.businessAccount]: [
    {
      name: '',
      label: 'Menu tabs',
      type: FieldTypes.group,
      rowSize: 2,
      fields: [
        {
          name: 'generalTabItem',
          label: 'General',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'billingTabItem',
          label: 'Billing',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'notificationsTabItem',
          label: 'Notifications',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'socialTabItem',
          label: 'Social links',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'securityTabItem',
          label: 'Security',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
      ],
    },
    {
      name: 'actionButtons',
      type: FieldTypes.buttons,
      showOn: 'settings.enableButtons',
      defaultValue: [
        {
          label: 'השלח בקשה לאישור',
          color: 'secondary',
          size: 'medium',
          variant: 'contained',
          link: '#',
        },
      ],
      rowSize: 4,
      label: 'Action Buttons',
    },
  ],
  [BlockTypes.residentAccount]: [],
  [BlockTypes.catalog]: [],
  [BlockTypes.product]: [],
  [BlockTypes.cardsbannerwithbutton]: [],
  [BlockTypes.allshopbanners2]: [],
  [BlockTypes.allshopbanners22]: [],
  [BlockTypes.mainxtramainbanner]: [],
  [BlockTypes.mainxtramainbanner2]: [],
  [BlockTypes.mainxtramainbanner3]: [],
  [BlockTypes.vouchersv1]: [],
  [BlockTypes.vouchersv2]: [],
  [BlockTypes.vouchersv3]: [],
  [BlockTypes.brandscarousel2]: [],
  [BlockTypes.couponsv2]: [],
  [BlockTypes.bigbannerelements]: [],
  [BlockTypes.logocomponent]: [],
  [BlockTypes.AllHappyHoldersBanners]: [],
  [BlockTypes.SpecialCategoriesHappyHolders]: [],
  [BlockTypes.greybanner]: [],
  [BlockTypes.blackbanner]: [],
  [BlockTypes.MainBannerHolders]: [],
  [BlockTypes.merchant]: [],
  [BlockTypes.merchants]: [],
  [BlockTypes.networks]: [],
  [BlockTypes.filterv2]: [],
  [BlockTypes.popup]: [],
  [BlockTypes.accessRestrictions]: [],
  [BlockTypes.providers]: [
    {
      name: 'layoutSettings',
      label: 'Layout Settings',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'enableTitle',
          label: 'Enable title',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 4,
        },
        {
          name: 'variant',
          label: 'Variant',
          type: FieldTypes.radiogroup,
          defaultValue: 'v1',
          rowSize: 4,
          options: [
            { label: 'Slider', value: 'v1' },
            { label: 'Table', value: 'v2' },
          ],
        },
      ],
    },
    {
      name: 'titleSettings',
      label: 'Title Settings',
      type: FieldTypes.group,
      showOn: 'settings.enableTitle',
      rowSize: 4,
      fields: [
        {
          name: 'addGradientStyle',
          label: 'Add gradient style',
          type: FieldTypes.checkbox,
          defaultValue: false,
          rowSize: 4,
        },
        {
          name: 'firstGradientColor',
          showOn: 'settings.addGradientStyle',
          label: 'First color',
          type: FieldTypes.select,
          defaultValue: 'rgba(58,13,94,1)',
          rowSize: 2,
          isColor: true,
        },
        {
          name: 'firstGradientColorStop',
          showOn: 'settings.addGradientStyle',
          label: 'First color stop',
          type: FieldTypes.input,
          defaultValue: '40',
          rowSize: 2,
        },
        {
          name: 'secondGradientColor',
          showOn: 'settings.addGradientStyle',
          label: 'Second color',
          type: FieldTypes.select,
          defaultValue: 'rgba(113,7,172,1)',
          rowSize: 2,
          isColor: true,
        },
        {
          name: 'secondGradientColorStop',
          showOn: 'settings.addGradientStyle',
          label: 'Second color stop',
          type: FieldTypes.input,
          defaultValue: '80',
          rowSize: 2,
        },
        {
          name: 'thirdGradientColor',
          showOn: 'settings.addGradientStyle',
          label: 'Third color',
          type: FieldTypes.select,
          defaultValue: 'rgba(171,0,255,1)',
          rowSize: 2,
          isColor: true,
        },
        {
          name: 'thirdGradientColorStop',
          showOn: 'settings.addGradientStyle',
          label: 'Third color stop',
          type: FieldTypes.input,
          defaultValue: '100',
          rowSize: 2,
        },
        {
          name: 'headingTitleTextColor',
          hideOn: 'settings.addGradientStyle',
          label: 'Heading Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          isColor: true,
        },
        {
          name: 'headingTitleFontSize',
          label: 'Heading Font Size',
          type: FieldTypes.select,
          defaultValue: '35px',
          rowSize: 4,
        },
        {
          name: 'headingTitleFontWeight',
          label: 'Heading Font Weight',
          type: FieldTypes.select,
          defaultValue: 900,
          rowSize: 4,
        },
        {
          name: 'headingTitleFontStyle',
          label: 'Heading Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
        },
        {
          name: 'headingTitleTextDecoration',
          label: 'Heading Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
        },
      ],
    },
  ],
  [BlockTypes.provider]: [],
  [BlockTypes.allProviders]: [],
  [BlockTypes.carouselStandardHorizontalBanner]: [],
  [BlockTypes.bannerwithbalancecitypeople]: [
    {
      name: 'title1',
      type: FieldTypes.input,
      rowSize: 4,
      label: 'Title 1',
      defaultValue: 'דוד המלך 29',
    },
    {
      name: 'title2',
      type: FieldTypes.input,
      rowSize: 4,
      label: 'Title 2',
      defaultValue: 'איכות חיים בכל צעד',
    },
  ],
  [BlockTypes.bannerwithbackgroundpic]: [],
  [BlockTypes.apartments]: [],
  [BlockTypes.onebigbutton]: [
    {
      name: 'actionButtons',
      type: FieldTypes.buttons,
      showOn: 'settings.enableButtons',
      defaultValue: [
        {
          label: 'התחברות למערכ',
          color: 'secondary',
          size: 'large',
          variant: 'contained',
          link: '#',
        },
      ],
      rowSize: 4,
      label: 'Action Buttons',
    },
  ],
  [BlockTypes.totalinfocitypeople]: [],
  [BlockTypes.servicelistcitypeople]: [],
  [BlockTypes.singleservicecitypeople]: [],
  [BlockTypes.balancecitypeople]: [],
  [BlockTypes.categoriesslider]: [],
  [BlockTypes.companylistcitypeople]: [],
  [BlockTypes.companydescription]: [],
  [BlockTypes.ordercitypeople]: [],
  [BlockTypes.historycitypeople]: [],
  [BlockTypes.supplierhistorycitypeople]: [],
  [BlockTypes.historysearchcitypeople]: [],
  [BlockTypes.footercitypeople]: [
    {
      name: 'footerMenuLinks',
      type: FieldTypes.iconLinksList,
      defaultValue: [
        { title: 'בית', path: '#' },
        { title: 'הארנק', path: '#' },
        { title: 'היסטוריה', path: '#' },
        { title: 'עזרה', path: '#' },
      ],
      label: 'Menu Links',
      rowSize: 2,
    },
  ],
  [BlockTypes.faq]: [
    {
      name: 'faqList',
      type: FieldTypes.textBlocksList,
      defaultValue: [
        { question: FAQ_CITY_PEOPLE[0].question, answer: FAQ_CITY_PEOPLE[0].answer },
        { question: FAQ_CITY_PEOPLE[1].question, answer: FAQ_CITY_PEOPLE[1].answer },
        { question: FAQ_CITY_PEOPLE[2].question, answer: FAQ_CITY_PEOPLE[2].answer },
        { question: FAQ_CITY_PEOPLE[3].question, answer: FAQ_CITY_PEOPLE[3].answer },
        { question: FAQ_CITY_PEOPLE[4].question, answer: FAQ_CITY_PEOPLE[4].answer },
      ],
      label: 'FAQ',
      rowSize: 4,
    },
  ],
  [BlockTypes.searchSection]: [],
  [BlockTypes.blogs]: [],
  [BlockTypes.newsletter]: [],
  [BlockTypes.reportBlock]: [],
  [BlockTypes.listingReports]: [],
  [BlockTypes.blogsTextAfter]: [],
  [BlockTypes.blog]: [],
  [BlockTypes.headActiveBanner]: [],
  [BlockTypes.bannersCarousel]: [],
  [BlockTypes.photoDescription]: [],
  [BlockTypes.networkInfo]: [],
  [BlockTypes.indentationBlock]: [],
  [BlockTypes.dividerBlock]: [],
  [BlockTypes.exchangeOption]: [],
  [BlockTypes.paymentMethods]: [],
  [BlockTypes.iframe]: [],
  [BlockTypes.contactcitypeople]: [],
  [BlockTypes.infoField]: [],
  [BlockTypes.buttonInfoBanner]: [],
};
