import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import Iconify from '../../../../../../components/iconify';
import { TableTable } from './table-table';
import { ComponentTypeProps } from '../../../../../../types/page-generator';

export const HistoryTab = ({ block }: ComponentTypeProps) => {
  const data_paid_1 = [
    {
      id: '1',
      date: '07.05.23',
      description: 'בקשה להעברה',
      regarding: 'מוטי אברמוב',
      domain: 'חינוך',
      status: 'הושלם',
    },
    {
      id: '2',
      date: '07.05.23',
      description: 'בקשה להעברה',
      regarding: 'מוטי אברמוב',
      domain: 'חינוך',
      status: 'הושלם',
    },
    {
      id: '3',
      date: '07.05.23',
      description: 'בקשה להעברה',
      regarding: 'מוטי אברמוב',
      domain: 'חינוך',
      status: 'הושלם',
    },
  ];

  return <TableTable block={block} dataPaid={data_paid_1} />;
};
