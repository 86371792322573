import { FC } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { ComponentTypeProps } from 'src/types/page-generator';

const ContactCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const title = block?.title;

  return (
    <Container maxWidth="sm" sx={{ pb: '100px' }}>
      <Typography
        variant="h4"
        sx={{ mb: '10px', fontSize: '32px', fontWeight: 400 }}
        align="center"
      >
        דברו איתנו
      </Typography>

      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <Typography variant="h6" align="center" sx={{ fontWeight: 400 }}>
          צרו איתנו קשר בכל נושא, אנחנו כאן בשבילכם באפליקציה ובמספר 3821*
        </Typography>
      </Box>

      <Box sx={{ mb: '16px', mt: '32px' }}>
        <TextField placeholder="שם" fullWidth />
      </Box>

      <Box sx={{ mb: '16px' }}>
        <TextField placeholder="מייל" fullWidth type="email" />
      </Box>

      <Box sx={{ mb: '16px' }}>
        <TextField placeholder="נושא הפנייה" fullWidth />
      </Box>

      <Box sx={{ mb: '16px' }}>
        <TextField placeholder="כתבו כאן את תוכן פנייתכם" fullWidth multiline rows={4} />
      </Box>

      <Box sx={{ mb: '16px', display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button variant="contained" sx={{ width: '50%' }}>
          אישור
        </Button>
      </Box>
    </Container>
  );
};

export default ContactCityPeople;
