import React, { FC, useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import { PriceTypeEnum } from 'src/types/product';
import { Ib2bProduct, PriceInterface } from 'src/types/b2b';
import { ComponentTypeProps } from 'src/types/page-generator';
import Iconify from 'src/components/iconify';

import { getContentValueFromProps } from '../../utils';
import { fCurrency } from '../../../../utils/format-number';

const BalanceCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { state, dispatch } = useAppContext();
  const balanceColor = 'black';
  const userTotalBalanceVoucher = useMemo(
    () =>
      state.products.reduce((acc: number, v: Ib2bProduct) => {
        const price = v.prices.find((p: PriceInterface) => p.type === PriceTypeEnum.MAX_PRICE);
        return Number.isNaN(Number(price?.value)) ? acc : acc + Number(price?.value);
      }, 0),
    [state.products]
  );
  const userTotalBalanceCard = state?.selectedProduct?.balance;

  const userTotalBalance = userTotalBalanceVoucher || userTotalBalanceCard;

  const summ = () => {
    let total = 0;
    state.virtualCards.map((item) => {
      total += +item.balance;
      return total;
    });
    return total;
  };

  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign'),
    [block?.settings.isAlternativeDesign]
  );

  const handleGoBack = () => dispatch({ type: ActionTypes.SET_SELECTED_APARTMENT, payload: null });

  return (
    <Stack direction="column" alignItems="center" sx={{ position: 'relative' }}>
      {state.selectedApartment && state.apartmentsCount > 1 && (
        <Box sx={{ position: 'absolute', top: 15, right: 15 }}>
          <Iconify
            icon="material-symbols-light:arrow-back-ios"
            color="secondary.lighter"
            width={18}
            onClick={handleGoBack}
          />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: '10px 20px',
          direction: 'rtl',
          borderRadius: 2.5,
          fontSize: '1.25rem',
          color: balanceColor,
          ...(!isAlternativeDesign && {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backgroundImage:
              'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
          }),
          ...(isAlternativeDesign && {
            padding: '10px 25px',
            backgroundColor: 'primary.light',
            borderRadius: 4,
          }),
        }}
      >
        {block?.settings?.variant === 'v1' ? (
          <Typography
            variant="h4"
            sx={{ ...(isAlternativeDesign && { color: 'primary.contrastText', fontWeight: 100 }) }}
          >
            {`₪ ${userTotalBalance}${isAlternativeDesign ? ' : יתרה למימוש' : ''}`}
          </Typography>
        ) : (
          <Typography
            variant="h4"
            sx={{ ...(isAlternativeDesign && { color: 'primary.contrastText', fontWeight: 100 }) }}
          >
            {`${fCurrency(summ())}${isAlternativeDesign ? ' : יתרה למימוש' : ''}`}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export default BalanceCityPeople;
