import { FC } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';

import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { useAuthContext } from 'src/auth/hooks';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Image from 'src/components/image';

import { ComponentTypeProps } from 'src/types/page-generator';

import { useTranslate } from '../../../../locales';
import { ButtonField } from '../../../../types/generator';
import { useContentContext } from '../../../../components/content';

const BigBannerHappyGift: FC<ComponentTypeProps> = ({ block }) => {
  const smDown = useResponsive('down', 'sm');
  const router = useRouter();
  const { dispatch } = useAppContext();
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const { t } = useTranslate();
  const smUp = useResponsive('up', 'sm');
  const openLoginForm = (pageName: string) => {
    router.push(pageName);
  };
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const mainTitle: string = typeof block?.mainTitle === 'string' ? block.mainTitle : '';
  const mainImage: string = typeof block?.mainImage === 'string' ? block.mainImage : '';
  const logo: string = typeof block?.logo === 'string' ? block.logo : '';

  const CARDS = [
    { image: '/assets/images/happy-gift/banners/blackCardImage.png' },
    { image: '/assets/images/happy-gift/banners/blueCardImageWithMargin.png' },
    { image: '/assets/images/happy-gift/banners/redCardImage.png' },
    { image: '/assets/images/happy-gift/banners/whiteCardImage.png' },
  ];

  return (
    <Grid container alignItems="center" p='0px 40px 0px 40px' flexDirection={smDown ? 'column' : 'row'}>
      <Grid item md={5} xs={12}>
        <Stack sx={{ alignItems: 'start' }} gap={2}>
          <Image src={logo || '/assets/images/happy-gift/logo.png'} sx={{ width: '100%' }} />
          <Typography
            sx={{
              color: block?.settings?.mainContentTextColor,
              fontWeight: block?.settings?.mainContentFontWeight,
              fontSize: block?.settings?.mainContentFontSize,
              fontStyle: block?.settings?.mainContentFontStyle,
              alignItems: block?.settings?.mainContentAlign,
              width: '70%',
              mt: 1,
            }}
          >
            {mainTitle}
          </Typography>
          <Stack
            alignItems="center"
            direction={{ xs: 'row-reverse', md: 'row-reverse' }}
            spacing={2}
            justifyContent="flex-end"
          >
            {block?.actionButtons?.map((menuButton: ButtonField, idx: number) => (
              <Button
                component={
                  menuButton?.link.includes('modal') || menuButton.link.includes('#')
                    ? Button
                    : NextLink
                }
                variant={menuButton?.variant || 'contained'}
                color={menuButton?.color || 'primary'}
                size={menuButton?.size || 'small'}
                key={`menuButton_${idx}`}
                sx={{
                  borderRadius: 10,
                  height: !smUp ? '40px' : '',
                  fontSize: !smUp ? '10px' : '',
                  px: !smUp ? '6px' : '',
                  textWrap: 'nowrap',
                }}
                href={
                  (!menuButton?.link.includes('modal') &&
                    !menuButton.isDialogToggler &&
                    !menuButton.link.includes('#') &&
                    menuButton?.link) ||
                  ''
                }
                onClick={() => {
                  if (menuButton.isDialogToggler && menuButton.modalId) {
                    setActiveDialog(menuButton.modalId);
                  }
                  if (menuButton.link.includes('#')) {
                    const newLink = menuButton?.link.replace('#', '');
                    window.location.href = `#${newLink}`;
                  }
                }}
              >
                {menuButton.label || `[${t('no label')}]`}
              </Button>
            ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item md={7} xs={12}>
        <Image src={mainImage || '/assets/images/happy-gift/banners/man.png'} />
      </Grid>
      {/* <Image src="/assets/images/happy-gift/cards-group.png" sx={{ mt: '-40px' }} /> */}
      <Box display="flex" mr={10}>
        {CARDS.map((card, ind: number) => (
          <Box
            key={ind}
            mr={-10}
            mt='-30px'
            sx={{
              cursor: 'pointer',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)',
                zIndex: 100,
              },
            }}
          >
            <Image src={card.image} alt="#" />
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default BigBannerHappyGift;
