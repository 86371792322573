import React, { FC, Key } from 'react';
import Slider from 'react-slick';

import Box from '@mui/material/Box';

import ShopBanner from './shop-banner';
import ShopBanner2 from './shop-banner-2';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import ThreeColumnsInfoWithIcons from './three-column-info-with-icons';
import Iconify from '../../../../components/iconify';

const AllShopBanners: FC<ComponentTypeProps> = ({ block }) => {
  const smDown = useResponsive('down', 'sm');
  const title = 'קטגוריות פופולריות';
  const cards = [
    {
      id: 1,
      component: <ShopBanner block={block} />,
    },
    {
      id: 2,
      component: <ShopBanner2 block={block} />,
    },
    {
      id: 3,
      component: <ThreeColumnsInfoWithIcons block={block} />,
    },
  ];

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: smDown ? '-20px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="primary.main" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: smDown ? '-20px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="primary.main" />
    </Box>
  );

  const settings = {
    autoplay: block?.settings?.autoplay || false,
    dots: true,
    arrows: !smDown,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomPrevArrow onClick={() => {}} />,
    prevArrow: <CustomNextArrow onClick={() => {}} />,
  };

  return (
    <Box
      sx={{
        mt: 4,
        '& .slick-slide': {
          height: smDown ? '600px' : '400px',
          direction: 'ltr',
          display: 'flex',
          alignItems: 'flex-end',
        },
        '& .slick-slider': {
          '& .slick-dots': {
            position: 'absolute',
            bottom: '10px',
            right: smDown ? '30%' : '43%',
            width: '150px',
            '& .slick-active': {
              '& button': {
                '&::before': {
                  color: '#27097A !important',
                  fontSize: '16px', // Increase font size for active dot
                },
              },
            },
            '& li': {
              '& button': {
                width: '50%',
                '&::before': {
                  color: '#27097A !important',
                  fontSize: '12px', // Increase font size for inactive dots
                },
              },
            },
          },
        },
      }}
    >
      <Slider {...settings}>
        {cards.map((item: any, index: Key) => (
          <Box key={index}>{item.component}</Box>
        ))}
      </Slider>
    </Box>
  );
};

export default AllShopBanners;
