import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Pagination from '@mui/material/Pagination';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Checkbox, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useTranslate } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { usePopover } from '../../../../components/custom-popover';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useTable, TableHeadCustom } from '../../../../components/table';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: any;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function ListingReports({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const TABLE_HEAD = [
    { id: 'date', label: t('Date'), width: 250 },
    { id: 'name', label: t('Name of document'), width: 400 },
  ];
  const table = useTable();
  const tableData = [
    { id: '1', date: '06/28/2024', name: 'Report 1' },
    { id: '2', date: '06/20/2024', name: 'Report 2' },
    { id: '3', date: '06/15/2024', name: 'Report 3' },
  ];
  return (
    <Stack alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography
          sx={{
            width: 1,
            fontWeight: 600,
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          Reports list
        </Typography>
        <Stack direction="row" gap={2}>
          <DatePicker label="Start" />
          <DatePicker label="End" />
          <TextField placeholder="search" />
        </Stack>
      </LocalizationProvider>

      <TableHeadCustom
        order={table.order}
        orderBy={table.orderBy}
        headLabel={TABLE_HEAD}
        rowCount={tableData.length}
        numSelected={table.selected.length}
        onSort={table.onSort}
        onSelectAllRows={(checked) =>
          table.onSelectAllRows(
            checked,
            tableData.map((row) => row.id)
          )
        }
      />
      <TableBody>
        {tableData
          .slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage)
          .map((row) => (
            <TableListRow
              key={row.id}
              row={row}
              selected={table.selected.includes(row.id)}
              onSelectRow={() => table.onSelectRow(row.id)}
              onDeleteRow={() => {}}
              onEditRow={() => {}}
            />
          ))}
      </TableBody>
      <Stack sx={{ mb: 2 }}>
        <Pagination count={50} variant="outlined" color="primary" />
      </Stack>
    </Stack>
  );
}

function TableListRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }: Props) {
  const { t } = useTranslate();

  const { name, date } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap', width: 300 }}>{date}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap', width: 300 }}>{name}</TableCell>

      <TableCell>
        <Stack direction="row" gap={3}>
          <Iconify icon="mdi:edit" />
          <Iconify icon="teenyicons:pdf-outline" />
          <Iconify icon="ic:outline-share" />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
