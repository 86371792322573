import { useRouter } from 'next/navigation';
import { FC, useMemo, useState, ChangeEvent } from 'react';

import { Box, Stack, Button, Container, TextField, Typography } from '@mui/material';

import { SERVICES } from 'src/_mock/_services';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { DEFAULT_MERCHANT_IMAGE } from 'src/_mock/_companies';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';

import { PriceInterface } from 'src/types/b2b';
import { ButtonField } from 'src/types/generator';
import { PriceTypeEnum } from 'src/types/product';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';

const ORDER_TEXT = 'תשלום חדש עבור';
const INPUT_PLACEHOLDER = 'הזן סכום';

const OrderCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { state, dispatch } = useAppContext();
  const { selectedMerchant, selectedProduct, selectedVirtualCard } = state;
  const [cost, setCost] = useState('');
  const router = useRouter();

  const [button] = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign'),
    [block?.settings.isAlternativeDesign]
  );

  const balance = selectedVirtualCard?.balance || 0;

  const isDisableButton = useMemo(() => !+balance || !+cost || +cost > +balance, [cost]);
  // const isDisableButton = !cost;

  const merchantImageLink = state.selectedMerchant?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${state.selectedMerchant.mainImageURL}`
    : DEFAULT_MERCHANT_IMAGE;

  const cardImageLinkVoucher =
    state.selectedVirtualCard?.mainImageURL &&
    `${BASIC_URL_FOR_IMAGES}${state.selectedVirtualCard.mainImageURL}`;

  const cardImageLinkCard =
    state.selectedProduct?.cardType?.mainImageURL &&
    `${BASIC_URL_FOR_IMAGES}${selectedProduct?.cardType?.mainImageURL}`;

  const imageLink = cardImageLinkVoucher || cardImageLinkCard || SERVICES[0].image;
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setCost(event.target.value);

  if (!selectedMerchant) {
    return (
      <Box
        my={10}
        sx={{
          maxWidth: 360,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          m: '30px auto',
        }}
      >
        <Typography textAlign="center" fontSize={22}>
          MERCHANT NOT SELECTED!
        </Typography>
        <Typography textAlign="center">You need to go to the list of merchants</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          margin: '0 auto',
          mt: 1.5,
          width: { xs: '100%' },
          ...(isAlternativeDesign && {
            border: (theme) => `solid 1px ${theme.palette.primary.main}`,
            borderRadius: 3,
            mb: 10,
            p: 1.6,
          }),
        }}
      >
        {isAlternativeDesign && (
          <Box
            width="100%"
            height={190}
            borderRadius="16px 16px 0 0"
            sx={{
              background: '#FFFFFF',
              backgroundImage: `url('${imageLink || ''}')`,
              backgroundPosition: '50%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
        )}
        {isAlternativeDesign ? (
          <Stack
            direction="column"
            mt={1}
            mb={1}
            sx={{
              width: '100%',
              position: 'relative',
              direction: 'ltr',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: 30,
                color: 'primary.light',
              }}
            >
              {ORDER_TEXT}
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: 22,
                lineHeight: '22px',
                color: 'primary.main',
                textAlign: 'center',
              }}
            >
              {selectedMerchant.title || ''}
            </Typography>

            <Iconify
              width={60}
              color="primary.light"
              icon="material-symbols-light:keyboard-arrow-right"
              onClick={() => router.push(`/${block?.redirect}`)}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                transform: 'translate(-20px, -10px)',
              }}
            />
          </Stack>
        ) : (
          <>
            {Boolean(selectedMerchant.title) && (
              <Typography textAlign="center" fontSize={18} mt={2} color="primary.darker">
                {selectedMerchant.title}
              </Typography>
            )}
            {Boolean(selectedMerchant.description) && (
              <Typography textAlign="center" variant="h6" color="black">
                {selectedMerchant.description}
              </Typography>
            )}
          </>
        )}
        <Box
          width={isAlternativeDesign ? 130 : 110}
          height={isAlternativeDesign ? 130 : 110}
          sx={{
            my: 2,
            background: '#FFFFFF',
            backgroundImage: `url('${merchantImageLink}')`,
            backgroundPosition: '50%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        />
        {isAlternativeDesign ? (
          <Box
            sx={{
              width: 140,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <TextField
              type="number"
              value={cost}
              variant="standard"
              placeholder={INPUT_PLACEHOLDER}
              fullWidth={false}
              inputProps={{
                style: { fontSize: 24, textAlign: 'center' },
              }}
              onChange={handleChange}
            />
            <Typography
              sx={{
                fontSize: 24,
                color: 'primary.main',
                position: 'absolute',
                right: 0,
              }}
            >
              {cost ? '₪' : ''}
            </Typography>
          </Box>
        ) : (
          <TextField
            type="number"
            value={cost}
            variant="outlined"
            placeholder={INPUT_PLACEHOLDER}
            label="amount"
            fullWidth
            onChange={handleChange}
          />
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            fullWidth
            disabled={isDisableButton}
            href={
              (!button?.link.includes('modal') && !button.isDialogToggler && button?.link) || ''
            }
            onClick={() => {
              dispatch({ type: ActionTypes.SET_ORDER_COST, payload: cost });

              if (button.isDialogToggler && button.modalId) {
                setActiveDialog(button.modalId);
              }
            }}
            sx={{
              borderRadius: 2,
              mt: 3,
              backgroundColor: 'primary.main',
              ...(isAlternativeDesign && {
                backgroundColor: 'primary.light',
                fontSize: 16,
                fontWeight: 100,
                borderRadius: 3,
                width: 'fit-content',
                minWidth: '150px',
                padding: '4px 40px',
                margin: '24px auto 2px',
              }),
            }}
          >
            שלם
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};

export default OrderCityPeople;
