import { FC } from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useLocales, useTranslate } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ButtonField } from '../../../../types/generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

const ThreeColumnsCardInfoWithIcons: FC<ComponentTypeProps> = ({ block }) => {
  const { t } = useTranslate();
  const { combinedColumnIcons }: any = block;
  const { state, dispatch } = useAppContext();

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  return (
    <>
      <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
        {combinedColumnIcons?.map((item: any, id: number) => (
          <Grid item md={4} xs={12} key={id}>
            <Card
              sx={{
                borderRadius: '15px',
                p: 4,
                height: `${block?.settings?.cardsHeight}px`,
              }}
            >
              <Stack alignItems="center" gap={2}>
                <Box
                  sx={{
                    width: '50px',
                    height: '50px',
                    display: 'grid',
                    placeItems: 'center',
                    background: block?.settings?.backgroundColor,
                    borderRadius: '50%',
                  }}
                >
                  <Iconify
                    icon={item?.icon}
                    sx={{
                      width: '25px',
                      height: '25px',
                      color: 'white',
                      margin: 'auto',
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: item?.contentTextColor,
                    fontSize: item?.contentFontSize,
                    fontWeight: item?.contentFontWeight,
                    fontStyle: item?.contentFontStyle,
                    textDecoration: item?.contentTextDecoration,
                    direction: block?.settings?.forceRtl ? 'rtl' : '',
                    textAlign: item?.contentTextAlignItems,
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  sx={{
                    color: item?.contentSubtitleColor,
                    fontSize: item?.contentSubtitleFontSize,
                    fontWeight: item?.contentSubtitleFontWeight,
                    fontStyle: item?.contentSubtitleFontStyle,
                    textDecoration: item?.contentSubtitleDecoration,
                    direction: block?.settings?.forceRtl ? 'rtl' : '',
                    textAlign: item?.contentSubtitleAlignItems,
                  }}
                >
                  {item?.subtitleText}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Stack direction="row-reverse" alignItems="center" spacing={1} mt={2} justifyContent="center">
        {block &&
          block.actionButtons &&
          block?.actionButtons.map((menuButton: any, id: number) => (
            <Button
              key={id}
              variant={menuButton?.buttonVariant || 'contained'}
              color={menuButton?.buttonBgColor || 'secondary'}
              size={menuButton?.buttonSize}
              sx={{
                borderRadius: menuButton.buttonBorderRadius,
                background: menuButton.isBackgroundGradient
                  ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                  : menuButton?.background,
                fontSize: '16px',
                px: 1.1,
                '&:hover': {
                  color: menuButton?.color,
                  background: menuButton.isBackgroundGradient
                    ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                    : menuButton?.background,
                },
              }}
              href={
                (!menuButton?.link.includes('modal') &&
                  !menuButton.isDialogToggler &&
                  menuButton?.link) ||
                ''
              }
              onClick={() => {
                if (menuButton.isDialogToggler && menuButton.modalId) {
                  setActiveDialog(menuButton.modalId);
                }
              }}
            >
              {menuButton?.label || `[${t('no label')}]`}
            </Button>
          ))}
      </Stack>
    </>
  );
};
export default ThreeColumnsCardInfoWithIcons;
