import Slider from 'react-slick';
import isEqual from 'lodash/isEqual';
import { useParams } from 'next/navigation';
import { Swiper as SwiperCore } from 'swiper/types';
import { FC, Key, useRef, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { Pagination } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';

import { ITag } from '../../../../types/tag';
import Iconify from '../../../../components/iconify';
import { useRouter } from '../../../../routes/hooks';
import { INetwork } from '../../../../types/networks';
import { IMerchant } from '../../../../types/merchant';
import SvgColor from '../../../../components/svg-color';
import { getNetworkById } from '../../../../api/network';
import { getProductsTags } from '../../../../api/product';
import { ICategoryItem } from '../../../../types/product';
import MerchantToolbar from './merchants/merchant-toolbar';
import { useAppContext } from '../../../../contexts/AppContext';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import MerchantCard from '../../../../components/cards/merchant-card';
import { AlternativeMerchant } from './merchants/alternativeMerchant';
import { getVirtualCardTypeById } from '../../../../api/virtualCardType';
import { Ib2bTableFilter, Ib2bTableFilterValue } from '../../../../types/b2b';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { useGetMerchants, useGetMerchantCategories } from '../../../../api/shop';
import {
  getMerchants,
  getMerchantsTags,
  getMerchantsCategories,
  getMerchantsByProductIds,
} from '../../../../api/merchant';

export const Merchants: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const swiperRef = useRef<SwiperCore | null>(null);
  const isMobile = useResponsive('down', 'sm');
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const { dispatch, state } = useAppContext();
  const LIMIT = block?.settings?.limit || 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMerchants, setCurrentMerchants] = useState<IMerchant[]>([]);
  const [localCategories, setLocalCategories] = useState([]);
  const [localProductsTags, setLocalProductsTags] = useState([]);
  const [merchants, setMerchants] = useState<IMerchant[]>([]);
  const [duplicatedItem, setDuplicatedItem] = useState<any>([]);
  const [localNetworks, setLocalNetworks] = useState<INetwork[]>([]);
  const combinedBlock: any = block?.combinedBlock || [];
  const params = useParams();
  const change = localStorage.getItem('change');
  const actionButtons: any = block?.actionButtons || [];
  const sliderRef = useRef<any>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const { merchants: merchantsCarousel } = useGetMerchants({ search: 'מש' });
  const { categories: merchantCategories } = useGetMerchantCategories({
    limit: 99,
    offset: 0,
  });
  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
    // dispatch(getProducts(activeCategory.id, newPage, itemsPerPage));
  };

  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 3000],
    search: '',
  };

  const [filters, setFilters] = useState(defaultFilters);
  const canReset = !isEqual(defaultFilters, filters);

  useEffect(() => {
    getMerchantsTags({
      limit: 999,
      offset: 0,
      dispatch,
    });
    getMerchantsCategories({
      limit: 999,
      offset: 0,
      dispatch,
    });
  }, []);
  useEffect(() => {
    getProductsTags(state.smbAccount.id, dispatch);
  }, []);

  useEffect(() => {
    if (isEqual(defaultFilters, filters)) setCurrentPage(1);
  }, [filters]);
  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  useEffect(() => {
    getNetworkById({ dispatch }).then((networks: INetwork[]) => {
      setLocalNetworks(networks);
    });
  }, []);

  useEffect(() => {
    const merchantTagIds: string[] = [];
    const productTagIds: string[] = [];
    const categoryIds: string[] = [];
    const { area, name, category, price, type } = filters;
    area.forEach((a: string) =>
      merchantTagIds.push(String(state.merchantsTags.find((mt: ITag) => mt.name === a)?.id))
    );
    category.forEach((c: string) =>
      categoryIds.push(String(merchantCategories.find((cat: any) => cat.title === c)?.id))
    );
    type.forEach((a: string) =>
      productTagIds.push(String(state.productsTags.find((pt: ITag) => pt.name === a)?.id))
    );
    if (!block?.settings?.enableCategoryFilter && !block?.settings?.enableTagsFilter) {
      if (params.title === 'product' || params.title === 'card') {
        if (change === 'virtualCard') {
          const id = Array.isArray(params.id) ? params.id[0] : params.id;
          getVirtualCardTypeById(id)
            .then((data) => setMerchants(data?.merchants))
            .catch((e) => console.log(e));
        }
        getMerchantsByProductIds({
          limit: LIMIT,
          offset: (currentPage - 1) * LIMIT,
          dispatch,
          search: filters.name,
          productIds: params.id,
          tagsIds: merchantTagIds,
          categoryIds,
        });
      }
    }
  }, [LIMIT, state.categoryIdsCollector, currentPage, filters]);

  // const filteredMerchants = state.merchants.filter((merchant) => !merchant.networkId);

  const dataFiltered = applyFilter({
    inputData: state.merchants,
    filters,
  });

  useEffect(() => {
    const array: any = [];
    block?.settings?.categories?.map((item: { value: string }) => {
      array.push(item.value);
      return null;
    });
    setLocalCategories(block?.settings?.enableCategoryFilter ? array : []);
  }, [block?.settings?.categories]);

  useEffect(() => {
    const array: any = [];
    block?.settings?.merchantsTags?.map((item: { value: string }) => {
      array.push(item.value);
      return null;
    });
    setLocalProductsTags(block?.settings?.enableTagsFilter ? array : []);
  }, [block?.settings?.merchantsTags]);

  const props =
    block?.settings?.isAlternativeDesign && !block?.settings?.partialSize
      ? {
          offset: (currentPage - 1) * LIMIT,
          search: filters.name,
          tagsIds: localProductsTags,
          categoryIds: localCategories,
          productIds: params.title === 'product' && params.id ? params.id : [],
          dispatch,
        }
      : {
          limit: LIMIT,
          offset: (currentPage - 1) * LIMIT,
          search: filters.name,
          tagsIds: localProductsTags,
          categoryIds: localCategories,
          productIds: params.title === 'product' && params.id ? params.id : [],
          dispatch,
        };

  useEffect(() => {
    if (block?.settings?.enableCategoryFilter || block?.settings?.enableTagsFilter) {
      getMerchants(props);
    }
    const merchantTagIds: string[] = [];
    const productTagIds: string[] = [];
    const categoryIds: string[] = [];
    const { area, name, category, price, type } = filters;
    area.forEach((a: string) =>
      merchantTagIds.push(String(state.merchantsTags.find((mt: ITag) => mt.name === a)?.id))
    );
    category.forEach((c: string) =>
      categoryIds.push(String(merchantCategories.find((cat: any) => cat.title === c)?.id))
    );
    type.forEach((a: string) =>
      productTagIds.push(String(state.productsTags.find((pt: ITag) => pt.name === a)?.id))
    );
    if (!block?.settings?.enableCategoryFilter || !block?.settings?.enableTagsFilter) {
      getMerchants({
        limit: LIMIT,
        offset: (currentPage - 1) * LIMIT,
        dispatch,
        search: filters.name,
        tagsIds: merchantTagIds,
        categoryIds,
      });
    }
  }, [currentPage, filters, localProductsTags, localCategories]);

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const handleResetArea = () => {
    handleFilters('area', []);
  };

  const handleResetCategory = () => {
    handleFilters('category', []);
  };

  const handleResetType = () => {
    handleFilters('type', []);
  };

  const filterView = (
    <>
      {block?.settings?.enableFilter &&
        !block?.settings?.customMode &&
        !block?.settings?.enableCategoryFilter &&
        !block?.settings?.enableTagsFilter && (
          <MerchantToolbar
            block={block}
            filters={filters}
            onFilters={handleFilters}
            //
            typeOptions={state.productsTags.map((mTag: ITag) => mTag.name)}
            categoryOptions={merchantCategories}
            areaOptions={state.merchantsTags.map((mTag: ITag) => mTag.name)}
            handleResetFilters={handleResetFilters}
            canReset={canReset}
            handleResetCategory={handleResetCategory}
            handleResetArea={handleResetArea}
            handleResetTags={handleResetType}
            handleResetName={() => {}}
          />
        )}
    </>
  );

  const ScrollbarContainer = styled('div')({
    display: 'flex',
    width: '100',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  });

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      handleFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [handleFilters]
  );

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFilters('name', event.target.value);
    },
    [handleFilters]
  );

  const handleClick = (categoryTitle: string) => {
    const event = {
      target: { value: [categoryTitle] },
    } as SelectChangeEvent<string[]>;

    handleFilterCategory(event);
  };

  const settings = {
    // dots: !(block?.settings?.isAlternativeDesign && !isMobile),
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: block?.settings?.isAlternativeDesign && !isMobile ? 3 : 1,
    slidesToScroll: 1,
    autoplay: block && block.settings ? block.settings.autoplay : false,
    autoplaySpeed: block && block.settings ? (block.settings.playSpeed || 3) * 1000 : 3 * 1000,
    afterChange: (current: number) => setActiveSlide(current),
  };

  const handleSlideChange = (index: number) => {
    setActiveSlide(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <Box
      sx={{
        display: isMobile && block?.settings?.disableOnMobile ? 'none' : 'block',
      }}
    >
      {!block?.settings?.isAlternativeDesign ? (
        <>
          <Stack flexWrap="wrap" flexDirection="row-reverse" justifyContent="center" gap={3}>
            <>
              <Stack width={1} my={3}>
                <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
              </Stack>
              <Drawer open={open} onClose={toggleDrawer(false)}>
                {filterView}
              </Drawer>
            </>
            {block?.settings?.customMode ? (
              <Grid container spacing={3} rowGap={3} justifyContent="center">
                {combinedBlock.map((merchant: any) => {
                  const network = localNetworks?.find(
                    (item: INetwork) => item.id === merchant.networkId
                  );
                  return (
                    <Grid key={merchant.merchant[0].id} item xs={4} justifyContent="center">
                      <MerchantCard
                        merchant={merchant.merchant[0]}
                        block={merchant}
                        network={network}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container spacing={3} rowGap={3} justifyContent="center">
                {dataFiltered?.map((merchant: any) => {
                  const network = localNetworks?.find(
                    (item: INetwork) => item.id === merchant.networkId
                  );
                  return (
                    <Grid
                      key={merchant.id}
                      item
                      xs={12}
                      md={4}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <MerchantCard merchant={merchant} block={block} network={network} />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Stack>
          <br />
          {!block?.settings?.customMode && (
            <Stack justifyContent="center">
              <Pagination
                onChange={handlePageChange}
                page={currentPage}
                count={Math.round(state.merchantCount / LIMIT) || 1}
                color="primary"
                sx={{ margin: '0 auto' }}
              />
            </Stack>
          )}
          <br />
        </>
      ) : (
        <>
          {block?.settings?.partialSize ? (
            <Box
              sx={{
                '& .MuiGrid-root': { width: '100%', margin: 0, px: 0 },
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between" my={1}>
                <Typography
                  sx={{
                    fontSize: isMobile ? '20px' : '24px',
                    fontWeight: 600,
                    fontStyle: 'normal',
                    lineHeight: '67%',
                    letterSpacing: '-0.24px',
                  }}
                >
                  מבצעים
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={() => router.push(`/${actionButtons[0]?.link}`)}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: isMobile ? '12px' : '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.24px',
                    }}
                  >
                    {actionButtons[0]?.label}
                  </Typography>
                  <Iconify
                    icon={
                      // params.lng !== 'he' ? 'iconamoon:arrow-right-2' : 'iconamoon:arrow-left-2'
                      'iconamoon:arrow-left-2'
                    }
                  />
                </Stack>
              </Stack>
              <Box
                sx={{
                  height: isMobile ? '230px' : '100%',
                  '& .slick-slide': {
                    direction: 'rtl',
                    px: { md: 1, xs: 0 },
                  },
                }}
              >
                <Slider ref={sliderRef} {...settings}>
                  {merchantsCarousel?.map((item: any, index: Key) => {
                    const imageLink = `${uploadProductsImage(`${item?.mainImageURL}`)}`;
                    return (
                      <Stack
                        key={index}
                        width={1}
                        sx={{
                          border: block?.settings?.partialSize ? '1px solid white' : 'none',
                          borderRadius: '15px',
                          backgroundColor: 'white',
                          p: 1,
                          width: block?.settings?.partialSize && '108px',
                          height: block?.settings?.partialSize && '208px',
                        }}
                      >
                        <Box
                          onClick={() => router.push(`/merchant/${item?.id}`)}
                          sx={{
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${imageLink})`,
                            backgroundRepeat: 'no-repeat',
                            height: '100%',
                            borderRadius: '10px',
                            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
                            mt: block?.settings?.enableRotation && '50px',
                            width: block?.settings?.enableRotation ? '85%' : '100%',
                            mx: block?.settings?.enableRotation && 'auto',
                            cursor: 'pointer',
                          }}
                        />
                      </Stack>
                    );
                  })}
                </Slider>
                {isMobile && (
                  <Stack
                    width={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                    sx={{
                      position: 'relative',
                      bottom: '50px',
                      '& .ellipse': {
                        border: '1px solid',
                        borderColor: block?.settings?.dotsColor,
                        borderRadius: '2.46px/2.5px',
                        height: '5px',
                        left: 0,
                        opacity: 0.8,
                        top: 0,
                        width: '5px',
                      },
                    }}
                  >
                    {(() => {
                      const indicators = [];
                      // eslint-disable-next-line no-plusplus
                      for (let id = 0; id < merchantsCarousel.length; id++) {
                        indicators.push(
                          <Stack alignItems="center" key={id}>
                            {activeSlide === id ? (
                              <SvgColor
                                color={block?.settings?.dotsColor}
                                src="/assets/images/line-5.svg"
                              />
                            ) : (
                              <Box className="ellipse" onClick={() => handleSlideChange(id)} />
                            )}
                          </Stack>
                        );
                      }
                      return indicators;
                    })()}
                  </Stack>
                )}
              </Box>
              {!isMobile && <Box sx={{ my: 2 }} />}
              <Grid
                container
                spacing={1}
                rowGap={3}
                justifyContent="center"
                sx={{ '& .MuiGrid-item': { p: 0, px: 1 }, justifyContent: 'space-between' }}
              >
                {dataFiltered
                  ?.slice(6, 15)
                  ?.map((merchant: IMerchant, id: number) => (
                    <AlternativeMerchant block={block} merchant={merchant} key={id} />
                  ))}
              </Grid>
            </Box>
          ) : (
            <Box>
              {isMobile ? (
                <>
                  <Stack width={1} my={3}>
                    <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
                  </Stack>
                  <Drawer open={open} onClose={toggleDrawer(false)}>
                    {filterView}
                  </Drawer>
                </>
              ) : (
                <>{filterView}</>
              )}
              <>
                {block?.settings?.enableCategoriesFilter && (
                  <Stack direction="row" my={2} justifyContent="center">
                    <ScrollbarContainer>
                      {merchantCategories.map((category: ICategoryItem) => (
                        <Chip
                          label={category.title}
                          sx={{
                            mx: 1,
                            background: 'linear-gradient(90deg, #0881EB 80%, #003DE2 100%)',
                            color: 'white',
                            borderRadius: '10px',
                          }}
                        />
                      ))}
                    </ScrollbarContainer>
                  </Stack>
                )}
                <Grid container spacing={3} rowGap={3} justifyContent="center">
                  {dataFiltered?.map((merchant: IMerchant, id: number) => (
                    <AlternativeMerchant block={block} merchant={merchant} key={id} />
                  ))}
                </Grid>
              </>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

function applyFilter({ inputData, filters }: { inputData: IMerchant[]; filters: Ib2bTableFilter }) {
  const { name, area, category } = filters;

  if (name) {
    inputData = inputData.filter(
      (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  // if (area.length) {
  //   inputData = inputData.filter((product) => area.includes(product.area));
  // }
  //
  // if (category.length) {
  //   inputData = inputData.filter((product) => category.includes(product.category));
  // }

  return inputData;
}
