// @mui
import Grid from '@mui/material/Unstable_Grid2';
// types

import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useTranslate } from '../../../../locales';
import { getContentValueFromProps } from '../../utils';
import { IPaymentCard } from '../../../../types/payment';
import { IAddressItem } from '../../../../types/address';
import { ButtonField } from '../../../../types/generator';
import FormProvider from '../../../../components/hook-form';
import { BlockType } from '../../../../types/page-generator';
//
import AccountBillingHistory from './account-billing-history';
import { useResponsive } from '../../../../hooks/use-responsive';
import { IUserAccountBillingHistory } from '../../../../types/user';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import AccountStatusHistory from './account-status-history';
import uuidv4 from '../../../../utils/uuidv4';
import { randomInArray } from '../../../../utils/random';

// ----------------------------------------------------------------------

type Props = {
  plans: {
    subscription: string;
    price: number;
    primary: boolean;
  }[];
  cards: IPaymentCard[];
  invoices: IUserAccountBillingHistory[];
  addressBook: IAddressItem[];
  block: BlockType | undefined;
};

export default function AccountBilling({ cards, plans, invoices, addressBook, block }: Props) {
  const getFieldValue = getContentValueFromProps(block);
  const defaultValues = {};
  const { dispatch, state } = useAppContext();
  const isMobile = useResponsive('down', 'sm');
  const [discount, setDiscount] = useState('');
  const [discounts, setDiscounts] = useState([
    {
      id: uuidv4(),
      discount: '23',
      status: 'Accepted',
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
    },
    {
      id: uuidv4(),
      discount: '30',
      status: 'Not Accepted',
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
    },
    {
      id: uuidv4(),
      discount: '35',
      status: 'Not Accepted',
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
    },
    {
      id: uuidv4(),
      discount: '15',
      status: 'Accepted',
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
    },
  ]);

  const { t } = useTranslate();
  const NewSchema = Yup.object().shape({});
  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const checkDiscount = () => {
    const data = {
      id: uuidv4(),
      status: randomInArray(['Accepted', 'Not Accepted']),
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
      discount,
    };

    setDiscounts((prevState) => [...prevState, data]);
    setDiscount('');
  };

  return (
    <Grid container spacing={5} disableEqualOverflow>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack sx={{ mt: 4 }}>
          <Typography sx={{ fontSize: '20px' }}>אחוז הנחה בתוקף 20%</Typography>
        </Stack>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'baseline'}
          mb={2}
          gap={1}
        >
          <Stack
            direction="row"
            width={isMobile ? 1 : '20%'}
            alignItems="center"
            gap={1}
            mt={isMobile ? 3 : 2}
          >
            <Typography sx={{ fontSize: '20px' }}>שינוי אחוז הנחה</Typography>
            <TextField
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              sx={{ '& input': { padding: '10px' }, width: '20%' }}
            />
            <Typography sx={{ fontSize: '20px' }}>%</Typography>
          </Stack>
          {actionButtons.map((menuButton: any, id: number) => (
            <Button
              key={id}
              variant={menuButton?.buttonVariant || 'contained'}
              color={menuButton?.buttonBgColor || 'secondary'}
              size={menuButton?.buttonSize}
              sx={{
                borderRadius: '5px',
                background: menuButton.isBackgroundGradient
                  ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                  : menuButton?.background,
                fontSize: '16px',
                px: 1.1,
                '&:hover': {
                  color: menuButton?.color,
                  background: menuButton.isBackgroundGradient
                    ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                    : menuButton?.background,
                },
              }}
              href={
                (!menuButton?.link.includes('modal') &&
                  !menuButton.isDialogToggler &&
                  menuButton?.link) ||
                ''
              }
              onClick={() => {
                if (menuButton.isDialogToggler && menuButton.modalId) {
                  setActiveDialog(menuButton.modalId);
                  checkDiscount();
                }
              }}
            >
              {menuButton?.label || `[${t('no label')}]`}
            </Button>
          ))}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" gap={2} width={isMobile ? 'auto' : 0.6} mr={3}>
              <DatePicker sx={{ width: '100%' }} label={`${t('From')}`} />
              <DatePicker sx={{ width: '100%' }} label={`${t('Until')}`} />
            </Stack>
          </LocalizationProvider>
        </Stack>
        <Stack gap={2} direction="row" width={1}>
          {/* <RHFSelect sx={{ width: 0.5 }} native name="class" label={`${t('Select a club')}`}> */}
          {/*  {[ */}
          {/*    'קטגוריות', */}
          {/*    'גיפט קארד לאופנה ורשתות', */}
          {/*    'תורמים ומחזקים ', */}
          {/*    'שוברים למסעדות', */}
          {/*    'אחי ישראלי', */}
          {/*    'מתנות ליום הולדת', */}
          {/*    'גיפט קארד לנופש וחופשות', */}
          {/*    'שוברים לארוחת בוקר', */}
          {/*    'מתנות עד הבית', */}
          {/*    'שוברים לספא', */}
          {/*    'מתנה לגיוס', */}
          {/*    'חזרה לביה"ס', */}
          {/*    'מתנות לאישה', */}
          {/*    'מתנות למורה ולגננת', */}
          {/*  ].map((category) => ( */}
          {/*    <option key={category}>{category}</option> */}
          {/*  ))} */}
          {/* </RHFSelect> */}
        </Stack>
      </FormProvider>
      <Grid xs={12} md={12}>
        <AccountStatusHistory discounts={discounts} />
      </Grid>
      <Grid xs={12} md={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" gap={2} width={isMobile ? 'auto' : 1} mr={3} mb={4}>
            <DatePicker sx={{ width: '100%' }} label={`${t('From')}`} />
            <DatePicker sx={{ width: '100%' }} label={`${t('Until')}`} />
          </Stack>
        </LocalizationProvider>
        <AccountBillingHistory invoices={invoices} />
      </Grid>
    </Grid>
  );
}
