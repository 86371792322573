'use client';

import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// routes
// import { PATH_DASHBOARD as paths } from 'src/routes/paths';
// _mock
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
// import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useLocales, useTranslate } from 'src/locales';
//
import PersonalInformation from '../personal-information';
import Payments from '../payments';
import Property from '../property';
import Water from '../water';
import Veterinary from '../veterinary';
import Inquiries from '../inquiries';
import { ComponentTypeProps } from '../../../../../types/page-generator';

// ----------------------------------------------------------------------

export type AccountTab = {
  value: string;
  label: string;
  icon: JSX.Element;
};

export const TABS: AccountTab[] = [
  {
    value: 'personal',
    label: 'Personal information',
    icon: <Iconify icon="prime:user" width={24} />,
  },
  {
    value: 'payments',
    label: 'Payments',
    icon: <Iconify icon="system-uicons:credit-card" width={24} />,
  },
  {
    value: 'property',
    label: 'Property',
    icon: <Iconify icon="tdesign:home" width={20} />,
  },
  {
    value: 'water',
    label: 'Water',
    icon: <Iconify icon="ion:water-outline" width={20} />,
  },
  {
    value: 'veterinary',
    label: 'Veterinary',
    icon: <Iconify icon="la:dog" width={24} />,
  },
  {
    value: 'inquiries',
    label: 'Inquiries',
    icon: <Iconify icon="grommet-icons:contact" width={18} />,
  },
];

// ----------------------------------------------------------------------

export default function ResidentAccountView({ block }: ComponentTypeProps) {
  const settings = useSettingsContext();
  const { t } = useTranslate();
  const [currentTab, setCurrentTab] = useState('personal');

  useEffect(() => {
    if (!block?.personalTabItem) {
      setCurrentTab('payments');
      if (!block?.paymentsTabItem) {
        setCurrentTab('property');
        if (!block?.propertyTabItem) {
          setCurrentTab('water');
          if (!block?.waterTabItem) {
            setCurrentTab('veterinary');
            if (!block?.veterinaryTabItem) {
              setCurrentTab('inquiries');
              if (!block?.inquiriesTabItem) {
                setCurrentTab('personal');
              }
            }
          }
        }
      }
    }
  }, [block]);

  const personalInformationTabItem = Object.prototype.hasOwnProperty.call(
    block,
    'personalTabItem',
  )
    ? block?.personalTabItem
    : true;
  const paymentsTabItem = Object.prototype.hasOwnProperty.call(block, 'paymentsTabItem')
    ? block?.paymentsTabItem
    : true;
  const propertyTabItem = Object.prototype.hasOwnProperty.call(block, 'propertyTabItem')
    ? block?.propertyTabItem
    : true;
  const waterTabItem = Object.prototype.hasOwnProperty.call(block, 'waterTabItem')
    ? block?.waterTabItem
    : true;
  const veterinaryMedicineTabItem = Object.prototype.hasOwnProperty.call(
    block,
    'veterinaryTabItem',
  )
    ? block?.veterinaryTabItem
    : true;
  const inquiriesAndUpdatesTabItem = Object.prototype.hasOwnProperty.call(
    block,
    'inquiriesTabItem',
  )
    ? block?.inquiriesTabItem
    : true;

  const activeTabNames: string[] = [];

  if (personalInformationTabItem) activeTabNames.push('personal');
  if (paymentsTabItem) activeTabNames.push('payments');
  if (propertyTabItem) activeTabNames.push('property');
  if (waterTabItem) activeTabNames.push('water');
  if (veterinaryMedicineTabItem) activeTabNames.push('veterinary');
  if (inquiriesAndUpdatesTabItem) activeTabNames.push('inquiries');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
          (tab: AccountTab) => (
            <Tab key={tab.value} label={`${t(`${tab.label}`)}`} icon={tab.icon} value={tab.value} />
          )
        )}
      </Tabs>

      {currentTab === 'personal' && personalInformationTabItem && (
        <PersonalInformation block={block} />
      )}

      {currentTab === 'payments' && paymentsTabItem && <Payments block={block} />}

      {currentTab === 'property' && propertyTabItem && <Property block={block} />}

      {currentTab === 'water' && waterTabItem && <Water block={block} />}

      {currentTab === 'veterinary' && veterinaryMedicineTabItem && <Veterinary block={block} />}

      {currentTab === 'inquiries' && inquiriesAndUpdatesTabItem && <Inquiries block={block} />}
    </Container>
  );
}
