import { Box, Checkbox, Divider, Grid, Radio, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import React from 'react';
import Button from '@mui/material/Button';
import Iconify from '../../../../../../components/iconify';
import { ComponentTypeProps } from '../../../../../../types/page-generator';

export const ChannelsTab = ({ block }: ComponentTypeProps) => (
  <Stack gap={2}>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiPaper-elevation': { boxShadow: 'none' },
      }}
      gap={1}
    >
      <Grid md={3.9} xs={12}>
        <Stack>
          <Typography>קבלת עדכונים מהרשות דרך:</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack direction="row" alignItems="center">
            <Checkbox />
            <Typography>דוא``ל</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Checkbox />
            <Typography>whatsapp</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Checkbox />
            <Typography>SMS</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid md={3.9} xs={12}>
        <Stack>
          <Typography>קקבלת חשבון ארנונה מהרשות דרך:</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack direction="row" alignItems="center">
            <Checkbox />
            <Typography>דוא``ל</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Checkbox />
            <Typography>whatsapp</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Checkbox />
            <Typography>SMS</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid md={3.9} xs={12}>
        <Stack>
          <Typography>קבעו תזכורת קבועה לתשלום</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack direction="row" alignItems="center">
            <Radio />
            <Typography>1 לחודש</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Radio />
            <Typography>10 לחודש</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Radio />
            <Typography>בחירת תאריך מסויים בחודש</Typography>
          </Stack>
        </Stack>
        <Button
          variant="outlined"
          sx={{
            color: '#0059d2',
            borderRadius: '25px',
            px: '40px',
            border: '1px solid #0059d2',
          }}
        >
          שמירה
        </Button>
      </Grid>
    </Grid>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiPaper-elevation': { boxShadow: 'none' },
      }}
      gap={1}
    >
      <Grid md={2.9} xs={12}>
        <Stack>
          <Typography sx={{ fontWeight: 600 }}>יצירת קשר</Typography>
          <Typography>כל הדרכים לדבר איתנו</Typography>
          <Stack direction="row" gap={1}>
            <Iconify icon="ic:outline-phone" color="#0059d2" />
            <Typography>03-654564</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Iconify icon="entypo:email" color="#0059d2" />
            <Typography>example@gmail.com</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Iconify icon="ic:outline-place" color="#0059d2" />
            <Typography>ההסתדרות 12, פתח תקווה</Typography>
          </Stack>
          <Typography>123456</Typography>
        </Stack>
      </Grid>
      <Grid md={2.9} xs={12}>
        <Stack>
          <Typography sx={{ fontWeight: 600 }}>שעות קבלת קהל</Typography>
          <Typography sx={{ fontWeight: 600 }}>ימים א’-ג’</Typography>
          <Typography>שעות 09:00-13:00</Typography>
          <Typography>שעות 15:00-16:00</Typography>
          <Typography sx={{ fontWeight: 600 }}>ימים ד’-ה’</Typography>
          <Typography>שעות 08:00-12:00</Typography>
        </Stack>
      </Grid>
      <Grid md={2.9} xs={12}>
        <Stack>
          <Typography sx={{ fontWeight: 600 }}>שעות קבלת קהל</Typography>
          <Typography sx={{ fontWeight: 600 }}>ימים א’-ג’</Typography>
          <Typography>שעות 09:00-13:00</Typography>
          <Typography>שעות 15:00-16:00</Typography>
          <Typography sx={{ fontWeight: 600 }}>ימים ד’-ה’</Typography>
          <Typography>שעות 08:00-12:00</Typography>
        </Stack>
      </Grid>
      <Grid md={2.9} xs={12}>
        <Stack>
          <Stack direction="row">
            <Iconify icon="ep:arrow-left-bold" />
            <Typography>לכל מחלקות הרשות</Typography>
          </Stack>
          <Stack direction="row">
            <Iconify icon="ep:arrow-left-bold" />
            <Typography> אתר הרשות</Typography>
          </Stack>
          <Stack direction="row">
            <Iconify icon="ep:arrow-left-bold" />
            <Typography>זימון תורים</Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
    <Stack alignItems="center">
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '& .MuiPaper-elevation': { boxShadow: 'none' },
        }}
        gap={1}
      >
        <Grid md={3.9} xs={12}>
          <Stack alignItems="center">
            <Iconify icon="ph:question-bold" />
            <Typography>שיחה עם</Typography>
            <Typography>צ’אטבוט רשותי</Typography>
            <Button
              variant="outlined"
              sx={{
                color: '#0059d2',
                borderRadius: '25px',
                px: '40px',
                border: '1px solid #0059d2',
              }}
            >
              התחלת שיחה
            </Button>
          </Stack>
        </Grid>
        <Grid md={3.9} xs={12}>
          <Stack alignItems="center">
            <Iconify icon="tdesign:call-1" />
            <Typography>מוקד שירות טלפוני</Typography>
            <Typography>*106</Typography>
            <Button
              variant="outlined"
              sx={{
                color: '#0059d2',
                borderRadius: '25px',
                px: '40px',
                border: '1px solid #0059d2',
              }}
            >
              התחלת שיחה
            </Button>
          </Stack>
        </Grid>
        <Grid md={3.9} xs={12}>
          <Stack alignItems="center">
            <Iconify icon="ri:whatsapp-line" />
            <Typography>מוקד עירוני וואטצאפ</Typography>
            <Typography>050-1324567</Typography>
            <Button
              variant="outlined"
              sx={{
                color: '#0059d2',
                borderRadius: '25px',
                px: '40px',
                border: '1px solid #0059d2',
              }}
            >
              התחלת שיחה
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  </Stack>
);
