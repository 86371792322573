import 'swiper/css';
import Link from 'next/link';
import isEqual from 'lodash/isEqual';
import { FC, useMemo, useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Paper, Stack, useTheme, Checkbox, TextField } from '@mui/material';

import { useTranslate } from 'src/locales';
import { searchProducts } from 'src/api/product';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';

import { ComponentTypeProps } from 'src/types/page-generator';
import { Ib2bProduct, ICategoryItem, Ib2bTableFilter, Ib2bTableFilterValue } from 'src/types/b2b';

import { ITag } from '../../../../types/tag';
import { useRouter } from '../../../../routes/hooks';
import SanitizeHTML from '../../../../utils/sanitize';
import { useGetMerchants } from '../../../../api/shop';
import { getMerchants } from '../../../../api/merchant';
import { getNetworkByIds } from '../../../../api/network';
import { useBoolean } from '../../../../hooks/use-boolean';
import { ProductCard } from '../../../products/product-card';
import { IBranche, INetwork } from '../../../../types/networks';
import { useResponsive } from '../../../../hooks/use-responsive';
import BasicModal from '../../../../components/modals/basic-modal';
import { BASIC_URL_FOR_IMAGES } from '../../../../constants/common';
import MerchantToolbar from '../happy-gift/merchants/merchant-toolbar';

// ----------------------------------------------------------------------

const DEFAULT_FILTERS: Ib2bTableFilter = {
  name: '',
  type: [], // productTags(id)
  category: [],
  area: [], // merchantTags(id)
  price: [0, 3000],
  search: '',
  merchantsIds: [],
};

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

const SearchPageSection: FC<ComponentTypeProps> = ({ block }) => {
  const theme = useTheme();
  const router = useRouter();
  const { t } = useTranslate();
  const { dispatch, state } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const mainImage = block?.mainImage as string;
  const src = mainImage ? uploadProductsImage(mainImage) : '/assets/background/overlay_2.jpg';
  const popover = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const canReset = !isEqual(DEFAULT_FILTERS, filters);
  const [searchQueryNetworks, setSearchQueryNetworks] = useState('');
  const products = useMemo(() => state.productsSearch, [state.productsSearch]);
  const productsSearchCount = useMemo(() => state.productsSearchCount, [state.productsSearchCount]);
  const categories = useMemo(() => state.search.categories, [state.search.categories]);
  const merchants = useMemo(() => state.merchants, [state.merchants]);
  const networks = useMemo(() => state.search.networks, [state.search.networks]);
  const tags = useMemo(() => state.search.tags, [state.search.tags]);
  const isMobile = useResponsive('down', 'sm');
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const productTags = useMemo(
    () => tags.filter((tag: ITag) => Array.isArray(tag.products) && tag.products.length),
    [tags]
  );
  const merchantsTags = useMemo(
    () => tags.filter((tag: ITag) => Array.isArray(tag.merchants) && tag.merchants.length),
    [tags]
  );

  const [filteredMerchants, setFilteredMerchants] = useState<INetwork[]>([]);
  const [filterMerchantName, setFilterMerchantName] = useState('');
  const [selectedMerchantIds, setSelectedMerchantIds] = useState<string[]>([]);
  const [selectedNetworksIds, setSelectedNetworksIds] = useState<string[]>([]);
  const [merchantInfo, setMerchantInfo] = useState<INetwork>();
  const [tabValue, setValue] = useState('1');
  const { entityType, filterQuery } = state;
  const { merchants: localMerchants, merchantsLoading } = useGetMerchants({
    search: filterQuery,
  });
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [productOpenPrices, setProductOpenPrices] = useState<ProductOpenPrice[]>([]);

  const handleOpenPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: Ib2bProduct,
    maxPrice: number,
    minPrice: number
  ) => {
    const { value } = event.target;
    const newPrice: ProductOpenPrice = {
      value: product.id,
      title: product.title,
      price: parseInt(value, 10),
    };

    if (newPrice.price > maxPrice) {
      newPrice.price = maxPrice;
    }

    setProductOpenPrices((prevState: ProductOpenPrice[]) => {
      const updatedPrices = [...prevState.filter((price) => price.value !== product.id), newPrice];
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedPrices });
      return updatedPrices;
    });
    const localDataString: string | null = localStorage.getItem('openPrice');

    if (localDataString) {
      const localData: ProductOpenPrice[] = JSON.parse(localDataString);
      const updated: ProductOpenPrice[] = [
        ...localData.filter((price: ProductOpenPrice) => price.value !== product.id),
        newPrice,
      ];
      localStorage.setItem('openPrice', JSON.stringify(updated));
    } else {
      localStorage.setItem('openPrice', JSON.stringify([newPrice]));
    }
  };

  useEffect(() => {
    getMerchants({
      dispatch,
    });
  }, []);

  useEffect(() => {
    if (block?.openPriceProducts) {
      const payload = block?.openPriceProducts;
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload });
      dispatch({
        type: ActionTypes.SET_OPEN_PRICE_PRODUCTS_FROM_BUILDER,
        payload,
      });
    }
  }, [block?.openPriceProducts]);

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSelectMerchant = (smId: string) => () => {
    getNetworkByIds({ id: smId, dispatch }).then((item: INetwork) => {
      const currentId = String(item.branches[0].id);
      if (selectedMerchantIds.includes(String(item.branches[0].id))) {
        setSelectedMerchantIds((prev: string[]) =>
          prev.filter((exId: string) => exId !== currentId)
        );
        setSelectedNetworksIds((prev: string[]) => prev.filter((exId: string) => exId !== smId));
      } else {
        setSelectedMerchantIds((prev: string[]) => [...prev, currentId]);
        setSelectedNetworksIds((prev: string[]) => [...prev, smId]);
      }
    });
  };

  const reset = () => {
    setFilterMerchantName('');
    setFilteredMerchants([]);
    setSelectedMerchantIds([]);
    setSelectedNetworksIds([]);
    const payload = {
      type: '',
      searchQuery: '',
    };
    dispatch({ type: ActionTypes.SET_FILTER_QUERY, payload });
  };

  const handleFilterName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterMerchantName(event.target.value);
  }, []);

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  const handleResetFilters = () => setFilters(DEFAULT_FILTERS);
  const handleResetCategory = () => {
    setFilters((prevState) => ({
      ...prevState,
      category: [],
    }));
  };
  const handleResetTags = () => {
    setFilters((prevState) => ({
      ...prevState,
      type: [],
    }));
  };
  const handleResetName = () => {
    setFilters((prevState) => ({
      ...prevState,
      name: '',
    }));
    const payload = {
      type: '',
      searchQuery: '',
    };
    dispatch({ type: ActionTypes.SET_FILTER_QUERY, payload });
  };
  const handleResetArea = () => {
    setFilters((prevState) => ({
      ...prevState,
      area: [],
    }));
  };

  useEffect(() => {
    if (filterMerchantName) {
      setFilteredMerchants(
        networks.filter((network: INetwork) => network.name.includes(filterMerchantName))
      );
    } else {
      setFilteredMerchants([]);
    }
  }, [filterMerchantName]);

  useEffect(() => {
    if (entityType === 'Products' || entityType === undefined) handleFilters('name', filterQuery);
    if (entityType === 'Merchants') {
      setSelectedMerchantIds(() =>
        localMerchants && localMerchants[0] ? [localMerchants[0].id.toString()] : []
      );
    }
    if (entityType === 'Categories') {
      handleFilters(
        'category',
        typeof filterQuery === 'string' ? filterQuery.split(',') : filterQuery
      );
    }
  }, [filterQuery, entityType, filters.name, localMerchants]);

  useEffect(() => {
    const merchantTagIds: string[] = [];
    const productTagIds: string[] = [];
    const categoryIds: string[] = [];

    const { area, name, category, price, type } = filters;
    type.forEach((fType: string) =>
      productTagIds.push(String(productTags.find((pt: ITag) => pt.name === fType)?.id))
    );
    area.forEach((a: string) =>
      merchantTagIds.push(String(merchantsTags.find((mt: ITag) => mt.name === a)?.id))
    );
    category.forEach((c: string) =>
      categoryIds.push(String(categories.find((cat: ICategoryItem) => cat.title === c)?.id))
    );

    searchProducts(state.smbAccount.id, {
      dispatch,
      limit: block?.settings.limit || 9,
      offset: (currentPage - 1) * itemsPerPage,
      minPrice: price[0] ?? 0,
      maxPrice: price[1] ?? 3000,
      productTagIds,
      merchantTagIds,
      categoryIds,
      search: name,
      merchantIds: selectedMerchantIds,
    });
  }, [filters, currentPage, selectedMerchantIds.length]);
  const renderList = (
    <>
      {/* {!state.loadingSearchProduct && ( */}
      {/*  <> */}
      {products?.map((product: Ib2bProduct, ind: number) => (
        <ProductCard
          key={ind}
          // @ts-ignore
          block={block}
          product={product}
          mode="notCustom"
          openPriceProducts={block?.openPriceProducts}
          handleOpenPriceChange={handleOpenPriceChange}
          productOpenPrices={productOpenPrices}
        />
      ))}
      {/* </> */}
      {/* )} */}
    </>
  );

  const changeMerchantInfo = (network: INetwork) => {
    popover.onTrue();
    getNetworkByIds({ id: network.id, dispatch }).then((item: INetwork) => {
      setMerchantInfo(item);
    });
  };

  const filterView = (
    <MerchantToolbar
      block={block}
      filters={filters}
      onFilters={handleFilters}
      //
      typeOptions={productTags.map((pTag: ITag) => pTag.name)}
      categoryOptions={categories}
      areaOptions={merchantsTags.map((mTag: ITag) => mTag.name)}
      handleResetFilters={handleResetFilters}
      canReset={canReset}
      handleResetCategory={handleResetCategory}
      handleResetArea={handleResetArea}
      handleResetTags={handleResetTags}
      handleResetName={handleResetName}
    />
  );

  const networksView = (
    <Grid
      item
      md={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: 1,
      }}
    >
      <Typography
        sx={{
          // background: '#4891D2',
          width: '100%',
          // textAlign: 'center',
          opacity: '0.8',
          fontSize: '20px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          fontWeight: 600,
          my: 1,
        }}
      >{`${t('Networks')}`}</Typography>
      <Paper elevation={3} sx={{ width: '100%', mb: 2 }}>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 1 },
          }}
        >
          <TextField
            sx={{
              '& input': { direction: 'ltr' },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '0px',
              },
            }}
            fullWidth
            value={filterMerchantName}
            onChange={handleFilterName}
            placeholder={`${t('חיפוש...')}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {filterMerchantName.length ? (
                    <Iconify
                      icon="jam:close"
                      sx={{ color: 'text.disabled', cursor: 'pointer' }}
                      onClick={() => setFilterMerchantName('')}
                    />
                  ) : (
                    ''
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Scrollbar
            sx={{
              maxHeight: '500px',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          >
            {selectedMerchantIds.length !== 0 ? (
              <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1} my={1}>
                <Iconify
                  onClick={reset}
                  icon="tabler:trash"
                  sx={{
                    cursor: 'pointer',
                    color: selectedMerchantIds.length === 0 ? 'gray' : 'red',
                  }}
                />
                <Typography>נבחר</Typography>
                <Typography>{selectedMerchantIds.length}</Typography>
              </Stack>
            ) : (
              ''
            )}
            {filterMerchantName
              ? filteredMerchants?.map((network: INetwork) => (
                  <MenuItem key={network.id} value={network?.name} sx={{ whiteSpace: 'balance' }}>
                    <Checkbox
                      onChange={handleSelectMerchant(String(network.id))}
                      disableRipple
                      size="small"
                      checked={selectedNetworksIds.includes(String(network.id))}
                    />
                    <Typography
                      sx={{ fontSize: '14px!important', cursor: 'pointer' }}
                      onClick={() => changeMerchantInfo(network)}
                    >
                      {network?.name}
                    </Typography>
                  </MenuItem>
                ))
              : networks &&
                networks?.map((network: INetwork) => (
                  <MenuItem key={network.id} value={network?.name} sx={{ whiteSpace: 'balance' }}>
                    <Checkbox
                      onChange={handleSelectMerchant(String(network.id))}
                      disableRipple
                      size="small"
                      checked={selectedNetworksIds.includes(String(network.id))}
                    />
                    <Typography
                      sx={{ fontSize: '14px!important', cursor: 'pointer' }}
                      onClick={() => changeMerchantInfo(network)}
                    >
                      {network?.name}
                    </Typography>
                  </MenuItem>
                ))}
          </Scrollbar>
        </FormControl>
      </Paper>
    </Grid>
  );

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ mt: { md: block?.settings?.enableImage ? '-50px' : '', xs: '' } }}
    >
      {isMobile ? (
        <>
          <Stack width={1}>
            <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
          </Stack>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            <Stack>
              {filterView}
              <Box height={0.5}>{networksView}</Box>
            </Stack>
          </Drawer>
        </>
      ) : (
        <Box
          sx={{
            height: {
              md: block?.settings?.enableImage ? theme.spacing(75) : '150px',
              xs: block?.settings?.enableImage ? theme.spacing(75) : '450px',
            },
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {block?.settings?.enableImage && !isMobile && <Image src={src} />}
          <Box sx={{ zIndex: '1', position: 'absolute', bottom: 0, width: '100%' }}>
            <Paper
              sx={{ mx: 2, mb: 2, p: 2, justifyContent: 'center' }}
              component={Stack as any}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              {filterView}
            </Paper>
          </Box>
        </Box>
      )}

      {/* {block?.settings?.enableTags && (
        <Scrollbar>
          <Stack direction="row" spacing={1} mb={2}>
            {[].map((tag, tagIndex) => (
              <Chip
                label={tag.label}
                key={`${tag.name}.${tagIndex}}`}
                color={tags.includes(tag.name) ? 'primary' : 'default'}
                onClick={() => handleClickTag(tag.name)}
                variant={tags.includes(tag.name) ? 'filled' : 'outlined'}
              />
            ))}
          </Stack>
        </Scrollbar>
      )} */}

      <Grid container>
        {!isMobile && <>{networksView}</>}
        <Grid item md={isMobile ? 12 : 9}>
          <Grid
            container
            gap={3}
            justifyContent="center"
            // gridTemplateColumns={{
            //   xs: 'repeat(1, 1fr)',
            //   sm: 'repeat(1, 1fr)',
            //   md: 'repeat(3, 1fr)',
            //   lg: 'repeat(3, 1fr)',
            // }}
          >
            {renderList}
          </Grid>
          <Stack justifyContent="center" width={1}>
            <Pagination
              onChange={handlePageChange}
              count={Math.round(productsSearchCount / itemsPerPage)}
              color="primary"
              sx={{ margin: '32px auto' }}
            />
          </Stack>
        </Grid>
      </Grid>
      <BasicModal open={popover.value} onClose={() => popover.onFalse()}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="ענפים" value="1" />
                <Tab label="פרטים" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              {' '}
              <Stack alignItems="flex-start" sx={{ '& .rtl-jjtu05': { width: '100%' } }}>
                <TextField
                  sx={{ py: 2 }}
                  fullWidth
                  placeholder="חיפוש..."
                  value={searchQueryNetworks}
                  onChange={(e) => setSearchQueryNetworks(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {!!searchQueryNetworks.length && (
                          <Iconify
                            icon="iconamoon:close-fill"
                            sx={{ color: 'text.disabled', cursor: 'pointer' }}
                            onClick={() => setSearchQueryNetworks('')}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Scrollbar sx={{ height: smUp ? '400px' : '150px', width: '100%' }}>
                  <Stack gap={2} height="450px">
                    {merchantInfo &&
                      merchantInfo.branches
                        .filter((branch: IBranche) =>
                          branch.title.toLowerCase().includes(searchQueryNetworks.toLowerCase())
                        )
                        .map((branch: IBranche) => (
                          <Stack>
                            <Typography
                              onClick={() => router.push(`/merchant/${branch.id}`)}
                              variant="h5"
                              sx={{ cursor: 'pointer' }}
                            >
                              {branch?.title}
                            </Typography>
                            <Image
                              onClick={() => router.push(`/merchant/${branch.id}`)}
                              src={uploadProductsImage(
                                branch?.mainImageURL
                                  ? uploadProductsImage(branch?.mainImageURL)
                                  : '/assets/images/network-default.png'
                              )}
                              sx={{ '& img': { width: '100px', cursor: 'pointer' } }}
                            />
                            <SanitizeHTML html={branch?.content || ''} />
                            <Link href={branch?.url || ''} target="_blank">
                              <Typography sx={{ textDecoration: 'underline' }}>
                                לאתר בית העסק לחץ כאן
                              </Typography>
                            </Link>
                            {merchantInfo?.asterisks && (
                              <Typography
                                sx={{
                                  textAlign: 'start',
                                  width: '100%',
                                  fontSize: '10px',
                                  mt: 1,
                                  height: '10px',
                                  color: 'red',
                                }}
                              >
                                {merchantInfo?.asterisks && merchantInfo?.asterisks[0] && '*'}
                                {merchantInfo?.asterisks && merchantInfo?.asterisks[0]?.description}
                              </Typography>
                            )}
                          </Stack>
                        ))}
                  </Stack>
                </Scrollbar>
              </Stack>
            </TabPanel>
            <TabPanel value="2">
              <Stack alignItems="flex-start">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  color="secondary.main"
                >
                  {merchantInfo?.name || ''}
                </Typography>
                <Stack direction="row" justifyContent="space-between" width={1} alignItems="center">
                  {merchantInfo?.url && (
                    <Stack direction="row-reverse" gap={2} sx={{ color: 'secondary.main' }}>
                      <Link href={merchantInfo?.url} target="_blank">
                        <Typography sx={{ textDecoration: 'underline' }}>
                          לאתר בית העסק לחץ כאן
                        </Typography>
                      </Link>
                    </Stack>
                  )}
                  {merchantInfo?.mainImageURL && (
                    <Image
                      src={
                        merchantInfo?.mainImageURL
                          ? `${BASIC_URL_FOR_IMAGES}${merchantInfo?.mainImageURL}`
                          : ''
                      }
                      sx={{ '& img': { width: '150px', height: '150px' } }}
                    />
                  )}
                </Stack>
                <Typography>
                  <SanitizeHTML html={merchantInfo?.content as string} />
                </Typography>
                {merchantInfo?.asterisks && (
                  <Typography
                    sx={{
                      textAlign: 'start',
                      width: '100%',
                      fontSize: '10px',
                      mt: 1,
                      mb: 2,
                      height: '10px',
                      color: 'red',
                    }}
                  >
                    {merchantInfo?.asterisks && merchantInfo?.asterisks[0] && '*'}
                    {merchantInfo?.asterisks && merchantInfo?.asterisks[0]?.description}
                  </Typography>
                )}
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>
      </BasicModal>
    </Stack>
  );
};

function applyFilter({
  inputData,
  filters,
}: {
  inputData: Ib2bProduct[];
  filters: Ib2bTableFilter;
}) {
  const { name, area, category } = filters;

  if (name) {
    inputData = inputData.filter(
      (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (area.length) {
    inputData = inputData.filter((product) => area.includes(product.area));
  }

  if (category.length) {
    inputData = inputData.filter((product) => category.includes(product.category));
  }

  return inputData;
}

export default SearchPageSection;
