import Typography from '@mui/material/Typography';

import Image from '../../../components/image';

export const TitleRenderInfoPage = () => {
  const retrieveUrl = () => {
    switch (
      process?.env?.NEXT_PUBLIC_PROJECT_NAME &&
      process?.env?.NEXT_PUBLIC_PROJECT_NAME.toLowerCase()
    ) {
      case 'xtra':
        return 'ניתן למשש באתר הטבות https://happygift.co.il/';
      case 'ksharim':
        return 'ניתן למשש באתר הטבות https://xtra.co.il/';
      case 'raayonit':
        return 'ניתן למשש באתר הטבות https://raayonit.co.il/';
      case 'bsmart':
        return 'ניתן למשש באתר הטבות https://b-smart.co.il/';
      case 'boombuy':
        return 'ניתן למשש באתר הטבות https://boombuy.co.il/';
      case 'citypeople':
        return 'ניתן למשש באתר הטבות https://citypeople.co.il/';
      case 'hashavshevet':
        return 'ניתן למשש באתר הטבות https://hashavshevet.co.il/';
      default:
        return '';
    }
  };

  const link = retrieveUrl();
  return (
    <>
      <Typography
        sx={{
          fontSize: '25px',
          fontWeight: 1000,
          color: 'primary.main',
          mt: 4,
          textAlign: 'center',
        }}
      >
        {link}
      </Typography>
      <Image src="/assets/images/happy-gift/Vector-4.png" width="60%" mb={3} />
    </>
  );
};
