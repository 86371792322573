import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Iconify from '../../../../../../components/iconify';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { useResponsive } from '../../../../../../hooks/use-responsive';

export const FamilyTab = ({ block }: ComponentTypeProps) => {
  const isMobile = useResponsive('down', 'sm');
  return (
    <Stack gap={2}>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '& .MuiPaper-elevation': { boxShadow: '0 0 0 0 rgba(145, 158, 171, 0.16)' },
        }}
        gap={1}
      >
        <Grid
          md={5.9}
          xs={12}
          sx={{
            borderRadius: '5px',
            border: '1px solid grey',
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ margin: '10px 0px 0px 0px' }}
            >
              <Stack>
                <Box
                  sx={{
                    background: '#c3edff',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>דא</Typography>
                </Box>
                <Typography sx={{ fontWeight: 600 }}>דנה אברמוב</Typography>
                <Stack direction="row" gap={1}>
                  <Typography>200589657</Typography>
                  <li style={{ width: '5px' }} />
                  <Typography>ילדה</Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
              <Divider sx={{ my: 2, color: '#c3cfe7' }} />
              <Stack gap={2}>
                <Stack direction="row" width={1} gap={2}>
                  <Stack width={0.5}>
                    <Typography sx={{ fontWeight: 600 }}>תאריך לידה</Typography>
                    <Typography>10/03/1990</Typography>
                  </Stack>
                  <Stack sx={{ borderRight: '1px solid #c3cfe7' }} />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>מין</Typography>
                    <Typography>נקבה</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" width={1} gap={2}>
                  <Stack width={0.5}>
                    <Typography sx={{ fontWeight: 600 }}>מוסד לימוד</Typography>
                    <Typography>בית ספר</Typography>
                  </Stack>
                  <Stack sx={{ borderRight: '1px solid #c3cfe7' }} />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>כיתה</Typography>
                    <Typography>ד2</Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography>עפ”י רישומינו אתה ודנה מתגוררים</Typography>
                  <Typography>באותה הכותבת</Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          md={5.9}
          xs={12}
          sx={{
            borderRadius: '5px',
            border: '1px solid grey',
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ margin: '10px 0px 0px 0px' }}
            >
              <Stack>
                <Box
                  sx={{
                    background: '#c3edff',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>דא</Typography>
                </Box>
                <Typography sx={{ fontWeight: 600 }}>דנה אברמוב</Typography>
                <Stack direction="row" gap={1}>
                  <Typography>352029614</Typography>
                  <li style={{ width: '5px' }} />
                  <Typography>ילדה</Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
              <Divider sx={{ my: 2, color: '#c3cfe7' }} />
              <Stack gap={2}>
                <Stack direction="row" width={1} gap={2}>
                  <Stack width={0.5}>
                    <Typography sx={{ fontWeight: 600 }}>תאריך לידה</Typography>
                    <Typography>08/06/1995</Typography>
                  </Stack>
                  <Stack sx={{ borderRight: '1px solid #c3cfe7' }} />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>מין</Typography>
                    <Typography>נקבה</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" width={1} gap={2}>
                  <Stack width={0.5}>
                    <Typography sx={{ fontWeight: 600 }}>מוסד לימוד</Typography>
                    <Typography>בית ספר</Typography>
                  </Stack>
                  <Stack sx={{ borderRight: '1px solid #c3cfe7' }} />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>כיתה</Typography>
                    <Typography>ד1</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          md={5.9}
          xs={12}
          sx={{
            borderRadius: '5px',
            border: '1px solid grey',
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ margin: '10px 0px 0px 0px' }}
            >
              <Stack>
                <Box
                  sx={{
                    background: '#c3edff',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>דא</Typography>
                </Box>
                <Typography sx={{ fontWeight: 600 }}>דנה אברמוב</Typography>
                <Stack direction="row" gap={1}>
                  <Typography>200595617</Typography>
                  <li style={{ width: '5px' }} />
                  <Typography>ילד</Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
              <Divider sx={{ my: 2, color: '#c3cfe7' }} />
              <Stack gap={2}>
                <Stack direction="row" width={1} gap={2}>
                  <Stack width={0.5}>
                    <Typography sx={{ fontWeight: 600 }}>תאריך לידה</Typography>
                    <Typography>05/07/2001</Typography>
                  </Stack>
                  <Stack sx={{ borderRight: '1px solid #c3cfe7' }} />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>מין</Typography>
                    <Typography>זכר</Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography>עפ”י רישומינו אתה וארי מתגוררים באותה</Typography>
                  <Typography>הכותבת</Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: 600 }}>הורים</Typography>
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} gap={1}>
        <Grid
          md={5.9}
          xs={12}
          sx={{
            borderRadius: '5px',
            border: '1px solid grey',
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ margin: '10px 0px 0px 0px' }}
            >
              <Stack>
                <Box
                  sx={{
                    background: '#c3edff',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>יא</Typography>
                </Box>
                <Typography sx={{ fontWeight: 600 }}>יפה אברמוב</Typography>
                <Stack direction="row" gap={1}>
                  <Typography>002365985</Typography>
                  <li style={{ width: '5px' }} />
                  <Typography>הורה</Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
              <Divider sx={{ my: 2, color: '#c3cfe7' }} />
              <Stack gap={2}>
                <Stack direction="row" width={1} gap={2}>
                  <Stack width={0.5}>
                    <Typography sx={{ fontWeight: 600 }}>תאריך לידה</Typography>
                    <Typography>05/10/1978</Typography>
                  </Stack>
                  <Stack sx={{ borderRight: '1px solid #c3cfe7' }} />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>מין</Typography>
                    <Typography>נקבה</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          md={5.9}
          xs={12}
          sx={{
            borderRadius: '5px',
            border: '1px solid grey',
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ margin: '10px 0px 0px 0px' }}
            >
              <Stack>
                <Box
                  sx={{
                    background: '#c3edff',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>שא</Typography>
                </Box>
                <Typography sx={{ fontWeight: 600 }}>שולי אברמוב</Typography>
                <Stack direction="row" gap={1}>
                  <Typography>0125874965</Typography>
                  <li style={{ width: '5px' }} />
                  <Typography>הורה</Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
              <Divider sx={{ my: 2, color: '#c3cfe7' }} />
              <Stack gap={2}>
                <Stack direction="row" width={1} gap={2}>
                  <Stack width={0.5}>
                    <Typography sx={{ fontWeight: 600 }}>תאריך לידה</Typography>
                    <Typography>08/01/1972</Typography>
                  </Stack>
                  <Stack sx={{ borderRight: '1px solid #c3cfe7' }} />
                  <Stack>
                    <Typography sx={{ fontWeight: 600 }}>מין</Typography>
                    <Typography>זכר</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: 600 }}>בעלי חיים</Typography>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>מוקי</Typography>
            <Stack direction="row" gap={1}>
              <Typography>584625138</Typography>
              <li style={{ width: '5px' }} />
              <Typography>בעל חיים</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography sx={{ fontWeight: 600 }}>תאריך אימוץ</Typography>
              <Typography>08/10/2005</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>מין</Typography>
              <Typography>נקבה</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={
                !isMobile
                  ? {
                      borderLeft: '0.5px solid #c3cfe7',
                    }
                  : {}
              }
            >
              <Typography sx={{ fontWeight: 600 }}>צבע</Typography>
              <Typography>לבן</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>גזע</Typography>
              <Typography>לברדור רטריבר</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography sx={{ fontWeight: 600 }}>מרפאה מטפלת</Typography>
              <Typography>מרפאת ד”ר גלית</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>כתובת</Typography>
              <Typography>הרצל 31, רמת גן</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={
                !isMobile
                  ? {
                      borderLeft: '0.5px solid #c3cfe7',
                    }
                  : {}
              }
            >
              <Typography sx={{ fontWeight: 600 }}>דוא``ל</Typography>
              <Typography sx={{ wordBreak: 'break-word' }}>example@gmail.com</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>טלפון</Typography>
              <Typography>03-1234456</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack>
            <Stack direction="row" gap={1}>
              <Typography sx={{ fontWeight: 600 }}>לוקאס</Typography>
              <Iconify icon="zondicons:information-solid" sx={{ color: '#910537' }} />
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography>102798562</Typography>
              <li style={{ width: '5px' }} />
              <Typography>בעל חיים</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography sx={{ fontWeight: 600 }}>תאריך אימוץ</Typography>
              <Typography>01/01/2007</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>מין</Typography>
              <Typography>זכר</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={
                !isMobile
                  ? {
                      borderLeft: '0.5px solid #c3cfe7',
                    }
                  : {}
              }
            >
              <Typography sx={{ fontWeight: 600 }}>צבע</Typography>
              <Typography>חום</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>גזע</Typography>
              <Typography>מסטיף ספרדי</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography sx={{ fontWeight: 600 }}>מרפאה מטפלת</Typography>
              <Typography>מרפאת ד”ר גלית</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>כתובת</Typography>
              <Typography>הרצל 31, רמת גן</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>דוא``ל</Typography>
              <Typography sx={{ wordBreak: 'break-word' }}>example@gmail.com</Typography>
            </Grid>
            <Grid
              item
              md={2.9}
              xs={5.9}
              px={2}
              sx={{
                borderLeft: '0.5px solid #c3cfe7',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>טלפון</Typography>
              <Typography>03-1234456</Typography>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              border: '2px solid #910537',
              borderRadius: '5px',
              padding: '10px',
              bgcolor: '#9105371c',
            }}
          >
            <Stack direction="row" gap={1}>
              <Iconify icon="zondicons:information-solid" sx={{ color: '#910537' }} />
              <Typography>
                תוקף החיסון של לוקאס עומד להסתיים. לחידוש החיסון יש לעמוד במכסת ההשתלמויות ולשלם את
                האגרה
              </Typography>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
