import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import React, { useState } from 'react';
import Iconify from '../../../../../../components/iconify';
import { ComponentTypeProps } from '../../../../../../types/page-generator';

export const FormTab = ({ block }: ComponentTypeProps) => {
  const [expandedFirst, setExpandedFirst] = useState(false);
  const [expandedSecond, setExpandedSecond] = useState(false);
  const [expandedThird, setExpandedThird] = useState(false);

  const handleChangeFirst = () => {
    setExpandedFirst(!expandedFirst);
    setExpandedSecond(false);
    setExpandedThird(false);
  };
  const handleChangeSecond = () => {
    setExpandedFirst(false);
    setExpandedSecond(!expandedSecond);
    setExpandedThird(false);
  };
  const handleChangeThird = () => {
    setExpandedFirst(false);
    setExpandedSecond(false);
    setExpandedThird(!expandedThird);
  };

  const formsToDownload = [
    'הנחה בארנונה',
    'הנחה בארנונה',
    'הנחה בארנונה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
  ];
  const onlineForms = [
    'הנחה בארנונה',
    'הנחה בארנונה',
    'הנחה בארנונה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
  ];
  const discounts = [
    'הנחה בארנונה',
    'הנחה בארנונה',
    'הנחה בארנונה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
  ];
  const handouts = [
    'הנחה בארנונה',
    'הנחה בארנונה',
    'הנחה בארנונה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
  ];
  const relevantLinks = [
    'הנחה בארנונה',
    'הנחה בארנונה',
    'הנחה בארנונה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'עדכון סיסמה',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'אתר רשות עירוני',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'חילוף מחזיקים בנכס',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
    'זימון תור',
  ];

  return (
    <Stack
      gap={2}
      // sx={{
      //   '& .MuiButtonBase-root': { padding: 0, margin: 0 },
      //   '& .MuiAccordionSummary-content': { padding: 0, margin: 0 },
      // }}
    >
      <Accordion
        sx={{ border: '1px solid grey', borderRadius: '5px' }}
        onChange={handleChangeFirst}
        expanded={expandedFirst}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
          expandIcon={<></>}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Box
              sx={{
                // bgcolor: '#c3edff',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <>
                {expandedFirst ? (
                  <Iconify icon="ph:minus-light" width={30} />
                ) : (
                  <Iconify icon="ph:plus-light" width={30} />
                )}
              </>
            </Box>
            <Stack width={1}>
              <Typography sx={{ fontWeight: 600 }}>טפסים קשורים</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Typography sx={{ fontWeight: 600 }}>טפסים להורדה</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {formsToDownload.map((item: string, id) => (
              <Grid item md={3.9} xs={5.9} key={id} direction="row" gap={1} alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Iconify icon="iconoir:page" width={10} color="#282894" />
                  <Typography>{item}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Typography sx={{ fontWeight: 600, mt: 2 }}>טפסים מקוונים</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {onlineForms.map((item: string, id) => (
              <Grid item md={3.9} xs={5.9} key={id} direction="row" gap={1} alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Iconify icon="iconoir:page" width={10} color="#282894" />
                  <Typography>{item}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Typography sx={{ fontWeight: 600, mt: 2 }}>הנחות</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {discounts.map((item: string, id) => (
              <Grid item md={3.9} xs={5.9} key={id} direction="row" gap={1} alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Iconify icon="iconoir:page" width={10} color="#282894" />
                  <Typography>{item}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ border: '1px solid grey', borderRadius: '5px' }}
        onChange={handleChangeSecond}
        expanded={expandedSecond}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
          expandIcon={<></>}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Box
              sx={{
                // bgcolor: '#c3edff',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <>
                {expandedSecond ? (
                  <Iconify icon="ph:minus-light" width={30} />
                ) : (
                  <Iconify icon="ph:plus-light" width={30} />
                )}
              </>
            </Box>
            <Stack width={1}>
              <Typography sx={{ fontWeight: 600 }}>דפי מידע</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Typography sx={{ fontWeight: 600, mt: 2 }}>דפי מידע</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {handouts.map((item: string, id) => (
              <Grid item md={3.9} xs={5.9} key={id} direction="row" gap={1} alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Iconify icon="iconoir:page" width={10} color="#282894" />
                  <Typography>{item}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ border: '1px solid grey', borderRadius: '5px' }}
        onChange={handleChangeThird}
        expanded={expandedThird}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
          expandIcon={<></>}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Box
              sx={{
                // bgcolor: '#c3edff',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <>
                {expandedThird ? (
                  <Iconify icon="ph:minus-light" width={30} />
                ) : (
                  <Iconify icon="ph:plus-light" width={30} />
                )}
              </>
            </Box>
            <Stack width={1}>
              <Typography sx={{ fontWeight: 600 }}>קישורים רלוונטים</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Typography sx={{ fontWeight: 600, mt: 2 }}>קישורים רלוונטים</Typography>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {handouts.map((item: string, id) => (
              <Grid item md={3.9} xs={5.9} key={id} direction="row" gap={1} alignItems="center">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Iconify icon="iconoir:page" width={10} color="#282894" />
                  <Typography>{item}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
