import React from 'react';

import { Box } from '@mui/material';

import { ComponentTypeProps } from 'src/types/page-generator';
import { useAppContext } from 'src/contexts/AppContext';

export const DocumentView = ({ block }: ComponentTypeProps) => {
  const { state } = useAppContext();

  return (
    <Box
      maxWidth="460px"
      sx={{
        width: '100%',
        maxHeight: '65vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        m: '20px auto',
        p: 2,
        borderRadius: 2.5,
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backgroundImage:
          'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      {state.orderImages.map((imageUrl: string) => (
        <Box
          component="img"
          key={imageUrl.split('/')[-1]}
          src={imageUrl}
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            borderRadius: 0.5,
            mb: '10px',
          }}
        />
      ))}
    </Box>
  );
};
