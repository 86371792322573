import dayjs from 'dayjs';
import JsPDF from 'jspdf';
import * as Yup from 'yup';
import * as React from 'react';
import html2canvas from 'html2canvas';
import SignatureCanvas from 'react-signature-canvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputBase, { InputBaseProps, inputBaseClasses } from '@mui/material/InputBase';
import {
  Box,
  Stack,
  Table,
  Button,
  TableRow,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  Autocomplete,
  TableContainer,
} from '@mui/material';

import uuidv4 from '../../../../utils/uuidv4';
import Image from '../../../../components/image';
import { useTranslate } from '../../../../locales';
import { Ib2bProduct } from '../../../../types/b2b';
import Iconify from '../../../../components/iconify';
import { useGetProducts } from '../../../../api/shop';
import { UploadBox } from '../../../../components/upload';
import { useResponsive } from '../../../../hooks/use-responsive';
import { extractPrices } from '../../../../helpers/extractPrices';
import { ComponentTypeProps } from '../../../../types/page-generator';
import FormProvider, { RHFTextField } from '../../../../components/hook-form';

type UploadFile = File & { preview: any };

type block = {
  id: string;
  type: string;
  productId?: string;
  price?: string | number;
  count?: string | number;
  name?: string;
  data?: string;
  text?: string;
};

export type DraggableListItemType = {
  id: string;
  type: string;
  dateToFinish?: Date | string;
  assigned?: string;
  status?: string;
  blocks: block[];
  images?: File[];
  text?: string;
  name?: string;
  whose?: string;
};

export type FreeTextType = {
  id: string;
  text: string;
};

interface Item {
  id: number;
  images: UploadFile[];
  isPreview: boolean;
}

export default function ReportsBlock({ block }: ComponentTypeProps) {
  const divToPrintRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslate();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      columns: [{ name: '' }],
      rows: [{ cells: [''] }],
    },
  });
  const [loading, setLoading] = useState(false);

  const [rightFooter, setRightFooter] = useState('');
  const [headerText, setHeaderText] = useState('');
  const [headerImage, setImage] = useState<any>('');

  const [leftFooter, setLeftFooter] = useState('');
  const isMobile = useResponsive('down', 'sm');
  const [freeText, setFreeText] = useState<FreeTextType[]>([]);
  const [localData, setLocalData] = useState<any>([]);
  const [listItems, setListItems] = useState<DraggableListItemType[]>([
    {
      id: 'to',
      text: '',
      blocks: [],
      type: 'to',
    },
    {
      id: 'assigned',
      text: '',
      blocks: [],
      type: 'assigned',
    },
    {
      id: '1',
      type: 'table',
      blocks: [],
      images: [],
    },
    {
      id: '2',
      type: 'assign',
      dateToFinish: '',
      assigned: '',
      status: '',
      blocks: [],
    },
  ]);

  const { products } = useGetProducts({
    limit: 9999,
    offset: 0,
  });

  const NewSchema = Yup.object();

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data: any) => {
    const rowsWithImages = rows.map((row, index) => {
      const item = items[index];
      return {
        ...row,
        images: item.images,
      };
    });
    data.rows = rowsWithImages;

    if (signatureData && signatureData.length > 0) {
      const formData = new FormData();

      signatureData.forEach((item, index) => {
        const base64Data = item.signature.replace(/^data:image\/png;base64,/, '');
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        formData.append('whose', item.whose);
        formData.append('name', item.name);
        formData.append(
          `signature_${index + 1}`,
          new Blob([byteArray], { type: 'image/png' }),
          `signature_${index + 1}.png`
        );
      });
      data.signatures = formData;
    }
    setLocalData(data);
  };

  const savePdf = async () => {
    setLoading(true);
    const element = divToPrintRef.current;
    const footerElement = footerRef.current;
    const headerElement = headerRef.current;

    if (element && footerElement && headerElement) {
      const pdf = new JsPDF('p', 'mm', 'a4');
      const pdfWidth = 210;
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Обработка футера
      const footerCanvas = await html2canvas(footerElement);
      const footerImgData = footerCanvas.toDataURL('image/png');
      const footerImgProps = pdf.getImageProperties(footerImgData);
      const footerImgHeight = (footerImgProps.height * pdfWidth) / footerImgProps.width;
      // Обработка хедера
      const headerCanvas = await html2canvas(headerElement);
      const headerImgData = headerCanvas.toDataURL('image/png');
      const headerImgProps = pdf.getImageProperties(headerImgData);
      const headerImgHeight = (headerImgProps.height * pdfWidth) / headerImgProps.width;
      const contentHeight = pdfHeight - footerImgHeight - headerImgHeight;

      const elements = Array.from(element.children) as HTMLElement[];
      let totalHeight = 0;
      let position = headerImgHeight; // Начальная позиция после хедера
      let pageCount = 1;

      pdf.addImage(headerImgData, 'PNG', 0, 0, pdfWidth, headerImgHeight);
      const addElementToPdf = async (elem: HTMLElement, addNewPage = false) => {
        const childCanvas = await html2canvas(elem);
        const childImgData = childCanvas.toDataURL('image/png');
        const childImgProps = pdf.getImageProperties(childImgData);
        const childImgHeight = (childImgProps.height * pdfWidth) / childImgProps.width;

        if (addNewPage) {
          pdf.addPage();
          pdf.addImage(headerImgData, 'PNG', 0, 0, pdfWidth, headerImgHeight);
          position = headerImgHeight;
          pageCount += 1;
        }
        if (position + childImgHeight > contentHeight) {
          pdf.addImage(
            footerImgData,
            'PNG',
            0,
            pdfHeight - footerImgHeight,
            pdfWidth,
            footerImgHeight
          );
          pdf.text(`${pageCount} /`, pdfWidth / 2, pdfHeight - 5, { align: 'center' });
          pdf.addPage();
          pdf.addImage(headerImgData, 'PNG', 0, 0, pdfWidth, headerImgHeight);
          position = headerImgHeight;
          pageCount += 1;
        }

        pdf.addImage(childImgData, 'PNG', 0, position, pdfWidth, childImgHeight);
        position += childImgHeight;

        return childImgHeight;
      };

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < elements.length; i++) {
        const elem = elements[i];
        // eslint-disable-next-line no-await-in-loop
        const childCanvas = await html2canvas(elem);
        const childImgProps = pdf.getImageProperties(childCanvas.toDataURL('image/png'));
        const childImgHeight = (childImgProps.height * pdfWidth) / childImgProps.width;

        if (totalHeight + childImgHeight > contentHeight) {
          if (totalHeight === 0) {
            // eslint-disable-next-line no-await-in-loop
            await addElementToPdf(elem, true);
          } else {
            pdf.addImage(
              footerImgData,
              'PNG',
              0,
              pdfHeight - footerImgHeight,
              pdfWidth,
              footerImgHeight
            );
            pdf.text(`${pageCount} /`, pdfWidth / 2, pdfHeight - 5, { align: 'center' });
            // eslint-disable-next-line no-await-in-loop
            await addElementToPdf(elem, true);
          }
          totalHeight = childImgHeight;
        } else {
          totalHeight += childImgHeight;
          // eslint-disable-next-line no-await-in-loop
          await addElementToPdf(elem);
        }
      }

      pdf.addImage(footerImgData, 'PNG', 0, pdfHeight - footerImgHeight, pdfWidth, footerImgHeight);
      pdf.text(`${pageCount} /`, pdfWidth / 2, pdfHeight - 5, { align: 'center' });
      // @ts-ignore
      const totalPages = pdf.internal.getNumberOfPages();
      // eslint-disable-next-line no-plusplus
      for (let j = 1; j <= totalPages; j++) {
        pdf.setPage(j);
        pdf.text(`${totalPages}`, pdfWidth / 2 + 5, pdfHeight - 5, { align: 'center' });
      }
      setLoading(false);
      pdf.save(themeName ? `${themeName}.pdf` : 'Example.pdf');
    }
  };

  const addProductFieldBlock = (id: string) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              blocks: [...item.blocks, { id: String(item.blocks.length + 1), type: 'text' }],
            }
          : item
      )
    );
  };

  const deleteProductFieldBlock = (blockId: string, productId: string) => {
    setListItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === blockId) {
          return {
            ...item,
            blocks: item.blocks.filter((blockField) => blockField.id !== productId),
          };
        }
        return item;
      })
    );
  };

  const handleToChange = (value: string) => {
    setListItems((prevListItems) =>
      prevListItems.map((item) => {
        if (item.id === 'to') {
          return {
            ...item,
            text: value,
          };
        }
        return item;
      })
    );
  };

  const handleAssignChange = (value: string) => {
    setListItems((prevListItems) =>
      prevListItems.map((item) => {
        if (item.id === 'assigned') {
          return {
            ...item,
            text: value,
          };
        }
        return item;
      })
    );
  };

  const handleFreeTextChange = (id: string, text: string) => {
    setFreeText((prevItems) => {
      const existingItemIndex = prevItems.findIndex((item) => item.id === id);

      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex] = { ...updatedItems[existingItemIndex], text };
        return updatedItems;
      }
      return [...prevItems, { id: uuidv4(), text }];
    });
  };

  const addFreeTextBlock = () => {
    setFreeText((prevItems) => [...prevItems, { id: uuidv4(), text: '' }]);
  };

  const removeFreeTextBlock = (itemId: string) => {
    setFreeText((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const removeAssignBlock = (id: string) => {
    setListItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const removeSignatureBlock = (id: string) => {
    setListItems((prevItems) => prevItems.filter((item) => item.id !== id));
    setSignatureData((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const removeProductBlock = (id: string) => {
    setListItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const addAssignBlock = () => {
    setListItems((prevItems) => [
      ...prevItems,
      {
        id: uuidv4(),
        type: 'assign',
        blocks: [],
      },
    ]);
    handleClose();
  };

  const addSignatureBlock = () => {
    setListItems((prevItems) => [
      ...prevItems,
      {
        id: uuidv4(),
        type: 'signature',
        blocks: [],
      },
    ]);
    handleClose();
  };

  const addProductBlock = () => {
    setListItems((prevItems) => [
      ...prevItems,
      {
        id: uuidv4(),
        type: 'product',
        blocks: [{ id: '1', type: 'text', productId: '', price: 0 }],
      },
    ]);
    handleClose();
  };

  const onChangeSelect = (
    event: React.ChangeEvent<{}>,
    value: Ib2bProduct | null,
    blockId: string,
    productId: string
  ) => {
    if (value) {
      setListItems((prevItems) =>
        prevItems.map((item) =>
          item.id === blockId
            ? {
                ...item,
                blocks: item.blocks.map((i) =>
                  i.id === productId
                    ? {
                        ...i,
                        price: extractPrices(value.prices).price,
                        name: value.title,
                      }
                    : i
                ),
              }
            : item
        )
      );
    }
  };

  const handleChangeCount = (
    blockId: string,
    productId: string,
    field: keyof block,
    value: string | number | undefined
  ) => {
    setListItems((prevItems: DraggableListItemType[]) =>
      prevItems.map((item) =>
        item.id === blockId
          ? {
              ...item,
              blocks: item.blocks.map((it) =>
                it.id === productId ? { ...it, [field]: value || '' } : it
              ),
            }
          : item
      )
    );
  };

  const handleChangeText = (
    blockId: string,
    productId: string,
    field: keyof block,
    value: string
  ) => {
    setListItems((prevItems: DraggableListItemType[]) =>
      prevItems.map((item) =>
        item.id === blockId
          ? {
              ...item,
              blocks: item.blocks.map((it) =>
                it.id === productId ? { ...it, [field]: value } : it
              ),
            }
          : item
      )
    );
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tableExists = listItems.some((item) => item.type === 'table');

  const addTableBlock = () => {
    if (!tableExists) {
      setListItems((prevItems) => [...prevItems, { id: uuidv4(), type: 'table', blocks: [] }]);
    }
    handleClose();
  };

  const [themeName, setThemeName] = useState('');

  const handleChangeThemeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setThemeName(event.target.value);
  }, []);

  const getFileWithPreview = (fil: File): UploadFile =>
    Object.assign(fil, { preview: URL.createObjectURL(fil) });

  const [items, setItems] = useState<Item[]>([{ id: 0, images: [], isPreview: false }]);

  const handleLoad = async (files: File[], rowIndex: number) => {
    const [firstFile] = files;
    if (firstFile) {
      const modFirstFile = await getFileWithPreview(firstFile);

      setItems((prevItems) =>
        prevItems.map((item, index) =>
          index === rowIndex
            ? {
                ...item,
                images: item.images ? [...item.images, modFirstFile] : [modFirstFile],
                isPreview: true,
              }
            : item
        )
      );
    }
  };

  const handleLoadLogo = async (files: File[]) => {
    const [firstFile] = files;
    if (firstFile) {
      const modFirstFile = await getFileWithPreview(firstFile);
      setImage(modFirstFile);
    }
  };

  const handleRemoveImage = (rowIndex: number, imageIndex: number) => {
    setItems((prevItems) =>
      prevItems.map((item, index) =>
        index === rowIndex
          ? { ...item, images: item.images.filter((_, i) => i !== imageIndex) }
          : item
      )
    );
  };

  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [signatureData, setSignatureData] = useState<
    {
      id: string;
      signature: string;
      whose: string;
      name: string;
    }[]
  >([]);
  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleMouseLeave = (item: DraggableListItemType) => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const signature = sigCanvas.current.toDataURL();
      setSignatureData((prevItems) => {
        const foundIndex = prevItems.findIndex((i) => i.id === item.id);
        if (foundIndex !== -1) {
          const updatedItem = {
            ...prevItems[foundIndex],
            signature,
            name: prevItems[foundIndex]?.name || '',
            whose: prevItems[foundIndex]?.whose || 'Client',
          };
          const updatedItems = [
            ...prevItems.slice(0, foundIndex),
            updatedItem,
            ...prevItems.slice(foundIndex + 1),
          ];
          return updatedItems;
        }
        return [
          ...prevItems,
          {
            id: item.id,
            signature,
            name: item.name || '',
            whose: item.whose || 'Client',
          },
        ];
      });
    }
  };

  const handleWhoseSignatureChange = (item: DraggableListItemType, whoseValue: string) => {
    setSignatureData((prevItems) => {
      const foundIndex = prevItems.findIndex((i) => i.id === item.id);
      if (foundIndex !== -1) {
        const updatedItem = {
          ...prevItems[foundIndex],
          whose: whoseValue || 'Client',
        };
        const updatedItems = [
          ...prevItems.slice(0, foundIndex),
          updatedItem,
          ...prevItems.slice(foundIndex + 1),
        ];
        return updatedItems;
      }
      return [
        ...prevItems,
        {
          id: item.id,
          signature: prevItems[foundIndex]?.signature || '',
          name: prevItems[foundIndex]?.name || '',
          whose: whoseValue || 'Client',
        },
      ];
    });
  };

  const handleNameSignatureChange = (item: DraggableListItemType, nameValue: string) => {
    setSignatureData((prevItems) => {
      const foundIndex = prevItems.findIndex((i) => i.id === item.id);
      if (foundIndex !== -1) {
        const updatedItem = {
          ...prevItems[foundIndex],
          name: nameValue,
        };
        const updatedItems = [
          ...prevItems.slice(0, foundIndex),
          updatedItem,
          ...prevItems.slice(foundIndex + 1),
        ];
        return updatedItems;
      }
      return [
        ...prevItems,
        {
          id: item.id,
          signature: prevItems[foundIndex]?.signature || '',
          name: nameValue,
          whose: prevItems[foundIndex]?.whose || 'Client',
        },
      ];
    });
  };

  const {
    fields: columns,
    append: appendColumn,
    remove: removeColumn,
  } = useFieldArray({
    control,
    name: 'columns',
  });

  const {
    fields: rows,
    append: appendRow,
    remove: removeRow,
    update: updateRow,
  } = useFieldArray({
    control,
    name: 'rows',
  });

  const addRow = () => {
    appendRow({ cells: columns.map(() => '') });
  };

  const addTableRow = () => {
    addRow();
    setItems((prevItems) => [
      ...prevItems,
      { id: prevItems.length + 1, images: [], isPreview: false },
    ]);
  };

  const removeTableRow = (rowIndex: number | number[] | undefined) => {
    removeRow(rowIndex);
    setItems((prevItems) => prevItems.filter((_, index) => index !== rowIndex));
  };

  const addColumn = () => {
    appendColumn({ name: '' });
    rows.forEach((row, index) => {
      const updatedCells = [...row.cells, ''];
      updateRow(index, { ...row, cells: updatedCells });
    });
  };

  const removeColumnAndCells = (index: any) => {
    removeColumn(index);
    rows.forEach((row, rowIndex) => {
      const updatedCells = row.cells.filter((_: any, cellIndex: any) => cellIndex !== index);
      updateRow(rowIndex, { ...row, cells: updatedCells });
    });
  };

  const handleChange = (id: string, field: string, value: any) => {
    const itemIndex = listItems.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      setListItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = { ...updatedItems[itemIndex], [field]: value };
        return updatedItems;
      });
    } else {
      setListItems((prevItems) => [
        ...prevItems,
        {
          id,
          [field]: value,
          dateToFinish: '',
          assigned: '',
          status: '',
          type: 'assign',
          blocks: [],
        },
      ]);
    }
  };

  const calculateTotal = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      const price = parseFloat(it.price as string) || 0;
      return sum + count * price;
    }, 0);

  const calculateTotalCount = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      return sum + count;
    }, 0);

  const itemTo = listItems.find((it) => it.id === 'to');
  const itemToText = itemTo ? itemTo.text : '';
  const itemAssign = listItems.find((it) => it.id === 'assigned');
  const itemAssignText = itemAssign ? itemAssign.text : '';

  const assignedItems = listItems.filter((it) => it.type === 'assign');

  const productItems = listItems.filter((it) => it.type === 'product');

  const tableItems = listItems.filter((it) => it.type === 'table');

  const signatureItems = signatureData;

  return (
    <Stack alignItems="center" sx={{ position: 'relative', zIndex: 10 }}>
      <Stack alignItems="center" gap={2} sx={{ mb: 2, pb: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" width={1} gap={1} mt={2} alignItems="center">
            <TextField
              fullWidth
              label="Text"
              name="text"
              onChange={(e) => setHeaderText(e.target.value)}
            />
            <>
              {headerImage ? (
                <Box sx={{ position: 'relative', maxWidth: '20%' }}>
                  <Image src={URL.createObjectURL(headerImage)} />
                  <IconButton
                    size="small"
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={() => setImage('')}
                  >
                    <Iconify icon="simple-line-icons:close" width={20} />
                  </IconButton>
                </Box>
              ) : (
                <UploadBox
                  multiple={false}
                  thumbnail
                  onDrop={(files) => handleLoadLogo(files)}
                  onUpload={() => console.info('ON UPLOAD')}
                />
              )}
            </>
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack alignItems="center">
              <Image src={block?.settings?.uploadLogo} sx={{ width: '100px' }} />
              <Stack
                width={1}
                direction="row"
                sx={{ alignItems: 'start', justifyContent: 'space-between' }}
              >
                <Stack gap={1}>
                  <RHFTextField
                    name="to"
                    sx={{ width: isMobile ? '100%' : '100%' }}
                    label="To"
                    onChange={(e) => handleToChange(e.target.value)}
                  />
                  <RHFTextField
                    name="assign"
                    sx={{ width: isMobile ? '100%' : '100%' }}
                    label="Assign"
                    onChange={(e) => handleAssignChange(e.target.value)}
                  />
                  {freeText.map((it) => (
                    <Stack
                      key={it.id}
                      direction="row"
                      gap={1}
                      sx={{ position: 'relative', width: '100%' }}
                    >
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: '-15px',
                          left: '-13px',
                        }}
                        onClick={() => removeFreeTextBlock(it.id)}
                      >
                        <Iconify icon="mingcute:close-line" width={20} />
                      </IconButton>
                      <RHFTextField
                        name={`freeText_${it.id}`}
                        key={it.id}
                        sx={{ width: isMobile ? '100%' : '100%' }}
                        onChange={(e) => handleFreeTextChange(it.id, e.target.value)}
                      />
                    </Stack>
                  ))}
                  <IconButton sx={{ width: '20%' }}>
                    <Iconify
                      sx={{ color: 'green' }}
                      icon="ic:twotone-plus"
                      onClick={() => addFreeTextBlock()}
                    />
                  </IconButton>
                </Stack>
                <DatePicker
                  name="dateNow"
                  sx={{ width: isMobile ? '30%' : '30%' }}
                  defaultValue={dayjs()}
                  disabled
                />
              </Stack>
              <TicketsInputName
                name="name"
                placeholder={t('Enter the theme')}
                value={themeName}
                onChange={handleChangeThemeName}
                // onKeyUp={handleUpdateTicket}
              />
            </Stack>

            {listItems.map((item) => (
              <Stack key={item.id}>
                {item.type === 'table' && (
                  <Box>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack spacing={2} mb={2} direction="row">
                        <IconButton onClick={addColumn}>
                          <Iconify color="green" icon="material-symbols:view-column" />
                        </IconButton>
                        <IconButton onClick={addTableRow}>
                          <Iconify color="green" icon="fluent:row-triple-24-filled" />
                        </IconButton>
                        <Button
                          sx={{ color: 'red' }}
                          onClick={() => {
                            setListItems(listItems.filter((i) => i.id !== item.id));
                            reset({
                              columns: [],
                              rows: [],
                            });
                          }}
                        >
                          Remove
                        </Button>
                      </Stack>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                      >
                        Table
                      </Typography>
                    </Stack>
                    <Box sx={isMobile ? { overflowX: 'auto', width: '90vw' } : {}}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, colIndex) => (
                                <TableCell
                                  key={colIndex}
                                  sx={{ minWidth: { md: '150px', xs: '50px' } }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{ position: 'relative' }}
                                  >
                                    <Controller
                                      control={control}
                                      // @ts-ignore
                                      name={`columns[${colIndex}].name`}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          label={`Column ${colIndex + 1}`}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      )}
                                    />
                                    <IconButton
                                      size="small"
                                      sx={{ position: 'absolute', top: '-15px', left: '-13px' }}
                                      onClick={() => removeColumnAndCells(colIndex)}
                                    >
                                      <Iconify icon="mingcute:close-line" width={20} />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, rowIndex) => (
                              <TableRow key={rowIndex}>
                                {row.cells.map((cell, cellIndex) => (
                                  <TableCell key={cellIndex} sx={{ minWidth: '150px' }}>
                                    <Controller
                                      control={control}
                                      // @ts-ignore
                                      name={`rows[${rowIndex}].cells[${cellIndex}]`}
                                      render={({ field }) => (
                                        <TextField {...field} variant="outlined" fullWidth />
                                      )}
                                    />
                                  </TableCell>
                                ))}
                                <TableCell sx={{ width: isMobile ? 1 : 0.2, minWidth: '150px' }}>
                                  {items[rowIndex] && items[rowIndex].images.length > 0 ? (
                                    items[rowIndex].images.map((image, imageIndex) => (
                                      <Box key={imageIndex} sx={{ position: 'relative' }}>
                                        <Image src={URL.createObjectURL(image)} width={1} />
                                        <IconButton
                                          size="small"
                                          sx={{ position: 'absolute', top: 0, right: 0 }}
                                          onClick={() => handleRemoveImage(rowIndex, imageIndex)}
                                        >
                                          <Iconify icon="simple-line-icons:close" width={20} />
                                        </IconButton>
                                      </Box>
                                    ))
                                  ) : (
                                    <UploadBox
                                      multiple={false}
                                      thumbnail
                                      onDrop={(files) => handleLoad(files, rowIndex)}
                                      onUpload={() => console.info('ON UPLOAD')}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <IconButton onClick={() => removeTableRow(rowIndex)}>
                                    <Iconify icon="tabler:trash" width={20} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Divider sx={{ mb: 1 }} />
                  </Box>
                )}
                {item.type === 'assign' && (
                  <>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {isMobile ? (
                        <Iconify
                          sx={{ color: 'red' }}
                          icon="tabler:trash"
                          onClick={() => removeAssignBlock(item.id)}
                        />
                      ) : (
                        <Button sx={{ color: 'red' }} onClick={() => removeAssignBlock(item.id)}>
                          Remove
                        </Button>
                      )}
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                      >
                        Assign
                      </Typography>
                    </Stack>
                    <Stack
                      my={1}
                      direction="row-reverse"
                      alignItems="center"
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Stack
                        key={item.id}
                        direction="row-reverse"
                        alignItems="center"
                        gap={1}
                        width={1}
                        mb={2}
                      >
                        <RHFTextField
                          name={`assigned_${item.id}`}
                          label="assign"
                          value={item?.assigned}
                          onChange={(e) => handleChange(item.id, 'assigned', e.target.value)}
                        />
                        <DatePicker
                          sx={{ width: '70%' }}
                          name={`dateToFinish${item.id}`}
                          label="Date to finish"
                          // @ts-ignore
                          value={item?.dateToFinish ? item?.dateToFinish : dayjs()}
                          onChange={(date) => handleChange(item.id, 'dateToFinish', date)}
                        />
                        <RHFTextField
                          name={`status_${item.id}`}
                          select
                          fullWidth
                          defaultValue={item?.status}
                          onChange={(e) => handleChange(item.id, 'status', e.target.value)}
                        >
                          {['New', 'Open', 'In progress', 'Done'].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </RHFTextField>
                      </Stack>
                    </Stack>
                    <Divider sx={{ mb: 1 }} />
                  </>
                )}
                {item.type === 'signature' && (
                  <>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {isMobile ? (
                        <Iconify
                          sx={{ color: 'red' }}
                          icon="tabler:trash"
                          onClick={() => removeSignatureBlock(item.id)}
                        />
                      ) : (
                        <Button sx={{ color: 'red' }} onClick={() => removeSignatureBlock(item.id)}>
                          Remove
                        </Button>
                      )}
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                      >
                        Signature
                      </Typography>
                    </Stack>
                    <Stack width={1}>
                      <Stack
                        my={1}
                        direction="row-reverse"
                        alignItems="center"
                        gap={1}
                        justifyContent="space-between"
                      >
                        <Stack
                          direction="row-reverse"
                          alignItems="center"
                          gap={1}
                          sx={{ position: 'relative' }}
                        >
                          <Box
                            sx={{ border: '1px solid black', position: 'relative' }}
                            onMouseLeave={() => handleMouseLeave(item)}
                          >
                            <IconButton
                              size="small"
                              sx={{
                                position: 'absolute',
                              }}
                              onClick={() => clearSignature()}
                            >
                              <Iconify icon="mingcute:close-line" width={20} />
                            </IconButton>
                            <SignatureCanvas
                              ref={sigCanvas}
                              penColor="black"
                              canvasProps={{
                                width: isMobile ? 300 : 500,
                                height: isMobile ? 200 : 200,
                                className: 'sigCanvas',
                              }}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                      <Stack gap={1}>
                        <RHFTextField
                          name={`whoseSignature${item.id}`}
                          select
                          fullWidth
                          defaultValue="Client"
                          onChange={(event) => handleWhoseSignatureChange(item, event.target.value)}
                        >
                          {['Client', 'Assign'].map((option: string) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{
                                mx: 1,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </RHFTextField>

                        <RHFTextField
                          name={`nameSignature${item.id}`}
                          label="Whose signature"
                          onChange={(event) => handleNameSignatureChange(item, event.target.value)}
                        />
                      </Stack>
                      {isMobile && (
                        <Button
                          variant="contained"
                          color="inherit"
                          onClick={() => handleMouseLeave(item)}
                          sx={{ my: 1 }}
                        >
                          Save
                        </Button>
                      )}
                    </Stack>
                    <Divider sx={{ mb: 1 }} />
                  </>
                )}
                {item.type === 'product' && (
                  <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                      >
                        Product
                      </Typography>
                      <Stack direction="row">
                        {isMobile ? (
                          <Iconify
                            sx={{ color: 'green' }}
                            icon="ic:twotone-plus"
                            onClick={() => addProductFieldBlock(item.id)}
                          />
                        ) : (
                          <Button
                            sx={{ color: 'green' }}
                            onClick={() => addProductFieldBlock(item.id)}
                          >
                            Add
                          </Button>
                        )}
                        {isMobile ? (
                          <Iconify
                            sx={{ color: 'red' }}
                            icon="tabler:trash"
                            onClick={() => removeProductBlock(item.id)}
                          />
                        ) : (
                          <Button sx={{ color: 'red' }} onClick={() => removeProductBlock(item.id)}>
                            Remove
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                    <Stack>
                      {item.blocks.map((i) => (
                        <Stack
                          key={i.id}
                          my={1}
                          direction="row"
                          alignItems="center"
                          gap={1}
                          sx={{ position: 'relative' }}
                        >
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: '-15px',
                              left: '-13px',
                            }}
                            onClick={() => deleteProductFieldBlock(item.id, i.id)}
                          >
                            <Iconify icon="mingcute:close-line" width={20} />
                          </IconButton>
                          <Autocomplete
                            fullWidth
                            options={products.map((option: Ib2bProduct) => ({
                              ...option,
                              key: `${option.id.toString()}-${option.name}`,
                            }))}
                            getOptionLabel={(option: any) => option.title}
                            onChange={(event, value) => onChangeSelect(event, value, item.id, i.id)}
                            renderInput={(params) => (
                              <TextField {...params} label={`${t('Product')}`} size="small" />
                            )}
                            sx={{
                              typography: 'body2',
                              '& .MuiFormLabel-root': { mt: '5px' },
                              '& .MuiInputBase-root': { height: '52px' },
                            }}
                          />
                          <RHFTextField
                            name={`product_${item.id}_${i.id}`}
                            fullWidth
                            label="Add text"
                            onChange={(e) =>
                              handleChangeText(item.id, i.id, 'text', e.target.value)
                            }
                          />
                          <RHFTextField
                            name={`count_${item.id}_${i.id}`}
                            sx={{ width: '50%' }}
                            label="Count"
                            onChange={(e) =>
                              handleChangeCount(item.id, i.id, 'count', e.target.value)
                            }
                          />
                          <Typography width={isMobile ? 0.5 : 0.2}>
                            {i?.price ? `₪${i?.price}` : '₪'}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" width={1}>
                      <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Total:</Typography>
                      <Stack direction="row" gap={4}>
                        <Typography> {calculateTotalCount(item.blocks).toFixed(0)}</Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          {' '}
                          ₪{calculateTotal(item.blocks).toFixed(2)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider sx={{ mb: 1, mt: 2 }} />
                  </>
                )}
              </Stack>
            ))}
          </LocalizationProvider>
          <Box sx={{ position: 'relative', zIndex: 0 }}>
            <Fab
              color="secondary"
              sx={{ width: isMobile ? '50px' : '50px', height: isMobile ? '50px' : '50px' }}
              aria-label="add"
              onClick={handleClick}
            >
              <Iconify sx={{ color: 'white' }} icon="ic:twotone-plus" onClick={handleClick} />
            </Fab>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <IconButton>
                <Iconify
                  sx={{ width: '50px', height: '50px' }}
                  icon="pajamas:assignee"
                  onClick={() => addAssignBlock()}
                />
              </IconButton>
              <IconButton disabled={tableExists}>
                <Iconify
                  sx={{ width: '50px', height: '50px' }}
                  icon="mingcute:text-2-fill"
                  onClick={() => addTableBlock()}
                />
              </IconButton>
              <IconButton>
                <Iconify
                  sx={{ width: '50px', height: '50px' }}
                  icon="ph:signature-bold"
                  onClick={() => addSignatureBlock()}
                />
              </IconButton>
              {block?.settings?.enableProducts && (
                <IconButton>
                  <Iconify
                    sx={{ width: '50px', height: '50px' }}
                    icon="ion:cart"
                    onClick={() => addProductBlock()}
                  />
                </IconButton>
              )}
            </Menu>
          </Box>
          <Stack direction="row" width={1} gap={1} mt={2}>
            <TextField
              fullWidth
              label="leftFooter"
              name="leftFooter"
              onChange={(e) => setLeftFooter(e.target.value)}
            />
            <TextField
              fullWidth
              label="rightFooter"
              name="rightFooter"
              onChange={(e) => setRightFooter(e.target.value)}
            />
          </Stack>
          <Stack gap={1}>
            <LoadingButton
              fullWidth
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                mt: 1,
                borderRadius: 5,
                bgcolor: 'primary.main',
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                '&:hover': {
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                },
              }}
            >
              {`${t('View')}`}
            </LoadingButton>
            {loading ? (
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                variant="contained"
                loading={loading}
                onClick={() => savePdf()}
                sx={{
                  mt: 1,
                  borderRadius: 5,
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'primary.main',
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
                  },
                }}
              >
                Loading...
              </LoadingButton>
            ) : (
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                variant="contained"
                loading={loading}
                onClick={() => savePdf()}
                sx={{
                  mt: 1,
                  borderRadius: 5,
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'primary.main',
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
                  },
                }}
              >
                {`${t('Save PDF')}`}
              </LoadingButton>
            )}
          </Stack>
        </FormProvider>
      </Stack>
      <Stack>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 600, width: 1, textAlign: 'center', my: 2 }}
        >
          Document preview
        </Typography>
        {headerComponent(headerRef, headerImage, headerText)}
        {PdfComponent({
          localData,
          signatureItems,
          assignedItems,
          tableItems,
          productItems,
          itemToText,
          itemAssignText,
          freeText,
          themeName,
          divToPrintRef,
        })}
        {footerComponent(footerRef, rightFooter, leftFooter)}
      </Stack>
    </Stack>
  );
}
type Props = {
  localData: any;
  signatureItems: any;
  assignedItems: any;
  tableItems: any;
  productItems: any;
  itemToText: any;
  itemAssignText: any;
  freeText: any;
  themeName: any;
  divToPrintRef: any;
};

const PdfComponent = ({
  localData,
  signatureItems,
  assignedItems,
  tableItems,
  productItems,
  itemToText,
  itemAssignText,
  freeText,
  themeName,
  divToPrintRef,
}: Props) => {
  const calculateTotal = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      const price = parseFloat(it.price as string) || 0;
      return sum + count * price;
    }, 0);
  const isMobile = useResponsive('down', 'sm');
  const calculateTotalCount = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      return sum + count;
    }, 0);
  return (
    <Stack
      ref={divToPrintRef}
      id="divToPrint"
      mt={4}
      sx={{
        width: '100%',
        height: 'auto',
        alignItems: 'center',
        zIndex: 0,
      }}
    >
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack mx={2}>
          <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }}>
            {itemToText || ''}
          </Typography>
          <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }}>
            {itemAssignText || ''}
          </Typography>
          {freeText?.map((it: any) => (
            <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }} key={it.id}>
              {it.text || ''}
            </Typography>
          ))}
        </Stack>
        <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }} mx={2}>
          {dayjs().format('DD.MM.YYYY') || ''}
        </Typography>
      </Stack>
      <Typography
        sx={{
          textAlign: 'center',
          mt: 3,
          fontSize: { md: '25px', xs: '12px' },
          fontWeight: 600,
          height: '10mm',
          width: '100mm',
        }}
      >
        {themeName || ''}
      </Typography>
      {tableItems && (
        <TableContainer>
          <Table sx={{ border: '1px solid black' }}>
            <TableHead sx={{ bgcolor: 'rgba(128, 128, 128, 0.5)' }}>
              <TableRow sx={{ border: '1px solid black' }}>
                {localData?.columns?.map((column: any, colIndex: any) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      border: '1px solid black!important',
                      width: '20%',
                      fontSize: { md: '25px', xs: '8px' },
                      height: { md: '100%', xs: '10%' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.name || ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {localData?.rows?.map((row: any, rowIndex: any) => (
                <TableRow key={rowIndex}>
                  {row?.cells?.map((cell: any, cellIndex: any) => (
                    <TableCell
                      key={cellIndex}
                      sx={{
                        border: '1px solid black!important',
                        width: '20%',
                        fontSize: { md: '25px', xs: '8px' },
                        height: { md: '100%', xs: '10%' },
                        padding: { md: 2, xs: '0px 16px' },
                      }}
                    >
                      {cell || ''}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '30%' },
                      height: { md: '100%', xs: '10%' },
                      fontSize: { md: '25px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {row?.images?.map(
                      (image: Blob | MediaSource, imageIndex: React.Key | null | undefined) => (
                        <Image
                          key={imageIndex}
                          src={URL.createObjectURL(image) || ''}
                          sx={{ width: { md: '100%', xs: '100%' } }}
                        />
                      )
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {assignedItems && (
        <Stack width={1} direction="row" sx={{ my: { md: 3, xs: 1 } }}>
          {assignedItems?.map((it: any) => (
            <Stack key={it.id} width={1} mx={2} sx={{ gap: { md: 3, xs: 0 } }}>
              <Stack direction="row" mt={2}>
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>Assigned :</Typography>
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  {it?.assigned || ''}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>Status :</Typography>
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  {it?.status || ''}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  Date to finish :
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      md: '25px',
                      xs: '8px',
                    },
                  }}
                >
                  {(it?.dateToFinish && it?.dateToFinish?.format('DD.MM.YYYY HH:mm:ss')) || ''}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
      {productItems && (
        <>
          {productItems?.map((i: any) => (
            <Table style={{ marginTop: isMobile ? '10px' : '40px' }}>
              {i?.blocks?.map((column: any, colIndex: any) => (
                <TableRow key={colIndex}>
                  <TableCell
                    sx={{
                      width: '70%',
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.name || ''}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '10%' },
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.text || ''}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '10%' },
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.count || ''}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '10%' },
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {`₪${column.price || ''}`}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={{ width: { md: '20%', xs: '10%' }, padding: { md: 2, xs: '0px 16px' } }}
                >
                  <Typography sx={{ fontSize: { md: '18px', xs: '8px' }, fontWeight: 600 }}>
                    Totlal :
                  </Typography>
                </TableCell>
                <TableCell />
                <TableCell
                  sx={{ width: { md: '20%', xs: '10%' }, padding: { md: 2, xs: '0px 16px' } }}
                >
                  <Typography sx={{ fontSize: { md: '18px', xs: '8px' }, fontWeight: 600 }}>
                    {calculateTotalCount(productItems[0]?.blocks) || ''}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ width: { md: '20%', xs: '10%' }, padding: { md: 2, xs: '0px 16px' } }}
                >
                  <Typography sx={{ fontSize: { md: '18px', xs: '8px' }, fontWeight: 600 }}>
                    {`₪${calculateTotal(productItems[0]?.blocks) || ''}`}
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
          ))}
        </>
      )}
      {signatureItems.length > 0 && (
        <Stack direction="row" width={1}>
          {signatureItems?.map((it: any) => (
            <Stack key={it.id} width={1} alignItems="center">
              {it.signature && (
                <Image src={it.signature || ''} sx={{ width: { md: '50%', xs: '25%' } }} />
              )}
              <Typography
                sx={{
                  fontSize: {
                    md: '25px',
                    xs: '8px',
                  },
                }}
              >
                {it.name || ''}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const footerComponent = (footerRef: any, rightFooter: string, leftFooter: string) => (
  <Box ref={footerRef} width={1} sx={{ zIndex: 0, height: { md: '15mm', xs: '5mm' } }}>
    <Divider />
    <Stack direction="row" width={1} height={1} justifyContent="space-between">
      <Typography mx={2} sx={{ fontSize: { md: '25px', xs: '8px' } }}>
        {rightFooter || ''}
      </Typography>
      <Typography mx={2} sx={{ fontSize: { md: '25px', xs: '8px' } }}>
        {leftFooter || ''}
      </Typography>
    </Stack>
  </Box>
);

const headerComponent = (headerRef: any, image: string | any, headerText: string) => (
  <Box
    ref={headerRef}
    width={1}
    sx={{
      height: {
        md: '15mm',
        xs: '7mm',
        borderBottom: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
      },
    }}
  >
    <Stack direction="row" width={1} justifyContent="space-between" alignItems="center">
      {headerText && (
        <Typography mx={2} sx={{ fontSize: { md: '20px', xs: '8px' } }}>
          {headerText || ''}
        </Typography>
      )}
      {image && (
        <Image
          src={image ? URL?.createObjectURL(image) : ''}
          sx={{ height: '10mm', img: { height: { md: '10mm', xs: '5mm' } } }}
        />
      )}
    </Stack>
  </Box>
);

function TicketsInputName({ sx, ...other }: InputBaseProps) {
  return (
    <InputBase
      sx={{
        [`&.${inputBaseClasses.root}`]: {
          py: 0.75,
          borderRadius: 1,
          typography: 'h6',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'transparent',
          transition: (theme) => theme.transitions.create(['padding-left', 'border-color']),
          [`&.${inputBaseClasses.focused}`]: {
            pl: 1.5,
            borderColor: 'text.primary',
          },
        },
        [`& .${inputBaseClasses.input}`]: {
          typography: 'h6',
        },
        ...sx,
      }}
      {...other}
    />
  );
}
