import { useEffect } from 'react';
import { useParams } from 'next/navigation';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { bgGradient } from 'src/theme/css';

import Image from 'src/components/image';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import Carousel, { useCarousel, CarouselArrowIndex } from 'src/components/carousel';

import { IProductItem } from 'src/types/product';

import { Ib2bProduct } from '../../types/b2b';
import { useRouter } from '../../routes/hooks';
import { IMerchant } from '../../types/merchant';
import { useAuthContext } from '../../auth/hooks';
import { BlockType } from '../../types/page-generator';
import { ActionTypes, useAppContext } from '../../contexts/AppContext';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';
import { PROJECT_NAME } from '../../config-global';

// ----------------------------------------------------------------------

const THUMB_SIZE = 64;

const StyledThumbnailsContainer = styled('div')<{ length: number }>(({ length, theme }) => ({
  position: 'relative',
  margin: theme.spacing(0, 'auto'),
  '& .slick-slide': {
    lineHeight: 0,
  },

  ...(length === 1 && {
    maxWidth: THUMB_SIZE * 1 + 16,
  }),

  ...(length === 2 && {
    maxWidth: THUMB_SIZE * 2 + 32,
  }),

  ...((length === 3 || length === 4) && {
    maxWidth: THUMB_SIZE * 3 + 48,
  }),

  ...(length >= 5 && {
    maxWidth: THUMB_SIZE * 6,
  }),

  ...(length > 3 && {
    '&:before, &:after': {
      ...bgGradient({
        direction: 'to left',
        startColor: `${alpha(theme.palette.background.default, 0)} 0%`,
        endColor: `${theme.palette.background.default} 100%`,
      }),
      top: 0,
      zIndex: 9,
      content: '\'\'',
      height: '100%',
      position: 'absolute',
      width: (THUMB_SIZE * 2) / 3,
    },
    '&:after': {
      right: 0,
      transform: 'scaleX(-1)',
    },
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  product: IProductItem | Ib2bProduct | IMerchant | any;
  imageLink?: string;
  block?: BlockType | undefined;
};

export default function ProductDetailsCarousel({ product, imageLink, block }: Props) {
  const theme = useTheme();
  const params = useParams();
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const router = useRouter();
  const path =
    block?.addiction === 'virtualCards' ? `/card/${product?.id}` : `/card/${product?.id}`;
  // const slides = product?.images?.map((img: any) => ({
  //   src: img,
  // })) || [{ src: imageLink || '' }];
  const retrieveUrl = () => {
    switch (PROJECT_NAME.toLowerCase()) {
      case 'xtra':
        return '/assets/images/xtra/default.png';
      case 'ksharim':
        return '/assets/images/happy-gift/banners/blueCardImage.png';
      case 'raayonit':
        return '/assets/images/raayonit/raayonit.jpg';
      case 'boombuy':
        return '/assets/images/boom-buy/banners/default.jpg';
      case 'citypeople':
        return '/assets/images/city-people/banners/default.png';
      case 'hashavshevet':
        return '/assets/images/hashavshevet/banners/default.png';
      default:
        return '';
    }
  };

  const imageProductLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(product?.mainImageURL)}`
      : product?.merchants?.length &&
      `${uploadProductsImage(product?.merchants[0].mainImageURL)}`) ||
    '/assets/images/product-default.png';

  const slides = [
    {
      src:
        imageProductLink === '/assets/images/product-default.png'
          ? retrieveUrl()
          : imageProductLink,
    },
    // {
    //   src: imageProductLink,
    // },
    // {
    //   src: imageProductLink,
    // },
  ];

  const lightbox = useLightBox(slides);

  const carouselLarge = useCarousel({
    rtl: false,
    draggable: false,
    adaptiveHeight: true,
  });

  const carouselThumb = useCarousel({
    rtl: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: slides.length > 3 ? 3 : slides.length,
  });

  useEffect(() => {
    carouselLarge.onSetNav();
    carouselThumb.onSetNav();
  }, [carouselLarge, carouselThumb]);

  useEffect(() => {
    if (lightbox.open) {
      carouselLarge.onTogo(lightbox.selected);
    }
  }, [carouselLarge, lightbox.open, lightbox.selected]);

  const renderLargeImg = (
    <Box
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        maxHeight: '400px',
        '& .slick-slider': { display: slides.length < 1 ? 'none' : 'block' },
      }}
    >
      <Carousel
        {...carouselLarge.carouselSettings}
        asNavFor={carouselThumb.nav}
        ref={carouselLarge.carouselRef}
      >
        {slides?.map((slide) => (
          <Image
            key={slide.src}
            alt={slide.src}
            src={slide.src}
            ratio="1/1"
            onClick={() => lightbox.onOpen(slide.src)}
            sx={{
              cursor: 'zoom-in',
              borderRadius: '20px',
              width: '50%',
              '& .component-image-wrapper': { pt: '50%!important' },
            }}
          />
        ))}
      </Carousel>
      {slides.length > 1 && (
        <CarouselArrowIndex
          sx={{ bgcolor: '#27097A', flexDirection: 'row-reverse' }}
          index={carouselLarge.currentIndex}
          total={slides?.length}
          onNext={carouselThumb.onNext}
          onPrev={carouselThumb.onPrev}
        />
      )}
    </Box>
  );

  const renderImage = (
    <Image
      key={slides[0].src}
      alt={slides[0].src}
      src={slides[0].src}
      ratio="1/1"
      onClick={() => lightbox.onOpen(slides[0].src)}
      sx={{
        cursor: 'zoom-in',
        borderRadius: '20px',
        width: '100%',
        '& .component-image-wrapper': { pt: '50%!important' },
      }}
    />
  );

  const renderThumbnails = (
    <StyledThumbnailsContainer length={slides?.length}>
      <Carousel
        {...carouselThumb.carouselSettings}
        asNavFor={carouselLarge.nav}
        ref={carouselThumb.carouselRef}
      >
        {slides.length > 1 &&
          slides?.map((item, index) => (
            <Box key={item.src} sx={{ px: 0.5, mt: 1 }}>
              <Avatar
                key={item.src}
                alt={item.src}
                src={item.src}
                variant="rounded"
                sx={{
                  width: THUMB_SIZE,
                  height: THUMB_SIZE,
                  opacity: 0.48,
                  cursor: 'pointer',
                  ...(carouselLarge.currentIndex === index && {
                    opacity: 1,
                    border: `solid 2.5px ${theme.palette.primary.main}`,
                  }),
                }}
              />
            </Box>
          ))}
      </Carousel>
    </StyledThumbnailsContainer>
  );

  return (
    <Box
      sx={{
        '& .slick-slide': {
          float: theme.direction === 'rtl' ? 'right' : 'left',
        },
      }}
    >
      <>
        {slides?.length > 1 ?
          <>{renderLargeImg}</>
          :
          <>{renderImage}</>
        }
      </>
      {renderThumbnails}


      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
        onGetCurrentIndex={(index) => lightbox.setSelected(index)}
      />
    </Box>
  );
}
