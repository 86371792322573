import { FC } from 'react';
import Slider from 'react-slick';

import Box from '@mui/material/Box';

import ShopBanner5 from './shop-banner-5';
import StandardBanner from './standard-banner';
import ShopBanner55 from './shop-banner-55';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

const AllShopBanners22: FC<ComponentTypeProps> = ({ block }) => {
  const combinedBlock: any = block?.combinedBlock;
  const isMobile = useResponsive('down', 'sm');

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let localHeight = block?.settings?.sliderHeight
    ? `${Number(block.settings.sliderHeight) + 30}px`
    : '400px';

  if (isMobile) localHeight = 'auto';

  return (
    <>
      <>
        {combinedBlock.length > 1 && (
          <Box
            sx={{
              '& .slick-slide': {
                // Добавляем отступы между слайдами
                direction: 'rtl',
                mb: '25px',
                display: 'grid!important',
                alignItems: 'end',
              },
              '& .slick-slider': {
                '& .slick-dots': {
                  position: 'absolute',
                  bottom: '35px',
                  '& .slick-active': {
                    '& button': {
                      '&::before': {
                        color: block?.settings?.dotsColor,
                        fontSize: '10px',
                      },
                    },
                  },
                  '& li': {
                    '& button': {
                      width: '20%',
                      '&::before': {
                        color: block?.settings?.dotsColor,
                        fontSize: '10px',
                      },
                    },
                  },
                },
              },
            }}
          >
            <Slider {...settings}>
              {combinedBlock.map((banner: any, id: number) => (
                <Box
                  key={id}
                  sx={{
                    height: localHeight,
                    maxHeight: block?.settings?.sliderHeight,
                  }}
                >
                  {banner.bannerType === 'standard' && <StandardBanner banner={banner} />}
                  {banner.bannerType === 'threeImage' && <ShopBanner55 block={banner} />}
                </Box>
              ))}
            </Slider>
          </Box>
        )}
      </>
      <>
        {combinedBlock.length === 1 && (
          <Box>
            {combinedBlock.map((banner: any, id: number) => (
              <Box key={id}>
                {banner.bannerType === 'standard' && <StandardBanner banner={banner} />}
                {banner.bannerType === 'threeImage' && <ShopBanner55 block={banner} />}
              </Box>
            ))}
          </Box>
        )}
      </>
    </>
  );
};

export default AllShopBanners22;
