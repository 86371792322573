import * as Yup from 'yup';
import NextLink from 'next/link';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/navigation';
import { FC, useState, SetStateAction, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { useAuthContext } from 'src/auth/hooks';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import SmallZipLineIcon from 'src/assets/icons/happy-gift/smallZipLineIcon';

import Image from 'src/components/image';

import { useTranslate } from '../../../../locales';
import { ButtonField } from '../../../../types/generator';
import { useContentContext } from '../../../../components/content';
import { ComponentTypeProps } from '../../../../types/page-generator';
import FormProvider, { RHFRadioGroup } from '../../../../components/hook-form';

const MainBannerHolders: FC<ComponentTypeProps> = ({ block }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [type, setType] = useState('Voucher');
  const smDown = useResponsive('down', 'sm');
  const router = useRouter();
  const { dispatch } = useAppContext();
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const { t } = useTranslate();
  const smUp = useResponsive('up', 'sm');
  const openLoginForm = (pageName: string) => {
    router.push(pageName);
  };
  const mainTitle: string = typeof block?.mainTitle === 'string' ? block.mainTitle : '';
  const subtitle: string = typeof block?.subtitle === 'string' ? block.subtitle : '';
  const mainImage: string = typeof block?.mainImage === 'string' ? block.mainImage : '';
  const logo: string = typeof block?.logo === 'string' ? block.logo : '';
  const images: any = Array.isArray(block?.images) ? block.images : [];
  let backgroundImages = '';
  if (block?.settings?.enableSlider && images.length > 0) {
    backgroundImages = `url('${images[currentImageIndex]?.image}')`;
  } else {
    backgroundImages = mainImage
      ? `url('${mainImage}')`
      : `url('/assets/images/happy-gift/banners/big-banner.png')`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const handleTypeChange = (event: { target: { value: SetStateAction<string> } }) => {
    setType(event.target.value);
  };

  const NewSchema = Yup.object().shape({
    type: Yup.string(),
  });

  const defaultValues = {
    type: 'Voucher',
  };

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {});

  return (
    <Box
      sx={{
        width: '100%',
        height: '750px',
        position: 'relative',
        backgroundImage: backgroundImages,
        backgroundSize: 'cover',
        // mt: '-40px',
      }}
    >
      <Grid
        container
        spacing={1}
        height={1}
        width={1}
        alignItems="center"
        sx={{ mx: smDown ? '0px' : '50px' }}
      >
        <Grid item md={4}>
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '15px',
              padding: '15px',
              width: 0.6
            }}
          >
            <Grid item md={12}>
              <Image src={logo || '/assets/images/happy-gift/logo.png'} sx={{ width: '100%' }} />
            </Grid>
            <Grid item md={12}>
              <Typography
                color={block?.settings.mainContentTextColor}
                sx={{
                  fontSize: {md: block?.settings?.mainContentFontSize,xs: block?.settings?.mainContentMobileFontSize},
                  fontWeight: block?.settings?.mainContentFontWeight,
                  fontStyle: block?.settings?.mainContentFontStyle,
                  alignItems: block?.settings?.mainContentAlign,
                }}
              >
                {mainTitle || ''}
              </Typography>
              <SmallZipLineIcon width="55%" />
            </Grid>
            <Grid item md={12}>
              <Typography
                color={block?.settings?.subtitleTextColor}
                sx={{
                  fontSize: {md: block?.settings?.subtitleFontSize,xs: block?.settings?.subtitleMobileFontSize},
                  fontWeight: block?.settings?.subtitleFontWeight,
                  width: '80%',
                  fontStyle: block?.settings?.subtitleFontStyle,
                  alignItems: block?.settings?.subtitleAlign,
                }}
              >
                {subtitle || ''}
              </Typography>
            </Grid>
            {block?.settings?.enableChoice && (
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <RHFRadioGroup
                  sx={{ color: '#511D70' }}
                  row
                  name="addressType"
                  value={type}
                  onChange={handleTypeChange}
                  options={[
                    { label: 'קוד שובר/כרטיס מתנה', value: 'Voucher' },
                    { label: 'מספר טלפון', value: 'Phone' },
                  ]}
                />
              </FormProvider>
            )}
            <Grid item md={12}>
              <Stack
                alignItems="center"
                direction={{ xs: 'row-reverse', md: 'row-reverse' }}
                spacing={2}
                justifyContent="flex-end"
              >
                {block?.actionButtons?.map((menuButton: ButtonField, idx: number) => (
                  <Button
                    component={
                      menuButton?.link.includes('modal') || menuButton.link.includes('#')
                        ? Button
                        : NextLink
                    }
                    variant={menuButton?.variant || 'contained'}
                    color={menuButton?.color || 'primary'}
                    size={menuButton?.size || 'small'}
                    key={`menuButton_${idx}`}
                    sx={{
                      borderRadius: 10,
                      height: !smUp ? '40px' : '',
                      fontSize: !smUp ? '10px' : '',
                      px: !smUp ? '6px' : '',
                      textWrap: 'nowrap',
                    }}
                    href={
                      (!menuButton?.link.includes('modal') &&
                        !menuButton.isDialogToggler &&
                        !menuButton.link.includes('#') &&
                        menuButton?.link) ||
                      ''
                    }
                    onClick={() => {
                      if (menuButton.isDialogToggler && menuButton.modalId) {
                        setActiveDialog(menuButton.modalId);
                      }
                      if (menuButton.link.includes('#')) {
                        const newLink = menuButton?.link.replace('#', '');
                        window.location.href = `#${newLink}`;
                      }
                    }}
                  >
                    {menuButton.label || `[${t('no label')}]`}
                  </Button>
                ))}
              </Stack>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainBannerHolders;
