// locales
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// components
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

import { BlockType, ButtonField } from 'src/types/generator';
import { ICategoryItem, Ib2bTableFilter, Ib2bTableFilterValue } from 'src/types/b2b';

// ----------------------------------------------------------------------

type Props = {
  block: BlockType | undefined;
  filters: Ib2bTableFilter;
  onFilters: (name: string, value: Ib2bTableFilterValue) => void;
  //
  typeOptions: string[];
  categoryOptions: ICategoryItem[];
  areaOptions?: string[];
  handleResetFilters: Function;
  canReset: boolean;
  handleResetCategory: Function;
  handleResetArea: Function;
  handleResetTags: Function;
  handleResetName: Function;
};

export default function MerchantToolbar({
  block,
  filters,
  onFilters,
  typeOptions,
  categoryOptions,
  areaOptions,
  handleResetFilters,
  handleResetCategory,
  handleResetTags,
  handleResetName,
  handleResetArea,
  canReset,
}: Props) {
  const { t } = useTranslation();

  const popover = usePopover();
  const defaultSlider = [0, 3000];
  const [valueSlider, setValue] = useState<number[]>(defaultSlider);

  const actionButtons: any = block?.actionButtons || [];
  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterArea = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'area',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'type',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterPrice = useCallback(
    (event: any) => {
      onFilters('price', event.target.value);
    },
    [onFilters]
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    handleFilterPrice(event);
  };

  return (
    <Box sx={{ direction: 'rtl' }}>
      <Stack
        spacing={1}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
          justifyContent: 'center',
        }}
      >
        <IconButton
          onClick={() => {
            handleResetFilters();
            setValue(defaultSlider);
          }}
          disabled={!canReset}
        >
          <Iconify icon="basil:trash-solid" color={!canReset ? 'grey.500' : 'primary.main'} />
        </IconButton>
        {block?.settings?.enableButtons && (
          <Stack
            alignItems="center"
            justifyContent="center"
            direction={{ xs: 'row-reverse', md: 'row-reverse' }}
            spacing={2}
            sx={{ minWidth: '100px', width: {md: 0.5, xs: 1} }}
          >
            {actionButtons?.map((menuButton: ButtonField, idx: number) => (
              // @ts-ignore - проблемы с совместимостью типов, не кртичино
              <Button
                fullWidth
                variant={menuButton.variant}
                color={menuButton.color}
                size={menuButton.size}
                key={`menuButton_${idx}`}
                sx={{ borderRadius: 10, p: '2px' }}
                endIcon={<Iconify icon={menuButton.icon} />}
              >
                {menuButton.label || `[${t('no label')}]`}
              </Button>
            ))}
          </Stack>
        )}
        {block?.settings?.enablePriceFilter && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ '& .MuiTypography-root': { color: 'primary.main' } }}
              >
                <Typography
                  sx={{ fontWeight: 800 }}
                >{`${filters.price[0]} - ${filters.price[1]} ₪`}</Typography>
                <Typography>תקציב</Typography>
              </Stack>
              <Slider
                value={valueSlider}
                min={0}
                max={3000}
                onChange={handleChange}
                valueLabelDisplay="auto"
              />
            </Stack>
          </FormControl>
        )}

        {block?.settings?.enableArea && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
              minWidth: { xs: 1, md: 60 },
              maxWidth: { xs: 1, md: 120 },
            }}
          >
            <InputLabel>{t('אזור')}</InputLabel>

            <Select
              multiple
              value={filters.area}
              onChange={handleFilterArea}
              input={<OutlinedInput label="אזור" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
                <Iconify
                  onClick={() => handleResetArea()}
                  icon="tabler:trash"
                  sx={{
                    cursor: 'pointer',
                    color: filters.area.length === 0 ? 'gray' : 'red',
                  }}
                />
                <Typography>נבחר</Typography>
                <Typography>{filters.area.length}</Typography>
              </Stack>
              {Array.isArray(areaOptions) &&
                areaOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox disableRipple size="small" checked={filters.area.includes(option)} />
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {block?.settings?.enableCategory && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>{t('קטגוריה')}</InputLabel>

            <Select
              multiple
              value={filters.category}
              onChange={handleFilterCategory}
              input={<OutlinedInput label="קטגוריה" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
                <Iconify
                  onClick={() => handleResetCategory()}
                  icon="tabler:trash"
                  sx={{
                    cursor: 'pointer',
                    color: filters.category.length === 0 ? 'gray' : 'red',
                  }}
                />
                <Typography>נבחר</Typography>
                <Typography>{filters.category.length}</Typography>
              </Stack>
              {categoryOptions?.map((option: any, id) => (
                <MenuItem key={id} value={option?.title}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.category.includes(option.title)}
                  />
                  {option?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {block?.settings?.enableType && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
              minWidth: { xs: 1, md: 60 },
              maxWidth: { xs: 1, md: 120 },
            }}
          >
            <InputLabel>{t('סוג')}</InputLabel>

            <Select
              multiple
              value={filters.type}
              onChange={handleFilterType}
              input={<OutlinedInput label="סוג" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
                <Iconify
                  onClick={() => handleResetTags()}
                  icon="tabler:trash"
                  sx={{
                    cursor: 'pointer',
                    color: filters.type.length === 0 ? 'gray' : 'red',
                  }}
                />
                <Typography>נבחר</Typography>
                <Typography>{filters.type.length}</Typography>
              </Stack>
              {Array.isArray(typeOptions) &&
                typeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox disableRipple size="small" checked={filters.type.includes(option)} />
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {block?.settings?.enableSearch && (
          <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
            <TextField
              sx={{ '& input': { direction: 'ltr' } }}
              fullWidth
              value={filters.name}
              onChange={handleFilterName}
              placeholder={`${t('חיפוש...')}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={
                        block?.settings?.isAlternativeDesign
                          ? 'icon-park-outline:search'
                          : 'eva:search-fill'
                      }
                      sx={{ color: 'text.disabled', mx: 0.5 }}
                    />
                  </InputAdornment>
                ),
                startAdornment: (
                  <>
                    {filters?.name && filters?.name?.length ? (
                      <InputAdornment position="start">
                        <Iconify
                          icon="iconamoon:close-fill"
                          sx={{ color: 'text.disabled', cursor: 'pointer' }}
                          onClick={() => handleResetName()}
                        />
                      </InputAdornment>
                    ) : (
                      ''
                    )}
                  </>
                ),
              }}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
