'use client';

import NextLink from 'next/link';
import configGlobal from 'content-config';
import { FC, useMemo, useState, useEffect } from 'react';
import { useParams, useRouter, usePathname } from 'next/navigation';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link, Badge, Button, Collapse } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useDebounce } from 'src/hooks/use-debounce';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { getDataFromLocaleStorage } from 'src/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from 'src/constants/localStorageKeys';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { useGetProducts, useGetMerchants, useGetCategories } from 'src/api/shop';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { useContentContext } from 'src/components/content';

import { SearchType, SearchResult } from 'src/types/shop';
import { INavItem, ButtonField } from 'src/types/generator';

import HeaderSearch from './search';
import NavMobile from './nav/mobile';
import { HEADER } from '../config-layout';
import { NavItemBaseProps } from './nav/types';
import HeaderShadow from '../common/header-shadow';
import { ComponentTypeProps } from '../../types/page-generator';
import { getContentValueFromProps } from '../../sections/generate-page/utils';

const Header: FC<ComponentTypeProps> = ({ block }) => {
  const { state, dispatch } = useAppContext();
  const { title } = useParams();
  const params = useParams();
  const { allContentAuth } = useContentContext();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { settings, links: rawLinks, menuButtons, burgerLinks: rawBurgerLinks } = block || {};
  const { t } = useTranslate();
  const getFieldValue = getContentValueFromProps(block);
  const debouncedQuery = useDebounce(searchQuery);
  const {
    showLogin,
    showCart,
    showWallet,
    showNotifications,
    showSearh,
    forceRtlParent,
    reverseDirection,
  } = settings || {};

  const links: NavItemBaseProps[] = formatNavMenu(rawLinks) || [];
  const burgerLinks: NavItemBaseProps[] = formatNavMenu(rawBurgerLinks) || [];

  const theme = useTheme();
  const [balance, setBalance] = useState(0);
  const mdUp = useResponsive('up', 'md');
  const smUp = useResponsive('up', 'sm');
  const isMobile = useResponsive('down', 'sm');
  const notificationIcon = getFieldValue('notificationIcon') || '';
  const userIcon = getFieldValue('userIcon') || '';
  const backgroundImage = block?.backgroundImage || '';
  const alternativeMenuButtons = (getFieldValue('alternativeMenuButtons') as ButtonField[]) || [];

  const { user, isAuthenticated } = useAuthContext();

  const logo = !isMobile ? block?.settings?.logo : block?.settings?.mobileLogo || block?.settings?.logo;

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const {
    state: { virtualCards },
  } = useAppContext();

  const accessToken =
    typeof localStorage !== 'undefined' ? localStorage.getItem('accessToken') || '' : '';

  // useEffect(() => {
  //   if (title === undefined) localStorage.setItem('productId', '');
  // }, [title]);

  useEffect(() => {
    if (accessToken && virtualCards.length > 0) {
      let summ = 0;
      virtualCards.forEach((card: any) => {
        summ += +card.balance;
      });
      setBalance(summ);
    }
  }, [accessToken, virtualCards]);

  const activities = getDataFromLocaleStorage(LOCAL_STORAGE_KEYS.subdomain);
  const router = useRouter();
  const pathname = usePathname();

  const empty = !showLogin && !showCart && !showWallet && !showNotifications && !showSearh;
  const handleSearch = (inputValue: string) => {
    setSearchQuery(inputValue);
  };

  const { products, productsLoading } = useGetProducts({
    search: debouncedQuery,
    limit: 5,
    offset: 0,
    relations: ['merchants', 'categories'],
  });

  const { categories, categoriesLoading } = useGetCategories({
    search: debouncedQuery,
    limit: 3,
    offset: 0,
  });

  const { merchants, merchantsLoading } = useGetMerchants({
    search: debouncedQuery,
    limit: 3,
    offset: 0,
  });

  const searchResults = useMemo(
    () =>
      debouncedQuery
        ? [
            ...assignType(products, SearchType.product),
            ...assignType(categories, SearchType.category),
            ...assignType(merchants, SearchType.merchant),
          ]
        : [],
    [products, categories, debouncedQuery]
  );

  const generateHref = (id: string, entityType: SearchType) => {
    switch (entityType) {
      case SearchType.category:
        return paths.category.details(id);
      case SearchType.product:
        return paths.product.details(id);
      case SearchType.merchant:
        return paths.merchant.details(id);
      default:
        return paths.page404;
    }
  };

  const searchLoading = useDebounce(categoriesLoading || productsLoading || merchantsLoading);

  const openLoginForm = (pageName: string) => {
    if (!user) {
      dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
      setActiveDialog('login');
    } else {
      router.push(pageName);
    }
  };

  const closeLoginForm = () => {
    if (!allContentAuth || isAuthenticated) {
      dispatch({ type: ActionTypes.CLOSE_LOGIN_FORM, payload: {} });
      if (pathname.includes('happy-holders')) {
        // TO-DO: delete hardcode
        router.push('https://b2c.happygift4u.co/');
      }
    }
  };

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  useEffect(() => {
    if (allContentAuth && !isAuthenticated) {
      dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
    }
  }, [allContentAuth, isAuthenticated]);

  const firstStop = block?.settings?.firstGradientColorStop
    ? `${block?.settings?.firstGradientColorStop}%`
    : '40%';
  const secondStop = block?.settings?.secondGradientColorStop
    ? `${block?.settings?.secondGradientColorStop}%`
    : '100%';

  const colorButton = block?.settings?.enableGradient
    ? {
        background: `linear-gradient(90deg, ${block?.settings?.firstGradientColor} ${firstStop}, ${block?.settings?.secondGradientColor} ${secondStop} )`,
      }
    : {};

  let justifyContent;
  if (isMobile && menuButtons && menuButtons.length === 1) justifyContent = 'flex-end';
  if (isMobile && menuButtons && menuButtons.length > 1) justifyContent = 'center';

  const actionButtons = (
    <Stack
      alignItems="center"
      direction={{ xs: 'row-reverse', md: 'row-reverse' }}
      spacing={2}
      p={0.5}
      justifyContent={isMobile ? justifyContent : 'flex-end'}
      width={1}
    >
      {menuButtons?.map((menuButton: ButtonField, idx: number) => (
        <Button
          component={
            menuButton?.link.includes('modal') || menuButton.link.includes('#') ? Button : NextLink
          }
          variant={menuButton?.variant || 'contained'}
          color={menuButton?.color || 'primary'}
          size={menuButton?.size || 'medium'}
          key={`menuButton_${idx}`}
          sx={{
            borderRadius: block?.settings?.isAlternativeDesign ? '5px' : '5px',
            height: !smUp ? '40px' : 'auto',
            fontSize: !smUp ? '10px' : '',
            px: !smUp ? '6px' : '',
            textWrap: 'nowrap',
            ...(menuButton.variant === 'contained' ? colorButton : {}),
          }}
          href={
            (!menuButton?.link.includes('modal') &&
              !menuButton.isDialogToggler &&
              !menuButton.link.includes('#') &&
              menuButton?.link) ||
            ''
          }
          onClick={() => {
            if (menuButton.isDialogToggler && menuButton.modalId) {
              setActiveDialog(menuButton.modalId);
            }
            if (menuButton.link.includes('#')) {
              const newLink = menuButton?.link.replace('#', '');
              window.location.href = `#${newLink}`;
            }
          }}
        >
          {menuButton.label || `[${t('no label')}]`}
        </Button>
      ))}
    </Stack>
  );

  const alternativeButtons = (
    <Stack direction="row" alignItems="center" width={isMobile ? '100%' : '500px'} gap={2}>
      {alternativeMenuButtons.map((menuButton: any, idx: number) => (
        <Button
          key={idx}
          variant={menuButton?.buttonVariant || 'contained'}
          // fullWidth
          size={menuButton?.buttonSize}
          sx={{
            width: '90%',
            borderRadius: menuButton?.buttonBorderRadius,
            height: '50px',
            fontSize: '16px',
            background: menuButton?.buttonVariant === 'contained' && menuButton?.buttonBgColor,
            color:
              menuButton?.buttonVariant === 'outlined'
                ? menuButton?.buttonBgColor
                : menuButton?.buttonColor,
            fontWeight: 400,
            borderColor: menuButton?.buttonVariant === 'outlined' && menuButton?.buttonBgColor,
            '&:hover': {
              background:
                menuButton?.buttonVariant === 'contained' ? menuButton?.buttonBgColor : '',
              color:
                menuButton?.buttonVariant === 'outlined'
                  ? menuButton?.buttonBgColor
                  : menuButton?.buttonColor,
              borderColor: menuButton?.buttonVariant === 'outlined' && menuButton?.buttonBgColor,
            },
          }}
          endIcon={<Iconify icon={menuButton?.buttonIcon} />}
        >
          {menuButton?.buttonLabel}
        </Button>
      ))}
    </Stack>
  );

  return (
    <>
      {!block?.settings?.disableHeader ? (
        <>
          {isMobile && block?.settings?.isAlternativeDesign ? (
            <>
              {block?.settings?.isAlternativeDesign ? (
                <Stack
                  gap={2}
                  sx={{
                    mt: -2,
                    pt: 1,
                    pb: params?.title === 'product' || params?.title === 'merchant' ? 11 : 0,
                    width: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    borderBottomRightRadius: block?.settings?.enableBorder ? '20px' : 0,
                    borderBottomLeftRadius: block?.settings?.enableBorder ? '20px' : 0,
                  }}
                >
                  <Stack
                    direction="row"
                    width={1}
                    justifyContent="space-between"
                    px={2}
                    alignItems="center"
                    mb={!alternativeMenuButtons.length ? 2 : 0}
                  >
                    {block?.settings?.showAlternativeNotification ? (
                      <Box
                        sx={{
                          p: 1,
                          width: { xs: '40px', md: '40px' },
                          height: { xs: '40px', md: '40px' },
                          backgroundColor: 'rgba(255, 255, 255, 0.4)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '10px',
                        }}
                        onClick={() => {
                          if (block?.alternativeNotificationLink !== '#back')
                            router.push(`${block?.alternativeNotificationLink}`);
                          else router.back();
                        }}
                      >
                        <Iconify
                          icon={`${notificationIcon}`}
                          sx={{
                            color: 'white',
                            width: '100%',
                            height: '100%',
                            zIndex: 1,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: { xs: '40px', md: '40px' },
                          height: { xs: '40px', md: '40px' },
                        }}
                      />
                    )}
                    {block?.settings?.enableAlternativeMainTitle && (
                      <Typography sx={{ color: 'white', textAlign: 'center', opacity: 0.5 }}>
                        {block?.settings?.alternativeMainTitle}
                      </Typography>
                    )}

                    {block?.settings?.showAlternativeUser ? (
                      <Box
                        sx={{
                          p: 1,
                          width: { xs: '40px', md: '40px' },
                          height: { xs: '40px', md: '40px' },
                          backgroundColor: 'rgba(255, 255, 255, 0.4)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '10px',
                        }}
                        onClick={() => {
                          if (block?.alternativeUserLink !== '#back')
                            router.push(`${block?.alternativeUserLink}`);
                          else router.back();
                        }}
                      >
                        <Iconify
                          icon={`${userIcon}`}
                          sx={{
                            color: 'white',
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: { xs: '40px', md: '40px' },
                          height: { xs: '40px', md: '40px' },
                        }}
                      />
                    )}
                  </Stack>
                  {block?.settings?.enableAlternativeSubtitle && (
                    <Stack width="100%" alignItems="center">
                      <Typography sx={{ color: 'white', textAlign: 'center', width: '69%' }}>
                        {block?.settings?.alternativeSubtitle}
                      </Typography>
                    </Stack>
                  )}
                  {!!alternativeMenuButtons.length && (
                    <Stack alignItems="center" mb={4} gap={2}>
                      {alternativeMenuButtons.map((menuButton: any, idx: number) => (
                        <Button
                          key={idx}
                          variant={menuButton?.buttonVariant || 'contained'}
                          // fullWidth
                          size={menuButton?.buttonSize}
                          sx={{
                            width: '90%',
                            borderRadius: menuButton?.buttonBorderRadius || '12px',
                            fontSize: '16px',
                            height: '50px',
                            lineHeight: '18px',
                            letterSpacing: '-0.078px',
                            background:
                              menuButton?.buttonVariant === 'contained' &&
                              menuButton?.buttonBgColor,
                            color:
                              menuButton?.buttonVariant === 'outlined'
                                ? menuButton?.buttonBgColor
                                : menuButton?.buttonColor,
                            fontWeight: 600,
                            borderColor:
                              menuButton?.buttonVariant === 'outlined' && menuButton?.buttonBgColor,
                            '&:hover': {
                              background:
                                menuButton?.buttonVariant === 'contained'
                                  ? menuButton?.buttonBgColor
                                  : '',
                              color:
                                menuButton?.buttonVariant === 'outlined'
                                  ? menuButton?.buttonBgColor
                                  : menuButton?.buttonColor,
                              borderColor:
                                menuButton?.buttonVariant === 'outlined' &&
                                menuButton?.buttonBgColor,
                            },
                          }}
                          endIcon={<Iconify icon={menuButton?.buttonIcon} />}
                        >
                          {menuButton?.buttonLabel}
                        </Button>
                      ))}
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Grid
                  container
                  direction="row"
                  sx={{
                    mt: -2,
                    pt: 1,
                    width: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    borderBottomRightRadius: '20px',
                    borderBottomLeftRadius: '20px',
                    alignItems: 'center',
                    pb: 2,
                  }}
                >
                  <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {block?.settings?.showAlternativeNotification ? (
                      <Box
                        sx={{
                          p: 1,
                          width: { xs: '40px', md: '40px' },
                          height: { xs: '40px', md: '40px' },
                          backgroundColor: 'rgba(255, 255, 255, 0.4)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (block?.alternativeNotificationLink !== '#back')
                            router.push(`${block?.alternativeNotificationLink}`);
                          else router.back();
                        }}
                      >
                        <Iconify
                          icon={`${notificationIcon}`}
                          sx={{
                            color: 'white',
                            width: '100%',
                            height: '100%',
                            zIndex: 1,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box />
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    {alternativeButtons}
                  </Grid>
                  <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {block?.settings?.showAlternativeUser ? (
                      <Box
                        sx={{
                          p: 1,
                          width: { xs: '40px', md: '40px' },
                          height: { xs: '40px', md: '40px' },
                          backgroundColor: 'rgba(255, 255, 255, 0.4)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (block?.alternativeUserLink !== '#back')
                            router.push(`${block?.alternativeUserLink}`);
                          else router.back();
                        }}
                      >
                        <Iconify
                          icon={`${userIcon}`}
                          sx={{
                            color: 'white',
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box />
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <AppBar>
              <Toolbar
                disableGutters
                sx={{
                  height: {
                    xs: HEADER.H_MOBILE,
                    md: HEADER.H_DESKTOP_OFFSET,
                  },
                  transition: theme.transitions.create(['height'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.shorter,
                  }),
                  ...(offsetTop && {
                    ...bgBlur({
                      color: theme.palette.background.default,
                    }),
                    height: {
                      md: HEADER.H_DESKTOP_OFFSET,
                    },
                  }),
                }}
              >
                <Box
                  sx={{
                    height: 1,
                    display: 'flex',
                    flexDirection: reverseDirection ? 'row-reverse' : 'row',
                    alignItems: 'center',
                    direction: forceRtlParent ? 'rtl' : 'inherit',
                    width: '100%',
                    // mx: 3,
                    flexWrap: !empty ? 'wrap' : 'nowrap',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack
                    direction={reverseDirection ? 'row-reverse' : 'row'}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    width={1}
                  >
                    <Link
                      sx={{width: '150px', mx: {md: 2, xs: 0} }}
                      component={NextLink}
                      href={
                        block?.settings?.customLogoLink && block?.settings?.logoLink
                          ? `/${block?.settings?.logoLink}`
                          : '/'
                      }
                    >
                      <Box
                        sx={{
                          // mt: 1.7,
                          display: 'flex',
                          justifyContent: reverseDirection ? 'flex-end' : 'flex-start',
                          ...(smUp && { height: '42px' }),
                        }}
                      >
                        <Image
                          src={logo}
                          alt={configGlobal.companyName}
                          sx={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </Box>
                    </Link>

                    {showCart ? (
                      <IconButton onClick={() => openLoginForm('/cart')}>
                        <Badge
                          badgeContent={user && state.cart.length ? state.cart.length : 0}
                          sx={{
                            color: 'secondary.contrastText',
                            '& .MuiBadge-badge': { bgcolor: 'primary.main' },
                          }}
                        >
                          <Iconify
                            icon="mynaui:cart"
                            color="primary.main"
                            width={30}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Badge>
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {showWallet ? (
                      <IconButton onClick={() => openLoginForm('/wallet')}>
                        <Badge
                          max={99999}
                          badgeContent={`₪${user ? virtualCards && balance : 0}`}
                          sx={{
                            color: 'secondary.contrastText',
                            '& .MuiBadge-badge': { bgcolor: 'primary.main' },
                          }}
                        >
                          <Iconify
                            icon="solar:wallet-linear"
                            color="primary.main"
                            width={25}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Badge>
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {showNotifications ? (
                      <IconButton>
                        <Iconify
                          icon="solar:bell-linear"
                          color="primary.main"
                          width={25}
                          sx={{ cursor: 'pointer' }}
                        />
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {showLogin ? (
                      <IconButton onClick={() => openLoginForm('/account')}>
                        <Iconify
                          icon="solar:user-linear"
                          color="primary.main"
                          width={25}
                          sx={{ cursor: 'pointer' }}
                        />
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {showSearh ? (
                      <IconButton
                        onClick={() => {
                          setShowSearchBar((v) => !v);
                          dispatch({ type: ActionTypes.SET_FILTER_QUERY, payload: '' });
                        }}
                      >
                        <Iconify
                          icon="ion:search-outline"
                          color="primary.main"
                          width={25}
                          sx={{ cursor: 'pointer' }}
                        />
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {!isMobile && (
                      <Collapse orientation="horizontal" in={showSearchBar}>
                        <HeaderSearch
                          query={debouncedQuery}
                          results={searchResults}
                          loading={searchLoading}
                          hrefItem={generateHref}
                          onSearch={handleSearch}
                          searchQuery={searchQuery}
                        />
                      </Collapse>
                    )}
                    {!isMobile && (
                      <Stack
                        direction="row-reverse"
                        flexWrap="wrap"
                        width={1}
                        justifyContent="flex-end"
                      >
                        {actionButtons}
                      </Stack>
                    )}
                    <NavMobile data={burgerLinks} logo={block?.settings.logo} />
                  </Stack>

                  <Box sx={{ flexGrow: 1 }} />
                  {isMobile && showSearchBar && (
                    <Collapse
                      orientation="horizontal"
                      in={showSearchBar}
                      sx={{ width: '100%', '& .MuiCollapse-wrapper ': { display: 'box' } }}
                    >
                      <HeaderSearch
                        query={debouncedQuery}
                        results={searchResults}
                        loading={searchLoading}
                        hrefItem={generateHref}
                        onSearch={handleSearch}
                        searchQuery={searchQuery}
                      />
                    </Collapse>
                  )}
                  {isMobile && (
                    <Stack
                      direction="row-reverse"
                      flexWrap="wrap"
                      width={1}
                      justifyContent="center"
                    >
                      {actionButtons}
                    </Stack>
                  )}
                </Box>
              </Toolbar>
              {/* {isMobile && ( */}
              {/*  <Box mx={5}> */}
              {/*    <NavDesktop data={links} /> */}
              {/*  </Box> */}
              {/* )} */}
              {offsetTop && <HeaderShadow />}
              {/* <CustomPopUp */}
              {/*  onClose={closeLoginForm} */}
              {/*  open={state.isLoginForm} */}
              {/*  title={<Image src={block?.settings.logo} />} */}
              {/*  action={<NewLogin closingForbidden={allContentAuth} onClose={closeLoginForm} />} */}
              {/* /> */}
            </AppBar>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

// ----------------------------------------------------------------------

export function formatNavMenu(rawLinks?: INavItem[]) {
  return rawLinks?.map((link: INavItem) => {
    const { label: title, icon, path } = link;
    if (link?.children?.length) {
      const newLinks: NavItemBaseProps = {
        title,
        path,
        ...(icon && { icon: <Iconify icon={icon} /> }),
      };
      newLinks.children = [
        {
          subheader: link.label,
          items: link.children.map((childLink) => ({
            title: childLink.label,
            path: childLink.path,
          })),
        },
      ];
      return newLinks;
    }
    return { title, path, ...(icon && { icon: <Iconify icon={icon} /> }) };
  });
}

// ----------------------------------------------------------------------

function assignType(items: SearchResult[], entityType: SearchType) {
  return items.map((item) => ({ ...item, entityType }));
}

// ----------------------------------------------------------------------

export default Header;
