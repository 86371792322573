import { useRef, useState } from 'react';
// eslint-disable-next-line
import ReactCodeInput from 'react-verification-code-input';

import { Box, Stack, Button, TextField, Typography } from '@mui/material';
import Image from '../../../../../../components/image';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { useAuthContext } from '../../../../../../auth/hooks';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

interface Props {
  onClose: () => void;
  closingForbidden?: boolean;
}

export const NewLogin = ({ block }: ComponentTypeProps) => {
  const { login, checkCode, user } = useAuthContext();
  const { dispatch } = useAppContext();

  const [phone, setPhone] = useState('');
  const [isCodeView, setIsCodeView] = useState(false);

  const mainImage: string = block?.mainImage as string;

  const ref = useRef<any>();

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const getCode = async () => {
    try {
      await login(phone);
      setIsCodeView(true);
    } catch (e) {
      console.error(e);
    }
  };

  const sendCode = async (code: string) => {
    try {
      await checkCode(phone, code);
      setActiveDialog(null);
      // window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  if (user) {
    return (
      <Box>
        <Typography mb={5}>
          You are logged in by phone <b>{user.phone}</b>
        </Typography>
      </Box>
    );
  }

  const isMaybeNext = !(phone.length > 9);

  let align;
  if (block?.settings?.imageAlign === 'start') align = 'flex-start';
  if (block?.settings?.imageAlign === 'end') align = 'flex-end';
  if (block?.settings?.imageAlign === 'center') align = 'center';

  return (
    <Box>
      <Stack alignItems={align}>
        <Image src={mainImage || ''} sx={{ width: '50%' }} />
      </Stack>
      {!isCodeView && (
        <Stack p={3} gap={2}>
          <Typography
            color="primary"
            sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}
          >
            אימות חשבון
          </Typography>
          <TextField
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            id="outlined-basic"
            label="מספר הטפון"
            variant="outlined"
            helperText="הקלד 10 ספרות"
          />
          <Stack direction="row" gap={2}>
            <Button
              sx={{ height: '40px', padding: 0 }}
              onClick={getCode}
              variant="contained"
              color="primary"
              disabled={isMaybeNext}
            >
              כניסה
            </Button>
          </Stack>
        </Stack>
      )}

      {isCodeView && (
        <Stack alignItems="center">
          <Typography mb={5}>הזן מספר טלפון</Typography>
          <Box mb={5} sx={{ direction: 'rtl' }}>
            <ReactCodeInput
              ref={ref}
              autoFocus
              fields={6}
              onComplete={(code) => sendCode(code)}
              // onChange={() => setError(null)}
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default NewLogin;
