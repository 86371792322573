import Stack from '@mui/material/Stack';
import { Box, Card, Link, Button, Typography } from '@mui/material';

import Image from '../image';
import { useTranslate } from '../../locales';

interface Props {
  amount: string | number;
  eventId: string;
  productId: string;
  isGiftCardType: boolean;
  name: string;
  coverUrl: string;
  onClick: (itemId: string, eventId: string) => void;
}

const SelectGiftItem = ({
  productId,
  eventId,
  isGiftCardType,
  amount,
  name,
  coverUrl,
  onClick,
}: Props) => {
  const { t } = useTranslate();

  const handleSelect = () => {
    onClick(productId, eventId);
  };

  const renderImg = (
    <Box
      sx={{
        position: 'relative',
        p: 1,
        maxWidth: 250,
        minWidth: 250,
        width: '100%',
        minHeight: 160,
        margin: '0 auto',
      }}
    >
      <Image
        alt={name}
        src={coverUrl}
        sx={{
          borderRadius: 1.5,
          width: '100%',
          minHeight: '160px',
          maxHeight: '160px',
        }}
      />
    </Box>
  );

  const renderContent = (
    <Stack spacing={2.5} sx={{ p: 3, pt: 2 }}>
      <Typography color="inherit" variant="subtitle1" sx={{ lineClamp: 2, overflow: 'hidden' }}>
        {name}
      </Typography>

      <Typography color="inherit" variant="subtitle2" sx={{ lineClamp: 2, overflow: 'hidden' }}>
        {isGiftCardType
          ? `${t('Amount')}: ${amount} ₪`
          : `${t('Amount')}: ${parseFloat(amount.toString().replace(/0+$/, '').replace(/\.$/, ''))}`}
      </Typography>

      <Stack direction="row" justifyContent="space-between" gap={1}>
        <Button
          sx={{
            flex: 1,
            bgcolor: 'primary.light',
            color: 'white',
            '&:hover': { bgcolor: 'primary.light' },
          }}
          size="small"
          variant="outlined"
          onClick={() => onClick(productId, eventId)}
        >
          {t('Select')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{
            flex: 1,
          }}
        >
          {t('View')}
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <Card sx={{ maxWidth: 260, margin: '25px auto' }}>
      {renderImg}

      {renderContent}
    </Card>
  );
};

export default SelectGiftItem;
