import NextLink from 'next/link';
import { FC, useMemo } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

import Image from 'src/components/image';

import SanitizeHTML from '../../../../utils/sanitize';
import { getContentValueFromProps } from '../../utils';
import { ButtonField } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

// =====================================================================

const StandartHorizontalBannerV1: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch } = useAppContext();
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslate();
  const getFieldValue = getContentValueFromProps(block);
  // === Content Values ===
  const title = useMemo(() => {
    if (block?.title) {
      return block?.title;
    }
    return getFieldValue('title');
  }, [block?.title, getFieldValue]);

  const linkText = useMemo(() => {
    if (block?.linkText) {
      return block?.linkText;
    }
    return getFieldValue('linkText');
  }, [block?.linkText, getFieldValue]);

  const heroImage = useMemo(() => getFieldValue('mainImage'), [getFieldValue]);

  const logoImage = useMemo(() => getFieldValue('headingLogo'), [getFieldValue]);

  const enableHeading = useMemo(() => getFieldValue('settings.enableHeading'), [getFieldValue]);

  const enableLinkText = useMemo(() => getFieldValue('settings.enableLinkText'), [getFieldValue]);

  const logoSize = useMemo(() => getFieldValue('settings.logoSize'), [getFieldValue]);

  const headingWidth = useMemo(() => getFieldValue('settings.headingWidth'), [getFieldValue]);

  const logo = useMemo(() => {
    if (getFieldValue('settings.enableLogo')) {
      return (
        <Image
          className={getFieldValue('settings.headingLogoClasses')}
          src={logoImage}
          sx={{ width: logoSize, my: 1, '& img': { objectFit: 'contain' } }}
        />
      );
    }
    return '';
  }, [getFieldValue, logoImage, logoSize]);

  const secondImage = useMemo(() => getFieldValue('secondImage'), [getFieldValue]);
  const contentText = useMemo(() => {
    if (block?.contentText) {
      return block?.contentText;
    }
    return getFieldValue('contentText');
  }, [block?.contentText, getFieldValue]);

  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [getFieldValue],
  );

  // === Settings Values ===
  const layout = useMemo(() => getFieldValue('settings.layout'), [getFieldValue]);
  const imageSize = useMemo(() => getFieldValue('settings.imageSize'), [getFieldValue]);
  const logoPosition = useMemo(() => getFieldValue('settings.logoPosition'), [getFieldValue]);
  const backgroundImage = useMemo(() => getFieldValue('settings.backgroundImage'), [getFieldValue]);
  const contentAlign = useMemo(() => getFieldValue('settings.contentAlign'), [getFieldValue]);
  const alignItems = useMemo(() => getFieldValue('settings.alignItems'), [getFieldValue]);
  const contentWidth = useMemo(() => getFieldValue('settings.contentWidth'), [getFieldValue]);
  const headingAlign = useMemo(() => getFieldValue('settings.headingAlign'), [getFieldValue]);
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [getFieldValue]);
  // text styles
  const headingTextColor = useMemo(
    () => getFieldValue('settings.headingTextColor'),
    [getFieldValue],
  );
  const headingFontSize = useMemo(() => getFieldValue('settings.headingFontSize'), [getFieldValue]);
  const headingFontWeight = useMemo(
    () => getFieldValue('settings.headingFontWeight'),
    [getFieldValue],
  );
  const headingFontStyle = useMemo(
    () => getFieldValue('settings.headingFontStyle'),
    [getFieldValue],
  );
  const headingTextDecoration = useMemo(
    () => getFieldValue('settings.headingTextDecoration'),
    [getFieldValue],
  );
  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentTextColor'),
    [getFieldValue],
  );
  const contentFontSize = useMemo(() => getFieldValue('settings.contentFontSize'), [getFieldValue]);
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [getFieldValue],
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [getFieldValue],
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [getFieldValue],
  );
  const linkTextColor = useMemo(
    () => getFieldValue('settings.linkTextColor'),
    [block?.settings.linkTextColor],
  );
  const linkFontSize = useMemo(
    () => getFieldValue('settings.linkFontSize'),
    [block?.settings.linkFontSize],
  );
  const linkFontWeight = useMemo(
    () => getFieldValue('settings.linkFontWeight'),
    [block?.settings.linkFontWeight],
  );
  const linkFontStyle = useMemo(
    () => getFieldValue('settings.linkFontStyle'),
    [block?.settings.linkFontStyle],
  );
  const linkTextDecoration = useMemo(
    () => getFieldValue('settings.linkTextDecoration'),
    [block?.settings.linkTextDecoration],
  );
  // classes
  const bannerClasses = useMemo(() => getFieldValue('settings.bannerClasses'), [getFieldValue]);
  const heroImageClasses = useMemo(
    () => getFieldValue('settings.heroImageClasses'),
    [getFieldValue],
  );
  const headingClasses = useMemo(() => getFieldValue('settings.headingClasses'), [getFieldValue]);
  const contentClasses = useMemo(() => getFieldValue('settings.contentClasses'), [getFieldValue]);
  const linkClasses = useMemo(
    () => getFieldValue('settings.linkClasses'),
    [block?.settings.linkClasses],
  );
  const smDown = useResponsive('down', 'sm');

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  return (
    <Card
      className={bannerClasses}
      sx={{
        width: '100%',
        borderRadius: '20px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        padding: !smUp ? '25px!important' : 5,
        '& strong': { color: block?.settings?.strongColor },
      }}
    >
      <Grid
        container
        spacing={2}
        // eslint-disable-next-line
        flexDirection={!smDown ? (layout === 'v2' ? 'row-reverse' : 'row') : 'column-reverse'}
        alignItems={alignItems}
      >
        <Grid
          item
          md={imageSize === 'bigger' ? 5 : 7}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: !smDown ? '50%' : '100%',
            justifyContent: block?.settings?.alignItems,
          }}
        >
          <Stack
            direction="column"
            width={1}
            alignItems={
              // eslint-disable-next-line no-nested-ternary
              contentAlign === 'start'
                ? 'flex-start'
                : contentAlign === 'end'
                  ? 'flex-end'
                  : 'center'
            }
          >
            {logoPosition === 'top' ? logo : ''}
            {enableHeading && (
              <Typography
                className={headingClasses}
                sx={{
                  fontWeight: headingFontWeight,
                  fontStyle: headingFontStyle,
                  textDecoration: headingTextDecoration,
                  color: headingTextColor,
                  fontSize: headingFontSize,
                  direction: forceRtl ? 'rtl' : undefined,
                  textAlign: headingAlign,
                  width: !smDown ? headingWidth : '100%',
                  // mb: 2,
                  lineHeight: block?.settings?.lineHeight,
                }}
              >
                {title}
              </Typography>
            )}
            {logoPosition === 'middle' ? logo : ''}
            <Typography
              sx={{
                direction: forceRtl ? 'rtl' : undefined,
                color: contentTextColor,
                fontSize: contentFontSize,
                fontStyle: contentFontStyle,
                fontWeight: contentFontWeight,
                textDecoration: contentTextDecoration,
                textAlign: contentAlign,
                width: '100%',
                lineHeight: block?.settings?.lineHeight,
              }}
              className={contentClasses}
            >
              <SanitizeHTML html={contentText} />
            </Typography>
            {logoPosition === 'bottom' ? logo : ''}
            {enableLinkText && (
              <Typography
                className={linkClasses}
                sx={{
                  direction: forceRtl ? 'rtl' : undefined,
                  color: linkTextColor,
                  fontSize: linkFontSize,
                  fontStyle: linkFontStyle,
                  fontWeight: linkFontWeight,
                  textDecoration: linkTextDecoration || 'underline',
                  mb: 1,
                  lineHeight: block?.settings?.lineHeightLink,
                }}
              >
                {linkText}
              </Typography>
            )}
            <Stack direction="row" spacing={2} py={1}>
              {actionButtons.map((actionButton: ButtonField, idx: number) => (
                <Button
                  component={actionButton?.link.includes('modal') ? Button : NextLink}
                  variant={actionButton?.variant || 'contained'}
                  color={actionButton?.color || 'primary'}
                  size={actionButton?.size || 'small'}
                  key={`menuButton_${idx}`}
                  sx={{
                    borderRadius: 10,
                    fontSize: '10px',
                    background: actionButton.background,
                    '&:hover': { background: actionButton.background },
                  }}
                  href={
                    (!actionButton?.link.includes('modal') &&
                      !actionButton.isDialogToggler &&
                      actionButton?.link) ||
                    ''
                  }
                  onClick={() => {
                    if (actionButton.isDialogToggler && actionButton.modalId) {
                      setActiveDialog(actionButton.modalId);
                    }
                  }}
                >
                  {actionButton.label || `[${t('no label')}]`}
                </Button>
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={imageSize === 'bigger' ? 7 : 5}
          sx={{ margin: imageSize === 'bigger' ? 'inherit' : '' }}
        >
          <Box sx={{ position: 'relative' }}>
            {secondImage && (
              <Image
                src={secondImage}
                sx={{
                  width: '100%',
                  position: 'absolute',
                  top: '-60px',
                  left: 0,
                }}
              />
            )}
            {/* {bannerImage !== 'transparent' && bannerImage ? ( */}
            {/*  <Image className={heroImageClasses} src={bannerImage} sx={{ width: '100%' }} /> */}
            {/* ) : ( */}
            <Image className={heroImageClasses} src={heroImage} sx={{ width: '100%' }} />
            {/* )} */}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
export default StandartHorizontalBannerV1;
