'use client';

import { useState, useEffect } from 'react';

import { API } from 'src/helpers/api';
import { useAuthContext } from 'src/auth/hooks';

import { IGift } from 'src/types/gift';

import SelectGift from './select-gift';
import { getDomainName } from '../../utils/client-common';

const SelectGiftWrapper = () => {
  const { user } = useAuthContext();

  const [gifts, setGifts] = useState<IGift[]>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      let activityId = '';
      const response = await API({
        url: `/smbActivity/getActivityByDomain?domain=${getDomainName()}`,
        method: 'GET',
      });
      if (response.data && response.data.payload) {
        activityId = response?.data.payload.id;
      }
      const { data } = await API({
        url: `giftSelection`,
        params: {
          activityId: activityId && activityId,
          ssoUserId: user?.id,
          relations: ['merchants', 'categories'],
        },
        method: 'GET',
      });
      if (data.length) {
        setGifts(data);
      }
    } catch (e) {
      console.error(e);
    }
  };
  if (!user?.id) return null;
  return (
    <div>
      {gifts.map((gift: IGift) => (
        <SelectGift gift={gift} customerId={user?.id} refreshData={init} />
      ))}
    </div>
  );
};

export default SelectGiftWrapper;
