import { FC } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import Image from 'src/components/image';

import SanitizeHTML from 'src/utils/sanitize';
import { BlockType } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

// =====================================================================

type Props = {
  block: BlockType;
  height?: string;
};

const ShopBanner55 = ({ block: banner, height }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const images: any = banner?.combinedImages || [];
  const borderColor: string = banner?.borderColor as string;
  const subtitleColor: string = banner?.subtitleColor as string;
  const subtitleFontSize: string = banner?.subtitleFontSize as string;
  const title = banner.title || '';
  const contentText = banner?.contentText || '';
  const lineColor: any = banner?.lineColor || '#003DE2';
  const lineWidth: any = banner?.lineWidth || '40%';
  const contentTextColor: any = banner?.contentTextColor || '#27097A';
  let size = '75%';
  if (images?.length === 2 && !isMobile) size = '50%';
  if (images?.length === 3 && !isMobile) size = '75%';

  return (
    <Card
      sx={{
        width: isMobile ? '95%' : '100%',
        height,
        borderRadius: '20px',
        border: '1px solid ',
        borderColor: borderColor || '#27097A',
        backgroundSize: 'cover',
        padding: isMobile ? '25px 24px 24px 24px' : '35px 24px 24px 24px',
      }}
    >
      <Stack alignItems="center">
        <Typography
          sx={{
            fontWeight: 600,
            color: contentTextColor || '#27097A',
            fontSize: '36px',
            textAlign: 'start',
            // mb: 2,
          }}
        >
          {title}
        </Typography>
        <Stack width={1} direction="row" justifyContent="center">
          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: lineColor || '#003DE2',
              width: lineWidth || '40%',
            }}
          />
        </Stack>
        <Typography
          sx={{
            direction: 'rtl',
            color: subtitleColor || '#27097A',
            fontSize: subtitleFontSize || '20px',
          }}
        >
          <SanitizeHTML html={contentText} />
        </Typography>
      </Stack>
      <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" gap={2}>
        {images.map((item: any, id: number) => (
          <Image
            key={id}
            src={item.image}
            sx={{
              width: '100%',
              objectFit: 'contain',
              '& span.component-image-wrapper': {
                display: 'flex!important',
                justifyContent: 'center!important',
              },
              '& img': { width: size },
            }}
          />
          // </Grid>
        ))}
      </Stack>
    </Card>
  );
};
export default ShopBanner55;
