import { FC, useMemo } from 'react';

import { Stack, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { useAppContext } from 'src/contexts/AppContext';
import { ComponentTypeProps } from 'src/types/page-generator';

export const InfoField: FC<ComponentTypeProps> = ({ block }) => {
  const { user } = useAuthContext();
  const { state } = useAppContext();
  const { t } = useTranslate();

  const isClient = !state?.customer?.merchants?.length;
  const merchantName = !isClient && state?.customer?.merchants[0]?.title;
  const [firstName, lastName] = useMemo(() => {
    if (state.customer?.firstName || state?.customer?.lastName) {
      return [state?.customer?.firstName || '', state?.customer?.lastName || ''];
    }

    if (user?.firstName || user?.lastName) {
      return [user?.firstName || '', user?.lastName || ''];
    }

    return ['', t('Guest')];
  }, [user, state.customer]);

  return (
    <>
      {user && (
        <Stack
          direction="row"
          gap={1}
          sx={
            block?.settings?.fixPosition
              ? {
                  position: 'fixed',
                  top: '85px',
                  '& p': {
                    fontWeight: block?.settings?.textFontWeight,
                    color: block?.settings?.textColor,
                    fontSize: block?.settings?.textFontSize,
                  },
                }
              : {
                  '& p': {
                    fontWeight: block?.settings?.textFontWeight,
                    color: block?.settings?.textColor,
                    fontSize: block?.settings?.textFontSize,
                  },
                }
          }
        >
          <Typography>היי</Typography>
          {isClient ? (
            <>
              <Typography>{firstName}</Typography>
              <Typography>{lastName}</Typography>
            </>
          ) : (
            <Typography>{merchantName || 'Merchant'}</Typography>
          )}
        </Stack>
      )}
    </>
  );
};
