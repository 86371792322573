import Carousel from 'react-slick';
import { FC, useEffect } from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import { Stack, Button, TextField, Typography, CardActions } from '@mui/material';

import { useRouter } from '../../../../routes/hooks';
import Iconify from '../../../../components/iconify';
import { PROJECT_NAME } from '../../../../config-global';
import { IVirtualCard } from '../../../../types/club-cards';
import { getAllVirtualCards } from '../../../../api/clubCards';
import { useAppContext } from '../../../../contexts/AppContext';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';

const ClubCardsView: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch, state } = useAppContext();
  const OPTIONS = ['1', '2', '3'];
  const { virtualCards } = state;
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');
  useEffect(() => {
    getAllVirtualCards(dispatch, state.smbAccount.id);
  }, []);

  const retrieveUrl = () => {
    switch (PROJECT_NAME.toLowerCase()) {
      case 'xtra':
        return '/assets/images/xtra/default.png';
      case 'ksharim':
        return '/assets/images/happy-gift/banners/blueCardImage.png';
      case 'raayonit':
        return '/assets/images/raayonit/raayonit.jpg';
      case 'boombuy':
        return '/assets/images/boom-buy/banners/default.jpg';
      case 'citypeople':
        return '/assets/images/city-people/banners/default.png';
      case 'hashavshevet':
        return '/assets/images/hashavshevet/banners/default.png';
      case 'b24club':
        return '/assets/images/b24/banners/default.png';
      default:
        return '';
    }
  };

  const defaultImage = retrieveUrl();

  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 1 } },
  ];

  const myHeight =
    !block?.settings?.enablePrice && !block?.settings?.enableCount && !block?.settings?.enableButton
      ? '200px'
      : '250px';

  const handleClick = (id: string) => {
    const currentPageUrl = window.location.href;
    const url = `${currentPageUrl}card/${id}`;
    if (navigator.share) {
      navigator
        ?.share({
          url,
        })
        .then(() => console.log(''))
        .catch((error) => console.error('Error', error));
    } else {
      navigator.clipboard
        ?.writeText(url)
        ?.then(() => {
          alert('Copied');
        })
        .catch((error) => {
          console.error('Failed to copy: ', error);
        });
    }
  };

  const shareHeight = block?.settings?.enableShare ? '20px' : '';

  return (
    <Container
      maxWidth="xl"
      sx={{ mt: process.env.NEXT_PUBLIC_PROJECT_NAME === 'b24club' ? '' : 8 }}
    >
      {!isMobile ? (
        <Grid container spacing={1} direction="row">
          {/* <Carousel arrows={false} slidesToShow={1} responsive={responsive}> */}
          {state?.allVirtualCards?.map((card: IVirtualCard, id: number) => (
            <Grid key={id} item md={3} sx={{ px: 1 }}>
              <Card
                sx={{
                  width: 1,
                  maxWidth: 300,
                  height: `calc(myHeight + ${shareHeight})`,
                  margin: 'auto',
                }}
              >
                <CardMedia
                  sx={{ height: 160, cursor: 'pointer', backgroundSize: 'contain' }}
                  image={
                    card.mainImageURL ? `${uploadProductsImage(card.mainImageURL)}` : defaultImage
                  }
                  // title="green iguana"
                  onClick={() => {
                    localStorage.setItem('change', 'virtualCard');
                    router.push(`/card/${card?.id}`);
                  }}
                />
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      direction: block?.settings?.forceRtl ? 'rtl' : '',
                      color: block?.settings?.contentTextColor,
                      fontSize: block?.settings?.contentFontSize,
                      fontStyle: block?.settings?.contentFontStyle,
                      fontWeight: block?.settings?.contentFontWeight,
                      textDecoration: block?.settings?.contentTextDecoration,
                      textAlign: block?.settings?.contentAlign,
                    }}
                  >
                    {card.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Stack
                    direction="row-reverse"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      display:
                        !block?.settings?.enablePrice &&
                        !block?.settings?.enableCount &&
                        !block?.settings?.enableButton
                          ? 'none'
                          : '',
                    }}
                  >
                    {block?.settings?.enablePrice && (
                      <TextField
                        placeholder="סכום"
                        sx={{
                          width: '30%',
                          '& .MuiInputBase-root': {
                            '& input': {
                              padding: '6px',
                            },
                          },
                        }}
                      />
                    )}
                    {block?.settings?.enableCount && (
                      <TextField
                        select
                        SelectProps={{ native: true }}
                        sx={{
                          '& .MuiNativeSelect-select': {
                            py: 1,
                            px: 2,
                          },
                        }}
                      >
                        {OPTIONS.map((option, idx) => (
                          <option key={idx} value={option}>
                            {option}
                          </option>
                        ))}
                      </TextField>
                    )}
                    {block?.settings?.enableButton && (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: '20px',
                          height: '30px',
                          bgcolor: block?.settings?.buttonsBackgroundColor,
                          color: block?.settings?.buttonsTextColor,
                          fontSize: block?.settings?.buttonsFontSize,
                          fontStyle: block?.settings?.buttonsFontStyle,
                          fontWeight: block?.settings?.buttonsFontWeight,
                          textDecoration: block?.settings?.buttonsTextDecoration,
                        }}
                      >
                        לרכישה
                      </Button>
                    )}
                  </Stack>
                </CardActions>
                {block?.settings?.enableShare && (
                  <Stack width={1} alignItems="end">
                    <Iconify
                      icon="solar:share-bold"
                      width={16}
                      sx={{ mx: 2, cursor: 'pointer' }}
                      onClick={() => handleClick(card.id)}
                    />
                  </Stack>
                )}
              </Card>
            </Grid>
          ))}
          {/* </Carousel> */}
        </Grid>
      ) : (
        // <Grid container spacing={1} direction="row">
        <Carousel arrows={false} dots slidesToShow={1} responsive={responsive} infinite={false}>
          {state?.allVirtualCards?.map((card: IVirtualCard, id: number) => (
            <Grid key={id} item md={3} sx={{ px: 1 }}>
              <Card
                sx={{
                  width: 1,
                  maxWidth: 300,
                  height: `calc(myHeight + ${shareHeight})`,
                  margin: 'auto',
                }}
              >
                <CardMedia
                  sx={{ height: 160, cursor: 'pointer', backgroundSize: 'contain' }}
                  image={
                    card.mainImageURL ? `${uploadProductsImage(card.mainImageURL)}` : defaultImage
                  }
                  // title="green iguana"
                  onClick={() => {
                    localStorage.setItem('change', 'virtualCard');
                    router.push(`/card/${card?.id}`);
                  }}
                />
                <CardContent sx={{ p: 1 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      direction: block?.settings?.forceRtl ? 'rtl' : '',
                      color: block?.settings?.contentTextColor,
                      fontSize: block?.settings?.contentFontSize,
                      fontStyle: block?.settings?.contentFontStyle,
                      fontWeight: block?.settings?.contentFontWeight,
                      textDecoration: block?.settings?.contentTextDecoration,
                      textAlign: block?.settings?.contentAlign,
                    }}
                  >
                    {card.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Stack
                    direction="row-reverse"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      display:
                        !block?.settings?.enablePrice &&
                        !block?.settings?.enableCount &&
                        !block?.settings?.enableButton
                          ? 'none'
                          : '',
                    }}
                  >
                    {block?.settings?.enablePrice && (
                      <TextField
                        placeholder="סכום"
                        sx={{
                          width: '30%',
                          '& .MuiInputBase-root': {
                            '& input': {
                              padding: '6px',
                            },
                          },
                        }}
                      />
                    )}
                    {block?.settings?.enableCount && (
                      <TextField
                        select
                        SelectProps={{ native: true }}
                        sx={{
                          '& .MuiNativeSelect-select': {
                            py: 1,
                            px: 2,
                          },
                        }}
                      >
                        {OPTIONS.map((option, idx) => (
                          <option key={idx} value={option}>
                            {option}
                          </option>
                        ))}
                      </TextField>
                    )}
                    {block?.settings?.enableButton && (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: '20px',
                          height: '30px',
                          bgcolor: block?.settings?.buttonsBackgroundColor,
                          color: block?.settings?.buttonsTextColor,
                          fontSize: block?.settings?.buttonsFontSize,
                          fontStyle: block?.settings?.buttonsFontStyle,
                          fontWeight: block?.settings?.buttonsFontWeight,
                          textDecoration: block?.settings?.buttonsTextDecoration,
                        }}
                      >
                        לרכישה
                      </Button>
                    )}
                  </Stack>
                </CardActions>
                {block?.settings?.enableShare && (
                  <Stack width={1} alignItems="end">
                    <Iconify
                      icon="solar:share-bold"
                      width={16}
                      sx={{ mx: 2, cursor: 'pointer' }}
                      onClick={() => handleClick(card.id)}
                    />
                  </Stack>
                )}
              </Card>
            </Grid>
          ))}
        </Carousel>
        // </Grid>
      )}
    </Container>
  );
};

export default ClubCardsView;
