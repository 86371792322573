import { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line
import ReactCodeInput from 'react-verification-code-input';

import {
  Box,
  Stack,
  Button,
  TextField,
  Typography,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Checkbox,
} from '@mui/material';

import Image from 'src/components/image';
import { ComponentTypeProps } from 'src/types/page-generator';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

interface Props {
  onClose: () => void;
  closingForbidden?: boolean;
}

enum LoginMethod {
  PASSPORT = 'passport',
  CODE = 'code',
  EMAIL = 'email',
  PHONE = 'phone',
}

interface LoginError {
  response?: AxiosResponse<{ message?: string }, any>;
}

export const PasportNumberAndCode = ({ block }: ComponentTypeProps) => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  const { dispatch } = useAppContext();

  const LoginMethodsLabel = {
    [LoginMethod.PASSPORT]: t('by the passportId'),
    [LoginMethod.CODE]: t('using the code sent'),
    [LoginMethod.EMAIL]: t('by the email'),
    [LoginMethod.PHONE]: t('by the phone'),
  };

  const loginMethods = useMemo(() => {
    const methods = [LoginMethod.PASSPORT];
    if (block?.settings?.enableCode) methods.push(LoginMethod.CODE);
    if (block?.settings?.enableEmail) methods.push(LoginMethod.EMAIL);
    if (block?.settings?.enablePhone) methods.push(LoginMethod.PHONE);

    return methods;
  }, [block?.settings]);

  const [dedicatedMethod, setDedicatedMethod] = useState<LoginMethod>(loginMethods[0]);
  const [selectedLoginMethod, setSelectedLoginMethod] = useState<LoginMethod | null>(null);

  const [passport, setPassport] = useState('');
  const [oneTimeCode, setOneTimeCode] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const [isCodeView, setIsCodeView] = useState(false);
  const isMaybeNext = !(phone.length > 9);
  const rulesLink: any = block?.rulesLink || '';
  const [checkedRules, setCheckedRules] = useState(false);
  const [isCheckingOtp, setCheckingOtp] = useState<boolean | null>(null);
  const [invalidCodeMsg, setInvalidCodeMsg] = useState('');

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const {
    login: otpLogin,
    checkCode: checkOtpCode,
    loginByEmail,
    loginByPassport,
    user,
  } = useAuthContext();
  console.log(user);

  const mainImage: string = block?.mainImage as string;

  const getCode = async () => {
    try {
      await otpLogin(phone);
      setIsCodeView(true);
    } catch (e) {
      console.error(e);
    }
  };

  const sendCode = async (code: string) => {
    try {
      setCheckingOtp(true);
      await checkOtpCode(phone, code)
      setCheckingOtp(false);
    } catch (e) {
      console.error(e);
      setCheckingOtp(false);
    }
  };

  const handleChange = {
    method: (event: React.ChangeEvent<HTMLInputElement>) => {
      setDedicatedMethod(event.target.value as LoginMethod);
    },
    password: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (loginError) setLoginError('');
      setPassword(event.target.value);
    },
    email: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (loginError) setLoginError('');
      setEmail(event.target.value);
    },
    phone: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (loginError) setLoginError('');
      setPhone(event.target.value);
    },
    passport: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (loginError) setLoginError('');
      setPassport(event.target.value);
    },
    oneTimeCode: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (loginError) setLoginError('');
      setOneTimeCode(event.target.value);
    },
  };

  const handleError = (e: LoginError) =>
    setLoginError(e.response?.data?.message || 'Login error...');
  const handleSelectMethod = () => setSelectedLoginMethod(dedicatedMethod);
  const closePopup = () => dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  const handleLoginByPassport = () => {
    loginByPassport(passport, password)
      .then(() => closePopup())
      .catch(handleError);
  };
  const handleLoginByEmail = () => {
    loginByEmail(email, password)
      .then(() => closePopup())
      .catch(handleError);
  };

  const resetInputs = () => {
    setPassport('');
    setEmail('');
    setOneTimeCode('');
    setPhone('');
    setPassword('');
    setLoginError('');
  };
  const handleGoBack = () => {
    setSelectedLoginMethod(null);
    resetInputs();
  };

  useEffect(() => {
    if (isCheckingOtp === false) {
      if (!user) {
        setInvalidCodeMsg('Invalid code OTP!');
        setTimeout(() => {
          closePopup();
          setInvalidCodeMsg('');
        }, 3000);
      } else {
        closePopup()
      }
    }
  }, [isCheckingOtp]);

  const goBackToSelectMethodsIcon = (
    <Iconify
      width={60}
      color="primary.light"
      icon="material-symbols-light:keyboard-arrow-right"
      onClick={handleGoBack}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate(0px, -30px)',
      }}
    />
  );

  const selectorLoginMethods = (
    <Stack p={3} gap={2}>
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start', mb: '10px' }}
        >
          Select login method
        </FormLabel>
        <RadioGroup
          aria-label="login-method"
          name="login-method"
          value={dedicatedMethod}
          onChange={handleChange.method}
        >
          {loginMethods.map((method: string) => (
            <FormControlLabel
              key={method}
              value={method}
              control={<Radio />}
              label={LoginMethodsLabel[method as LoginMethod]}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Button
        sx={{ height: '40px', padding: 0, m: '20px auto', width: '50%' }}
        variant="contained"
        color="primary"
        onClick={handleSelectMethod}
      >
        {t('Continue')}
      </Button>
    </Stack>
  );

  const loginByPassportBlock = (
    <Stack p={3} pt={5} gap={2} sx={{ position: 'relative' }}>
      {goBackToSelectMethodsIcon}
      <Typography
        color="primary"
        sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}
      >{`${t('Enter passport ID and password')}`}</Typography>
      <TextField
        fullWidth
        value={passport}
        onChange={handleChange.passport}
        label={t('Passport ID')}
      />
      <TextField
        fullWidth
        value={password}
        onChange={handleChange.password}
        label={t('Password')}
      />
      <Typography sx={{ fontSize: 10, color: 'red' }}>{loginError}</Typography>
      <Button
        sx={{ height: '40px', padding: 0, mt: '20px' }}
        variant="contained"
        color="primary"
        disabled={password.length < 7 || passport.length < 8}
        onClick={handleLoginByPassport}
      >
        {t('login')}
      </Button>
    </Stack>
  );

  const loginByCodeBlock = (
    <Stack p={3} pt={5} gap={2} sx={{ position: 'relative' }}>
      <Typography sx={{ textAlign: 'center', width: '100%' }}>Not implemented yet</Typography>
      {goBackToSelectMethodsIcon}
    </Stack>
  );

  const loginByEmailBlock = (
    <Stack p={3} pt={5} gap={2} sx={{ position: 'relative' }}>
      {goBackToSelectMethodsIcon}
      <Typography
        color="primary"
        sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}
      >{`${t('Enter email and password')}`}</Typography>
      <TextField fullWidth value={email} onChange={handleChange.email} label={t('Email')} />
      <TextField
        fullWidth
        value={password}
        onChange={handleChange.password}
        label={t('Password')}
      />
      <Typography sx={{ fontSize: 10, color: 'red' }}>{loginError}</Typography>
      <Button
        sx={{ height: '40px', padding: 0, mt: '20px' }}
        variant="contained"
        color="primary"
        disabled={password.length < 7 || email.length < 8 || !email.includes('@')}
        onClick={handleLoginByEmail}
      >
        {t('login')}
      </Button>
    </Stack>
  );

  const loginByPhoneBlock = (
    <>
      {!isCodeView && (
        <Stack p={3} gap={2} alignItems="center" sx={{ m: '0 auto', position: 'relative' }} width="90%" maxWidth="460px">
          {goBackToSelectMethodsIcon}
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
              fontWeight: 600,
              textAlign: 'start',
            }}
          >
            אימות חשבון
          </Typography>
          <TextField
            value={phone}
            fullWidth
            onChange={(e) => setPhone(e.target.value)}
            id="outlined-basic"
            label="מספר הטפון"
            variant="outlined"
            helperText="הקלד 10 ספרות"
          />
          <Stack direction="row" gap={2} width="100%" mt={2}>
            <Button
              sx={{
                borderRadius: 10,
                fontWeight: 300,
                width: '100%',
              }}
              onClick={getCode}
              size="large"
              variant="contained"
              color="secondary"
              disabled={isMaybeNext}
            >
              {t('login')}
            </Button>
          </Stack>
          {block?.settings?.enableCheckbox && (
            <Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Checkbox checked={checkedRules} onChange={() => setCheckedRules(!checkedRules)} />
                <Typography sx={{ fontSize: '16px' }}>אני מאשר/מאשרת תנאי שימוש במערכת</Typography>
              </Stack>
              <Link href={rulesLink} passHref>
                <Typography
                  component="a"
                  sx={{ textDecoration: 'underline', mx: 6, color: 'black' }}
                  onClick={closePopup}
                >
                  לקריאה תקנון השימוש
                </Typography>
              </Link>
            </Stack>
          )}
        </Stack>
      )}
      {isCodeView && (
        <Stack alignItems="center" my={3}>
          <Typography>הזן קוד</Typography>
          {invalidCodeMsg && (
            <Typography mt={1} color="red">
              {invalidCodeMsg}
            </Typography>
          )}
          {isCheckingOtp && (
            <Typography mb={1} color="text.secondary">
              checking...
            </Typography>
          )}
          <Box mt={3} mb={5} sx={{ direction: 'rtl' }}>
            <ReactCodeInput ref={ref} autoFocus fields={6} onComplete={(code) => sendCode(code)} />
          </Box>
        </Stack>
      )}
    </>
  );

  return (
    <Box>
      <Stack width={1} alignItems="center">
        <Image src={mainImage || ''} sx={{ width: '20%' }} />
      </Stack>

      {!selectedLoginMethod && selectorLoginMethods}

      {selectedLoginMethod === LoginMethod.PASSPORT && loginByPassportBlock}

      {selectedLoginMethod === LoginMethod.CODE && loginByCodeBlock}

      {selectedLoginMethod === LoginMethod.EMAIL && loginByEmailBlock}

      {selectedLoginMethod === LoginMethod.PHONE && loginByPhoneBlock}
    </Box>
  );
};

export default PasportNumberAndCode;
