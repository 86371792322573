import { Box, Button, Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import React, { useCallback, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Slider from 'react-slick';
import Iconify from '../../../../../../components/iconify';
import Label from '../../../../../../components/label';
import { useLocales, useTranslate } from '../../../../../../locales';
import Statistics from './statistics';
import { PersonalInformationTab } from '../personal-information/personal-information-tab';
import { FamilyTab } from '../personal-information/family-tab';
import { DogTable } from './dog-table';
import { CatTable } from './cat-table';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { useResponsive } from '../../../../../../hooks/use-responsive';

type AccountTab = {
  value: string;
  label: string;
};

const data_paid_1 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_1 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
];

const data_paid_2 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_2 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: ' עבר תוקף התשלום',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: ' עבר תוקף התשלום',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
];

export const PetsTab = ({ block }: ComponentTypeProps) => {
  const isMobile = useResponsive('down', 'sm');
  const { t } = useTranslate();
  const [currentTab, setCurrentTab] = useState('notPaid');
  const TABS: AccountTab[] = [
    {
      value: 'notPaid',
      label: 'Not paid',
    },
    {
      value: 'paid',
      label: 'Have been paid',
    },
  ];

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const notPaidTabItem = Object.prototype.hasOwnProperty.call(block, 'notPaidTabItem')
    ? block?.notPaidTabItem
    : true;
  const paidTabItem = Object.prototype.hasOwnProperty.call(block, 'paidTabItem')
    ? block?.paidTabItem
    : true;

  const activeTabNames: string[] = [];

  if (notPaidTabItem) activeTabNames.push('notPaid');
  if (paidTabItem) activeTabNames.push('paid');

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Stack gap={2}>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            width={1}
          >
            <Stack direction={isMobile ? 'column' : 'row'} width={1}>
              <Iconify icon="la:dog" color="#000080" width="50px" />
              <Stack gap={1}>
                <Stack direction="row" gap={1}>
                  <Typography sx={{ fontWeight: 600 }}>מוקי</Typography>
                  <Iconify icon="icon-park-solid:attention" color="#AC2632" />
                </Stack>
                <Stack direction="row" gap={1}>
                  <Typography>כלב</Typography>
                  <li style={{ width: '5px' }} />
                  <Typography>מספר שבב: 123456778224536</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={1} width={isMobile ? 1 : 0.2}>
              <Typography>תוקף החיסון</Typography>
              <Typography sx={{ fontWeight: 600 }}>07.03.24</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}> פרטי בעל החיים</Typography>
          <Stack gap={2}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={1.9} xs={5.9} px={2}>
                <Typography>תאריך לידה</Typography>
                <Typography sx={{ fontWeight: 600 }}>07.03.13</Typography>
              </Grid>
              <Grid item md={1.9} xs={5.9} px={2} sx={{ borderLeft: '1px solid #c3cfe7' }}>
                <Typography>גיל</Typography>
                <Typography sx={{ fontWeight: 600 }}>8.7</Typography>
              </Grid>
              <Grid
                item
                md={1.9}
                xs={5.9}
                px={2}
                sx={!isMobile ? { borderLeft: '1px solid #c3cfe7' } : {}}
              >
                <Typography>צבע</Typography>
                <Typography sx={{ fontWeight: 600 }}>חום</Typography>
              </Grid>
              <Grid item md={1.9} xs={5.9} px={2} sx={{ borderLeft: '1px solid #c3cfe7' }}>
                <Typography>מין</Typography>
                <Typography sx={{ fontWeight: 600 }}>זכר</Typography>
              </Grid>
              <Grid
                item
                md={1.9}
                xs={5.9}
                px={2}
                sx={!isMobile ? { borderLeft: '1px solid #c3cfe7' } : {}}
              >
                <Typography>גזע</Typography>
                <Typography sx={{ fontWeight: 600 }}>רועה בלגי</Typography>
              </Grid>
              <Grid item md={1.9} xs={5.9} px={2} sx={{ borderLeft: '1px solid #c3cfe7' }}>
                <Typography>מסורס</Typography>
                <Typography sx={{ fontWeight: 600 }}>כן</Typography>
              </Grid>
            </Grid>
            <Stack direction="row" gap={1}>
              <Iconify icon="ic:outline-info" color="#000080" />
              <Typography>הכלב מוגדר כמסוכן</Typography>
            </Stack>
            <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              מה זה אומר בעצם?
            </Typography>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Typography sx={{ fontWeight: 600 }}>פרטי רישיון</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>תוקף הרישיון</Typography>
              <Typography sx={{ fontWeight: 600 }}>07.03.13</Typography>
            </Grid>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>תאריך השתלת השבב</Typography>
              <Typography sx={{ fontWeight: 600 }}>07.03.13</Typography>
            </Grid>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>רופא/ה מחסן/ת</Typography>
              <Typography sx={{ fontWeight: 600 }}>ד”ר תיקה בר-און</Typography>
            </Grid>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>חיסון אחרון</Typography>
              <Typography sx={{ fontWeight: 600 }}>03.03.23</Typography>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            sx={{
              bgcolor: '#9105371c',
              padding: 1,
              borderRadius: '5px',
              border: '1px solid #AC2632',
            }}
          >
            <Iconify icon="ic:baseline-info" color="#AC2632" />
            <Typography sx={{ fontWeight: 600 }}>תוקף החיסון של מוקי עומד להסתיים.</Typography>
            <Typography>לחידוש החיסון יש לעמוד במכסת ההשתלמויות ולשלם את האגרה</Typography>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>היסטוריית תשלומים</Typography>
            <CatTable block={block} dataPaid={data_paid_1} />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            width={1}
          >
            <Stack direction="row" width={1}>
              <Iconify icon="la:cat" color="#000080" width="50px" />
              <Stack gap={1}>
                <Stack direction="row" gap={1}>
                  <Typography sx={{ fontWeight: 600 }}>לילי</Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                  <Typography>חתולה</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}> פרטי בעל החיים</Typography>
          <Stack gap={2}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={1.9} xs={5.9} px={2}>
                <Typography>תאריך לידה</Typography>
                <Typography sx={{ fontWeight: 600 }}>07.03.13</Typography>
              </Grid>
              <Grid item md={1.9} xs={5.9} px={2} sx={{ borderLeft: '1px solid #c3cfe7' }}>
                <Typography>גיל</Typography>
                <Typography sx={{ fontWeight: 600 }}>8.7</Typography>
              </Grid>
              <Grid
                item
                md={1.9}
                xs={5.9}
                px={2}
                sx={!isMobile ? { borderLeft: '1px solid #c3cfe7' } : {}}
              >
                <Typography>צבע</Typography>
                <Typography sx={{ fontWeight: 600 }}>חום</Typography>
              </Grid>
              <Grid item md={1.9} xs={5.9} px={2} sx={{ borderLeft: '1px solid #c3cfe7' }}>
                <Typography>מין</Typography>
                <Typography sx={{ fontWeight: 600 }}>זכר</Typography>
              </Grid>
              <Grid
                item
                md={1.9}
                xs={5.9}
                px={2}
                sx={!isMobile ? { borderLeft: '1px solid #c3cfe7' } : {}}
              >
                <Typography>גזע</Typography>
                <Typography sx={{ fontWeight: 600 }}>רועה בלגי</Typography>
              </Grid>
              <Grid item md={1.9} xs={5.9} px={2} sx={{ borderLeft: '1px solid #c3cfe7' }}>
                <Typography>מסורס</Typography>
                <Typography sx={{ fontWeight: 600 }}>כן</Typography>
              </Grid>
            </Grid>
            <Stack direction="row" gap={1}>
              <Iconify icon="ic:outline-info" color="#000080" />
              <Typography>הכלב מוגדר כמסוכן</Typography>
            </Stack>
            <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              מה זה אומר בעצם?
            </Typography>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Typography sx={{ fontWeight: 600 }}>פרטי רישיון</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>תוקף הרישיון</Typography>
              <Typography sx={{ fontWeight: 600 }}>07.03.13</Typography>
            </Grid>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>תאריך השתלת השבב</Typography>
              <Typography sx={{ fontWeight: 600 }}>07.03.13</Typography>
            </Grid>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>רופא/ה מחסן/ת</Typography>
              <Typography sx={{ fontWeight: 600 }}>ד”ר תיקה בר-און</Typography>
            </Grid>
            <Grid item md={2.9} xs={5.9} px={2}>
              <Typography>חיסון אחרון</Typography>
              <Typography sx={{ fontWeight: 600 }}>03.03.23</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>היסטוריית תשלומים</Typography>
            <CatTable block={block} dataPaid={data_paid_1} />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
