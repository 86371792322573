import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { API } from 'src/helpers/api';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';

import { IGift } from '../../types/gift';
import { useTranslate } from '../../locales';
import SelectGiftItem from './select-gift-item';
import ConfirmModal from '../modals/confirm-modal';
import Carousel, { useCarousel, CarouselArrows } from '../carousel';

type Props = {
  gift: IGift;
  customerId: string;
  refreshData: () => void;
  sx?: SxProps;
};

type SelectedGiftType = {
  itemId: string;
  eventId: string;
};

const SelectGift = ({ sx, gift, customerId, refreshData }: Props) => {
  const [confirmModalVisibility, setConfirmModalVisibility] = useState(false);
  const { t } = useTranslate();
  const [selectedGift, setSelectedGift] = useState<SelectedGiftType | null>(null);

  const handleSelectGift = (itemId: string, eventId: string) => {
    setConfirmModalVisibility(true);
    setSelectedGift({ itemId, eventId });
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalVisibility(false);
  };

  const handleConfirmSelectGift = () => {
    setConfirmModalVisibility(false);
    selectGift();
  };
  const selectGift = async () => {
    try {
      const { data } = await API({
        url: 'giftSelection/useGiftSelectionEvent',
        method: 'POST',
        data: {
          eventId: selectedGift?.eventId,
          itemId: selectedGift?.itemId,
          customerId,
        },
      });
      refreshData();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Box sx={sx || {}}>
      <Stack sx={{ position: 'relative' }} alignItems="center" gap={2}>
        <Stack direction="row-reverse" alignItems="center" gap={2}>
          <Typography variant="h4" sx={{ color: 'primary.light', textTransform: 'capitalize' }}>
            {gift?.name}
          </Typography>
          <Typography>{t('(Select one gift)')}</Typography>
        </Stack>

        <GiftsWrapper giftsCount={gift.items?.length}>
          {gift.items.map((_gift: any) => {
            if (_gift.virtualCardTypeId) {
              return (
                <SelectGiftItem
                  amount={_gift.amount}
                  key={_gift.id}
                  productId={_gift.id}
                  eventId={_gift.eventId}
                  name={_gift.virtualCardType?.name}
                  coverUrl={
                    _gift.virtualCardType?.mainImageURL
                      ? uploadProductsImage(_gift.virtualCardType?.mainImageURL)
                      : ''
                  }
                  isGiftCardType
                  onClick={handleSelectGift}
                />
              );
            }
            return (
              <SelectGiftItem
                key={_gift.id}
                eventId={_gift.eventId}
                productId={_gift.id}
                amount={_gift.amount}
                name={_gift.product.title}
                coverUrl={
                  (_gift.product.image && uploadProductsImage(_gift.product.image)) ||
                  (_gift.product.mainImageURL && uploadProductsImage(_gift.product.mainImageURL)) ||
                  (_gift?.product.merchants &&
                    uploadProductsImage(_gift.product.merchants[0]?.mainImageURL))
                }
                isGiftCardType={false}
                onClick={handleSelectGift}
              />
            );
          })}
        </GiftsWrapper>
      </Stack>

      <ConfirmModal
        open={confirmModalVisibility}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirmSelectGift}
      />
    </Box>
  );
};

const screenWidth = typeof window !== 'undefined' ? window.screen.width : 0;
const GiftsWrapper = ({ children, giftsCount }: any) => {
  const breakpoints = {
    xxl: 1400,
    md: 768,
    sm: 576,
  };

  const carousel = useCarousel({
    slidesToShow: 4,
    slidesToScroll: 1,
    rtl: document.dir === 'rtl',
    centerMode: true,
    centerPadding: '0px',
    // ...CarouselDots({
    //   sx: { mt: 3 },
    // }),
    responsive: [
      {
        breakpoint: breakpoints.xxl,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpoints.sm,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  });

  return (screenWidth > breakpoints.xxl && giftsCount > 4) ||
    (screenWidth > breakpoints.md && screenWidth < breakpoints.xxl && giftsCount > 3) ||
    (screenWidth > breakpoints.sm && screenWidth < breakpoints.md && giftsCount > 2) ||
    (screenWidth < breakpoints.sm && giftsCount) ? (
    <CarouselArrows
      filled
      onNext={carousel.onNext}
      onPrev={carousel.onPrev}
      leftButtonProps={{
        size: 'small',
        sx: { top: 'calc(50% + 35px)', left: '-5px' },
      }}
      rightButtonProps={{
        size: 'small',
        sx: { top: 'calc(50% + 35px)', right: '-5px' },
      }}
      sx={{ maxWidth: '100%' }}
    >
      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {children}
      </Carousel>
    </CarouselArrows>
  ) : (
    <Stack direction="row" gap={5} /* wrap */>
      {children}
    </Stack>
  );
};

export default SelectGift;
