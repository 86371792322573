// @mui
import { TableRow, TableCell, Checkbox, Button, Stack } from '@mui/material';
import Label from '../../../../../../components/label';
import Iconify from '../../../../../../components/iconify';

// ----------------------------------------------------------------------

type PaidTableType = {
  id: string;
  date: string;
  paymentForThePeriod: string;
  amountToPay: string;
  propertyAddress: string;
  status: string;
};

type Props = {
  row: PaidTableType;
};

export default function CatTableRow({ row }: Props) {
  const { date, paymentForThePeriod, amountToPay, propertyAddress, status } = row;

  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox checked={false} />
        </TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{paymentForThePeriod}</TableCell>
        <TableCell>{amountToPay}</TableCell>
        <TableCell>{propertyAddress}</TableCell>
        <TableCell>
          <Label color={status === 'שולם' ? 'success' : 'info'}>
            {status === 'שולם' && <Iconify icon="iconamoon:attention-circle-fill" mx={1} />}
            {status}
          </Label>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" sx={{ '&:hover': { color: '#000080' } }}>
            <Button variant="text">לצפייה בקבלה</Button>
            <Iconify icon="ep:arrow-left-bold" />
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}
