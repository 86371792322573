// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { fCurrency } from 'src/utils/format-number';

import { useTranslate } from 'src/locales';
import { useAppContext } from 'src/contexts/AppContext';
// utils
import { removeProduct, increseProduct, decreaseProduct } from 'src/api/basket';

// components
import Iconify from 'src/components/iconify';

import { ProvidersEnum } from 'src/types/b2b';
import { CartInterface } from 'src/types/cart';

import IncrementerButton from '../product/common/incrementer-button';
import Image from '../../components/image';

type Props = {
  row: CartInterface;
};

export default function CheckoutCartProduct({ row }: Props) {
  const { price, quantity, product } = row;
  const { t } = useTranslate();
  const { dispatch, state } = useAppContext();
  const available =
    product.provider === ProvidersEnum.STOCK && product.productCodeStockCount
      ? +product.productCodeStockCount
      : 1;

  const imageLink = localStorage.getItem(product.id);
  if (!row.product) return <> </>;

  return (
    <>
      <TableRow>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {imageLink ? (
            <Avatar
              variant="rounded"
              alt="alt"
              src={imageLink}
              sx={{ width: 64, height: 64, mr: 2 }}
            />
          ) : (
            <Avatar variant="rounded" alt="alt" sx={{ width: 64, height: 64, mr: 2 }} />
          )}

          <br />

          <Stack spacing={0.5}>
            <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
              {product.title}
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'body2', color: 'text.secondary' }}
            >
              {/* size: <Label sx={{ ml: 0.5 }}> {size} </Label> */}
              {/* <Divider orientation="vertical" sx={{ mx: 1, height: 16 }} /> */}
              {/* <ColorPreview colors={colors} /> */}
            </Stack>
          </Stack>
        </TableCell>

        <TableCell>{fCurrency(price)}</TableCell>

        <TableCell>
          <Box sx={{ width: 88, textAlign: 'right' }}>
            <IncrementerButton
              quantity={quantity}
              onDecrease={() => decreaseProduct(row, state.smbAccount.id, dispatch)}
              onIncrease={() => increseProduct(row, state.smbAccount.id, dispatch)}
              disabledDecrease={quantity <= 1}
              disabledIncrease={quantity >= available}
            />

            {/* <Typography variant="caption" component="div" sx={{ color: 'text.secondary', mt: 1 }}> */}
            {/*  available: {available} */}
            {/* </Typography> */}
          </Box>
        </TableCell>

        <TableCell align="right">{fCurrency(price * quantity)}</TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          <IconButton onClick={() => removeProduct(row.id, state.smbAccount.id, dispatch)}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
        </TableCell>
      </TableRow>
      {row.deliveryOptionId && row.deliveryOption && (
        <TableRow sx={{ borderBottom: '1px dashed #E0E0E0' }}>
          <TableCell align="left" sx={{ px: 1 }}>
            {!!row.deliveryOption.imageUrl.length && <>
              {row.deliveryOption.imageUrl.includes('/') ? (
                <Image src={row.deliveryOption.imageUrl} sx={{ width: '20px', height: '20px' }} />
              ) : (
                <Iconify icon={row.deliveryOption.imageUrl} sx={{ width: '20px', height: '20px' }} />
              )} </>}
            <Typography sx={{ fontWeight: 600 }}>{row.deliveryOption.label}</Typography>
          </TableCell>
          <TableCell align="left" sx={{ px: 1 }}/>
          <TableCell align="left" sx={{ px: 1 }}>
            <Typography>{row.deliveryOption.description}</Typography>
          </TableCell>
          <TableCell align="right" sx={{ px: 1 }}>
            <Typography>{fCurrency(row.deliveryOption.price)}</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
